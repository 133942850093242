import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { IPortfolio } from '../../../model/portfolio.model'
import { UserMode } from '../../../model/component-modes.enum'

@Component({
  selector: 'portfolio-photos-preview',
  template: `
    <e11-image-viewer #imageViewer [imageUrl]="$any(items) | portfolioPhotoUrls"></e11-image-viewer>

    <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-3 e11-gap-4 e11-items-center e11-mb-4">
      <ng-container *ngIf="signingFiles; else images">
        <div *ngFor="let i of 3 | range" class="e11-skeleton-empty !e11-h-20"></div>
      </ng-container>

      <ng-template #images>
        <ng-container *ngIf="showPreviews">
          <div *ngFor="let item of items | slice : 0 : limit; let i = index">
            <img
              alt="portfolio image"
              (click)="imageViewer.open(i)"
              [src]="item.file?.signedURL"
              class="e11-cursor-pointer e11-w-full e11-h-[100px] e11-object-cover"
            />
          </div>
        </ng-container>
      </ng-template>
    </div>
    <div *ngIf="showPreviews" class="e11-flex e11-justify-end">
      <p
        (click)="viewMoreClicked.emit()"
        *ngIf="items.length > limit"
        class="e11-mb-2 e11-text-xs e11-text-skin-primary-accent e11-text-end e11-cursor-pointer e11-w-fit"
      >
        {{ items.length - limit }} {{ 'more image(s)...' | translate }}
      </p>
    </div>
  `,
  styles: ``,
})
export class PortfolioPhotosPreviewComponent implements OnInit {
  @Input() items: IPortfolio[] = []
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() signingFiles: boolean = true
  @Input() showPreviews: boolean = true
  @Output() viewMoreClicked = new EventEmitter()

  limit: number = 3

  ngOnInit() {
    if (this.userMode === UserMode.Public) {
      this.limit = this.items.length
    }
  }
}
