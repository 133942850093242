import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class ViewportService {
  viewportSizeSource: BehaviorSubject<any> = new BehaviorSubject<any>('unknown')
  viewportSizeChanged$

  viewportWidthSource: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  viewportWidthObservable$

  constructor() {
    this.viewportSizeChanged$ = this.viewportSizeSource.asObservable()
    this.viewportWidthObservable$ = this.viewportWidthSource.asObservable()
    this.viewPortSize()
    window.addEventListener('resize', event => {
      this.viewPortSize()
    })
  }

  viewPortSize() {
    // Tailwind v4 dimensions
    this.viewportWidthSource.next(window.innerWidth)
    if (window.innerWidth <= 640) {
      this.viewportSizeSource.next('xs')
    }
    if (window.innerWidth > 640 && window.innerWidth <= 768) {
      this.viewportSizeSource.next('sm')
    }
    if (window.innerWidth > 768 && window.innerWidth <= 1024) {
      this.viewportSizeSource.next('md')
    }
    if (window.innerWidth > 1024 && window.innerWidth <= 1280) {
      this.viewportSizeSource.next('lg')
    }
    if (window.innerWidth > 1280 && window.innerWidth <= 1536) {
      this.viewportSizeSource.next('xl')
    }
    if (window.innerWidth > 1536) {
      this.viewportSizeSource.next('viewport2-xl')
    }
  }
}
