import { PlatformAdminCustomerInvoiceComponent } from '@engineering11/payment-web'
import { DynamicComponentConfig } from '@engineering11/platform-admin-web'

export const dynamicComponents: Partial<DynamicComponentConfig> = {
  routes: [],
  tabs: [
    {
      label: 'Subscriptions',
      id: 'subscriptions',
      component: PlatformAdminCustomerInvoiceComponent,
    },
  ],
  components: [],
}
