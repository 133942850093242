import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'date-display-start-end',
  templateUrl: './date-display-start-end.component.html',
  styleUrls: ['./date-display-start-end.component.scss'],
})
export class DateDisplayStartEndComponent implements OnInit {
  /**
   * Encourages following naming convention.
   * Can be built in parent if necessary e.g. [item]="{ startDate: item.from, endDate: item.to }"
   */
  @Input() item!: { startDate?: Date | null; endDate?: Date | null }
  @Input() dateFormat = 'MMM yyyy'

  current: boolean = false

  constructor() {}

  ngOnInit(): void {
    if (this.item) {
      this.isCurrent(this.item)
    }
  }

  isCurrent(item: any) {
    if (item.currentlyAttending) {
      this.current = true
    }
    if (item.currentlyWorking) {
      this.current = true
    }
  }
}
