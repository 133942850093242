import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { PlayerSize } from '@engineering11/multimedia-web'
import { IPortfolio } from '../../../model/portfolio.model'
import { UserMode } from '../../../model/component-modes.enum'

@Component({
  selector: 'portfolio-video-line-item',
  template: `
    <div class="e11-p-0 e11-h-60 e11-bg-skin-grey/20 e11-mb-12">
      <e11-confirmation
        #confirmation
        confirmTitle="{{ 'Delete this video' | translate }}"
        confirmText="{{ 'confirm' | translate }}"
        [closeOnEscape]="false"
        cancelText="{{ 'cancel' | translate }}"
        [backdropStyle]="'dark'"
        (answer)="confirmationAnswer($event)"
      >
        {{ 'Are you sure you want to remove this video? You can always add it back if you need to.' | translate }}
      </e11-confirmation>

      <div [ngClass]="{ 'e11-skeleton': !video }" class="e11-h-full e11-w-full">
        <video-player [playerSize]="PlayerSize.Small" [videoPlayerResource]="{ video: video?.file?.signedURL }"></video-player>
      </div>
      <div class="e11-border e11-border-skin-app-borders e11-p-2">
        <div class="e11-flex e11-items-center e11-justify-between">
          <div class="e11-max-w-[70%] e11-grow">
            <p class="e11-mb-0 e11-skeleton-empty e11-truncate">{{ video ? video?.file?.fileName : '' }}</p>
          </div>
          <e11-button
            *ngIf="userMode === UserMode.Candidate"
            [size]="'sm'"
            [buttonGroup]="false"
            [color]="'secondary'"
            [skeletonLoading]="!video"
            (click)="confirmation.open()"
            value="{{ 'Delete' | translate }}"
          ></e11-button>
        </div>
      </div>
    </div>
  `,
})
export class PortfolioVideoLineItemComponent implements OnInit {
  @Input() video?: IPortfolio
  @Input() userMode = UserMode.Candidate
  @Output() deleteVideo = new EventEmitter()

  UserMode = UserMode

  PlayerSize = PlayerSize

  constructor() {}

  ngOnInit() {}

  confirmationAnswer(res: boolean) {
    if (res) this.deleteVideo.emit()
  }
}
