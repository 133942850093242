<!-- Empty State may be needed -->
<!-- Populated state -->
<div *ngIf="workHistory.length > 0">
  <div *ngFor="let item of workHistory; let i = index" class="e11-border e11-border-skin-app-borders e11-p-2 e11-mb-4 e11-rounded">
    <!-- company and view more  -->
    <div class="e11-flex">
      <div class="e11-w-3/4">
        <p class="e11-font-bold e11-line-clamp-3 e11-break-normal e11-mb-0">
          {{ item.companyName }}
        </p>
      </div>
      <div
        *ngIf="!hideViewOptionButton"
        class="e11-w-1/4 e11-flex e11-items-center e11-justify-end e11-cursor-pointer e11-text-skin-primary-accent hover:e11-text-skin-primary"
        (click)="viewMore(i)"
      >
        <span class="e11-flex e11-items-center" *ngIf="open !== i && !viewAll">
          {{ 'View more' | translate }}
          <span class="material-icons e11--mt-[2px]"> expand_more </span>
        </span>
        <span class="e11-flex e11-items-center" *ngIf="open === i || viewAll">
          {{ 'View less' | translate }}
          <span class="material-icons e11--mt-[2px]"> expand_less </span>
        </span>
      </div>
    </div>
    <div>{{ item.jobTitle }}</div>
    <date-display-start-end [item]="item"></date-display-start-end>
    <div class="" *ngIf="open === i || viewAll">
      <div *ngIf="item.jobDescription" [e11SummernoteView]="item?.jobDescription || ''"></div>
    </div>
    <!-- company and view more  -->
    <div class="e11-break-words e11-line-clamp-2 e11-skeleton-empty e11-mb-1" *ngIf="!workHistory"></div>
  </div>
</div>
