import { Injectable } from '@angular/core'
import { FilesService, IFileReceipt, UploadFileRequest, UploadFilesRequest } from '@engineering11/files-web'
import { VideoThumbnailsService } from '@engineering11/multimedia-web'
import { omit } from 'lodash'
import { firstValueFrom } from 'rxjs'
import { v4 as uuidv4 } from 'uuid'

export interface IFileUploadResponse {
  fileReceipt: IFileReceipt
  thumbReceipt?: IFileReceipt
}

const mimeDB: Record<string, string> = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/webp': 'webp',
}

// const videoTypes = ['video/mp4', 'video/mpeg', 'video/ogg', 'video/webm', 'video/x-msvideo', 'video/quicktime', 'video/3gpp', 'video/x-m4v']

/**
 * Automatically creates thumbnails for video type files
 * TODO: Move to files sdk and inject an interface for the video thumbnail service
 */

@Injectable({ providedIn: 'root' })
export class FileUploadService {
  constructor(private filesService: FilesService, private videoThumbnailService: VideoThumbnailsService) {}

  async uploadFile(request: UploadFileRequest): Promise<IFileUploadResponse> {
    const id = uuidv4()

    return {
      fileReceipt: await this.filesService.uploadFile({ ...request, groupingId: request.groupingId ?? id }),
      thumbReceipt: await this.getThumbReceipt(request, id),
    }
  }

  async uploadFiles(request: UploadFilesRequest) {
    return Promise.all(request.files.map(file => this.uploadFile({ ...omit(request, 'files'), file })))
  }

  async getThumbReceipt(request: UploadFileRequest, id: string) {
    // refactor to allow for custom video types, refer portfolioFileTypeAccept variable in portfolio-item-header.component.ts
    if (request.file.type.startsWith('video/')) {
      return await this.filesService.uploadFile({ file: await this.getThumbnail(request.file), groupingId: request.groupingId ?? id })
    } else {
      return undefined
    }
  }

  async getThumbnail(file: File) {
    this.videoThumbnailService.capture(file)
    const thumbFile = await firstValueFrom(this.videoThumbnailService.getCapture())
    const blob = await (await fetch(thumbFile)).blob()
    const ext: string = mimeDB[blob.type] || 'jpg'
    const filename = file.name.replace(/\s+/g, '_')
    const thumbName = encodeURIComponent(`${filename}_${new Date().getTime()}.${ext}`)
    return new File([blob], thumbName, { type: blob.type })
  }
}
