import { Injectable } from '@angular/core'
import { INotificationMessage, NotificationType } from '@engineering11/ui-lib/e11-notifications'
import { NotificationService } from '@engineering11/web-ui-helpers'
import { TranslateService } from '@ngx-translate/core'

export type NotificationOverrides = { title: string; message: string; type: NotificationType; autoClose: boolean }

/**
 * @deprecated - use NotificationService from @engineering11/web-ui-helpers
 */
@Injectable({ providedIn: 'root' })
export class NotificationTranslateService {
  constructor(private notificationsService: NotificationService, private translate: TranslateService) {}

  /**
   * @deprecated - use @popNotification - it's more extensible
   */
  popNotificationMessage(title: string, message: string, type: NotificationType, autoClose: boolean, ctaText = '') {
    const notification = { title, message, type, autoClose, ctaText }
    this.notificationsService.popNotification(notification)
  }

  popNotification(notification: INotificationMessage) {
    this.notificationsService.popNotification(notification)
  }

  popNotificationFromError(err: Error, overrides?: Partial<NotificationOverrides>) {
    this.notificationsService.popNotificationFromError(err, overrides)
  }
}
