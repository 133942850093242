import { Component, EventEmitter, Input, Output } from '@angular/core'
import { groupByKey } from '@engineering11/utility'
import { RequestedUpdateFields } from '../../../model/candidate-application.model'
import { UserMode } from '../../../model/component-modes.enum'
import { MatchType } from '../../../model/enum'
import { IMatchedSkill, ISkill } from '../../../model/interfaces'

const FilterTypes = Object.freeze({ ...MatchType, NoMatch: 'NoMatch' as const })
type FilterKeys = keyof typeof FilterTypes
const employerFilterTypeCopy: Record<FilterKeys, string> = {
  [FilterTypes.Exact]: 'Exact matches to the skills listed in the job post',
  [FilterTypes.Partial]: 'Partial matches to the skills listed in the job post',
  [FilterTypes.NoMatch]: 'Skills listed in the job post that the candidate does not have',
  [FilterTypes.NotListed]: 'Skills the candidate has that were not listed in the job post',
}

const candidateFilterTypeCopy: Record<FilterKeys, string> = {
  [FilterTypes.Exact]: employerFilterTypeCopy.Exact,
  [FilterTypes.Partial]: employerFilterTypeCopy.Partial,
  [FilterTypes.NoMatch]: 'Skills listed in the job post that you do not have',
  [FilterTypes.NotListed]: 'Skills you have that were not listed in the job post',
}

@Component({
  selector: 'skills-result',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.scss'],
})
export class CandidateSkillsResultComponent {
  FilterTypes = FilterTypes
  employerFilterTypeCopy = employerFilterTypeCopy
  candidateFilterTypeCopy = candidateFilterTypeCopy
  UserMode = UserMode
  filterTypes = Object.values(FilterTypes)
  filterCurrent: FilterKeys = MatchType.Exact

  translationFilterTypes = {
    Exact: 'Exact',
    Partial: 'Partial',
    NotListed: 'Other',
    NoMatch: 'Missing',
  }

  groupedSkills: Record<string, ISkill[]> = Object.values(FilterTypes).reduce((prv, nxt) => ({ ...prv, [nxt]: [] }), {})

  // Needed for empty state
  matchedSkills!: IMatchedSkill[]

  showOnlyMissing = false

  constructor() {}

  @Output() newSkills = new EventEmitter<ISkill[]>()
  @Output() requestFromComponent = new EventEmitter()

  @Input() openRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false
  @Input() pdfMode: boolean = false
  @Input() showRequestButton = true

  @Input()
  userMode: UserMode = UserMode.Candidate

  @Input() set skills(matchedSkills: IMatchedSkill[]) {
    this.matchedSkills = matchedSkills
    this.groupedSkills = { ...this.groupedSkills, ...groupByKey(matchedSkills, 'matchType') }
  }

  @Input() set missingSkills(missingSkills: ISkill[]) {
    this.groupedSkills = { ...this.groupedSkills, NoMatch: missingSkills }
  }

  @Input() set showOnlyMissingSkills(showOnlyMissing: boolean) {
    this.showOnlyMissing = showOnlyMissing

    if (this.showOnlyMissing) this.filterCurrent = this.FilterTypes.NoMatch
  }

  @Input() readOnly = false

  requestModal() {
    this.requestFromComponent.emit('Skills')
  }

  changedTab(event: any) {
    this.filterCurrent = event.tabId as FilterKeys
  }
}
