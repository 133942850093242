import { Component, Input } from '@angular/core'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { RequestedFieldChanged } from '../../model/enum'

@Component({
  selector: 'request-open',
  template: `
    <div
      class="e11-my-4 e11-relative e11-block e11-w-full e11-border-2 e11-border-skin-app-borders e11-border-dashed e11-rounded-lg e11-p-2 e11-text-center"
      *ngIf="request"
    >
      <span class="material-icons md-36 e11-mx-auto e11-text-skin-secondary">pending</span>

      <div class="e11-text-sm e11-text-skin-light">
        <span *ngIf="request === requestTypes.Education">
          {{ 'We have sent a notification to the candidate to provide educational information.' | translate }}
        </span>
        <span *ngIf="request === requestTypes.References">
          {{ 'We have sent a notification to the candidate to provide references.' | translate }}
        </span>
        <span *ngIf="request === requestTypes.WorkHistory">
          {{ 'We have sent a notification to the candidate to provide work history.' | translate }}
        </span>
        <span *ngIf="request === requestTypes.Languages">
          {{ 'We have sent a notification to the candidate to provide languages.' | translate }}
        </span>
        <span *ngIf="request === requestTypes.Skills">
          {{ 'We have sent a notification to the candidate to provide skills.' | translate }}
        </span>
        <span *ngIf="request === requestTypes.Certifications">
          {{ 'We have sent a notification to the candidate to provide certifications.' | translate }}
        </span>
        <span *ngIf="request === requestTypes.FirstImpression || $any(request) === 'firstImpressionUrl'">
          {{ 'We have sent a notification to the candidate to provide a First Impression.' | translate }}
        </span>
        <span *ngIf="request === requestTypes.Portfolio">
          {{ 'We have sent a notification to the candidate to provide portfolio items.' | translate }}
        </span>
        <span *ngIf="request === requestTypes.SocialMedia">
          {{ 'We have sent a notification to the candidate to provide social media links.' | translate }}
        </span>
        <span *ngIf="request === requestTypes.MilitaryAffiliation">
          {{ 'We have sent a notification to the candidate to provide military affiliation information.' | translate }}
        </span>
      </div>
    </div>
  `,
  styleUrls: ['./request-open.component.scss'],
})
export class CandidateRequestOpenComponent {
  @Input() request?: RequestedUpdateFields

  requestTypes = RequestedFieldChanged

  constructor() {}
}
