import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms'
import { E11FormValidator, Validation } from '@engineering11/ui-lib/e11-input-errors'
import { E11NotificationsService, INotificationMessage } from '@engineering11/ui-lib/e11-notifications'
import { URL_REGEX } from '@engineering11/utility'
import { v4 as uuidv4 } from 'uuid'
import { UserMode } from '../../../model/component-modes.enum'
import { IPortfolioUrl, PortfolioItemType, PortfolioUiType } from '../../../model/portfolio.model'
import { PortfolioStore } from '../portfolio.store.component'

@Component({
  selector: 'portfolio-links-form',
  template: `
    <ng-container *ngIf="userMode === UserMode.Candidate">
      <form #formDirective="ngForm" class="" [formGroup]="formNewPortfolioLink" (ngSubmit)="onSubmitNewPortfolioLink()" novalidate>
        <div class="e11-w-full">
          <e11-input
            #inputTemplateName
            [type]="'url'"
            name="portfolioLink"
            id="newPortfolioLink"
            label="{{ 'Website URL' | translate }}"
            placeholder="{{ 'https://' | translate }}"
            [parentForm]="formNewPortfolioLink"
            formControlName="url"
            [autofocus]="false"
            [required]="false"
          >
            <e11-input-errors
              [parentForm]="formNewPortfolioLink"
              [formControl]="pf.url"
              label="{{ 'New Portfolio Link' | translate }}"
            ></e11-input-errors>
            <div class="e11-flex e11-justify-end e11-text-xs e11-text-skin-light"></div>
          </e11-input>
        </div>

        <div class="e11-w-full e11-text-right">
          <e11-button
            color="neutral"
            [ghost]="true"
            value="{{ 'Cancel' | translate }}"
            [buttonGroup]="true"
            [size]="'sm'"
            (click)="closeModal.emit()"
          ></e11-button>
          <e11-button color="primary-accent" value="{{ 'Save' | translate }}" [size]="'sm'" [type]="'submit'"></e11-button>
        </div>
      </form>
    </ng-container>
  `,
})
export class PortfolioLinksFormComponent {
  _portfolioLinks: IPortfolioUrl[] = []
  currentLink?: IPortfolioUrl

  @ViewChild('formDirective') formDirective?: NgForm

  @Input() set portfolioLinks(portfolio: IPortfolioUrl[]) {
    this._portfolioLinks = portfolio
  }
  @Input() set editLink(link: IPortfolioUrl | undefined) {
    if (link) {
      this.currentLink = link
      this.formNewPortfolioLink.patchValue({
        url: link.url,
      })
    }
  }
  @Input() userMode = UserMode.Candidate

  @Output() closeModal = new EventEmitter()

  PortfolioUiType = PortfolioUiType
  UserMode = UserMode

  formNewPortfolioLink = new FormGroup({
    url: new FormControl('', [
      Validators.required,
      E11FormValidator.minLengthAlphanumeric(1),
      Validators.pattern(URL_REGEX),
      E11FormValidator.whitespaceValidator(),
    ]),
  })

  constructor(private notificationsService: E11NotificationsService, private portfolioStore: PortfolioStore) {}

  get pf() {
    return this.formNewPortfolioLink.controls
  }

  deletePortfolioLink(itemId: string) {
    this.portfolioStore.onPortfolioItemDeleted(itemId)
  }

  cancelLink() {
    this.currentLink = undefined
    this.formNewPortfolioLink.reset()
    this.formDirective?.resetForm()
    this.resetPatch()
  }

  resetPatch() {
    this.formNewPortfolioLink.patchValue({
      url: '',
    })
    this.pf.url.setErrors(null)
  }

  confirmationAnswer(res: boolean, id: string) {
    if (res) {
      this.deletePortfolioLink(id)
    }
  }

  hasDuplicateLinks(link: string) {
    if (this._portfolioLinks.filter(p => p.url === link).length > 0) {
      this.notificationsService.popNotificationMessage(duplicateNotification)
      this.cancelLink()
      return true
    }
    return false
  }

  onSubmitNewPortfolioLink() {
    if (this.formNewPortfolioLink.invalid || this.pf.url.value?.length === 0) {
      this.formNewPortfolioLink.updateValueAndValidity()
      Validation.validateAll(this.formNewPortfolioLink)
      return
    }
    if (this.hasDuplicateLinks(this.pf.url.value!)) return
    if (this.currentLink) {
      const updatedLink: IPortfolioUrl = {
        id: this.currentLink.id,
        itemType: PortfolioItemType.Url,
        url: this.pf.url.value!,
      }
      this.portfolioStore.onPortfolioItemUpdated(updatedLink)
    } else {
      const newLink: IPortfolioUrl = {
        id: uuidv4(),
        itemType: PortfolioItemType.Url,
        url: this.pf.url.value!,
      }
      this.portfolioStore.onPortfolioItemsAdded([newLink])
    }
    this.cancelLink()
  }
}
const duplicateNotification: INotificationMessage = {
  title: 'Error',
  message: 'Duplicate links cannot be added',
  type: 'error',
  autoClose: true,
  dismissOnRouteChange: true,
}
