import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ILanguage, languageCodeMap, proficiencyMap } from '../../model/language.model'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'

@Component({
  selector: 'ui-language',
  template: `
    <e11-chip
      *ngIf="language"
      [icon]="'language'"
      [id]="language.code"
      [title]="languageCodeMap[language.code]"
      [subText]="proficiencyMap[language.proficiency]"
      [color]="language.proficiency == 5 ? 'success' : 'neutral'"
      [width]="'260px'"
      [closer]="closer"
      [iconPosition]="'left'"
      [iconClickable]="true"
      [chipClickable]="false"
      (removeItem)="removeItem.emit()"
    ></e11-chip>
  `,
})
export class LanguageChipComponent {
  proficiencyMap = proficiencyMap
  languageCodeMap = languageCodeMap
  @Input() language?: ILanguage
  @Input() closer = true
  @Input() loading = false

  @Input() userMode: UserMode = UserMode.Candidate
  @Input() readonlyMode: boolean = false
  @Input() openedRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false

  @Output() requestFromComponent = new EventEmitter()
  @Output() editClicked = new EventEmitter()

  userModeEnum = UserMode

  @Output() removeItem = new EventEmitter()
  constructor() {}
}
