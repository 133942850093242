import { ActionReducerMap, MetaReducer } from '@ngrx/store'

import { storeFreeze } from 'ngrx-store-freeze'

import { environment } from '../../environments/environment'

import * as fromReducers from './reducers'
import { effects as fromEffects } from './effects'

interface importType {
  [key: string]: { reducer: any }
}
export interface State {
  [key: string]: Record<string, any>[]
}

export const reducers: ActionReducerMap<State> = Object.keys(fromReducers)
  .sort()
  .reduce((prev, next: string) => {
    return { ...prev, ...{ [next]: (fromReducers as importType)[next].reducer } }
  }, {})

export const effects = Object.keys(fromEffects).map(effect => (fromEffects as any)[effect])

export const metaReducers: MetaReducer<State>[] = !environment.production ? [storeFreeze] : []
