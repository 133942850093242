import { Injectable } from '@angular/core'
import { IRegistrationConfig, BaseRegistrationConfigProvider } from '@engineering11/registration-web'
import { Store } from '@ngrx/store'
import { getToken } from 'shared-lib'
import { environment } from '../../environments/environment'

@Injectable({ providedIn: 'root' })
export class AdminRegistrationConfigProvider extends BaseRegistrationConfigProvider {
  constructor(private store: Store) {
    super()
  }
  get config(): IRegistrationConfig {
    return {
      registrationBaseURL: environment.services.registration,
      authBaseURL: environment.services.auth,
      authTenantId: environment.tenantId,
      token$: this.store.select(getToken),
      basePlatformHost: environment.basePlatformHost,
      cdnName: environment.cdnCname,
      defaultEmailDomain: 'notifications@notifications.cnected.com',
    }
  }
}
