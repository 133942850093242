import { IConfig, BaseConfigProvider } from '@engineering11/config-web'
import { NOT_FOUND_ROUTE } from '../constants'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { getCurrentUserId } from '../store/user/user.selectors'

@Injectable({ providedIn: 'root' })
export class ConfigProvider extends BaseConfigProvider {
  constructor(private store: Store) {
    super()
  }
  /**
   * Attempting new pattern where all config is included in config provider
   * @returns
   */
  get config(): IConfig {
    return {
      notFoundRoute: NOT_FOUND_ROUTE,
      userId$: this.store.pipe(getCurrentUserId),
    }
  }
}
