import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core'
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms'
import { E11FormValidationService, Validation } from '@engineering11/ui-lib/e11-input-errors'
import { E11PopoverGlobalService } from '@engineering11/ui-lib/e11-popover'
import { compareDesc, sort } from '@engineering11/utility'
import { FormSelectModel, removeDuplicatesByNameIgnoreCase, ViewportService } from '@engineering11/web-utilities'
import { Subscription } from 'rxjs'
import { SkillLevel, skillLevelRank } from '../../model/enum'
import { ISkill } from '../../model/interfaces'

const ucFirst = (t: string) => t.replace(/(^|\s)[A-Za-zÀ-ÖØ-öø-ÿ]/g, c => c.toUpperCase())
const compareBySkillRank = compareDesc<ISkill>(skill => skillLevelRank[skill.level])

@Component({
  selector: 'manage-skills',
  templateUrl: './manage-skills.component.html',
  styleUrls: ['./manage-skills.component.scss'],
})
export class ManageSkillsComponent {
  @ViewChild('myInput') inputElement!: ElementRef
  @ViewChild('skillPopover') skillPopover!: TemplateRef<any>

  _currentSkills: ISkill[] = []
  @Input() set currentSkills(skills: ISkill[] | undefined) {
    this._currentSkills = sort(skills ?? [], compareBySkillRank)
  }

  @Input() loading = true
  @Input() readOnly = false
  @Input() showCompare = false
  @Input() markAsMissingSkills = false
  @Input() showEmptyStateBorder = true

  @Output() newSkills = new EventEmitter<ISkill[]>()
  @Output() compareClicked = new EventEmitter()
  isMobile: boolean = false
  viewportSubscription: Subscription

  state: string = '' // default(blank), 'edit' (edit will work for adding and editing)
  addItem: string = '' // 'skill'(default), 'edit'
  ctaTextSkill: string = 'Add'

  // New
  modifyItem: any = '' // blank/default,   skill,  cert   -- this is for adding or editing a skill/cert
  modifyItemType: any = '' // blank/default,   skill,  cert   -- this is for adding or editing a skill/cert
  modifyTask: string = '' // edit or remove

  skillLevelList: FormSelectModel[] = []

  // Forms
  formAddSkill = new UntypedFormGroup({})
  skillLastSelected: string = 'Beginner'
  formAddSkillSubmitted = false

  testName: string = ''
  testLevel: string = 'Beginner'

  errorMessages = [
    {
      error: 'required',
      message: 'A name is required',
    },
    {
      error: 'hasWhitespaceError',
      message: "Can't start with a space",
    },
    {
      error: 'maxlength',
      message: "Name can't be longer than 100 characters",
    },
    {
      error: 'name',
      message: 'Cannot have duplicate name',
    },
  ]

  constructor(
    private formBuilder: UntypedFormBuilder,
    private viewportService: ViewportService,
    private customValidator: E11FormValidationService,
    private popoverGlobalService: E11PopoverGlobalService
  ) {
    this.viewportSubscription = this.viewportService.viewportSizeChanged$.subscribe(data => {
      this.isMobile = data == 'sm' || data == 'xs'
    })
    this.skillLevelList = Object.values(SkillLevel).map(value => new FormSelectModel(value, ucFirst(value.toLowerCase())))
  }

  ngOnInit(): void {
    this.createFormAddSkill()
  }

  ngOnDestroy() {
    this.viewportSubscription.unsubscribe()
  }

  // Adding skills form
  get f() {
    return this.formAddSkill.controls as {
      [key: string]: UntypedFormControl
    }
  }

  duplicateSkillsValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      if (
        !this.markAsMissingSkills &&
        (this.modifyTask === 'add' || (this.modifyTask === 'edit' && control.value !== this.modifyItem.name)) &&
        this._currentSkills.some(skill => skill.name === control.value)
      ) {
        return { name: true }
      }
      return null
    }
  }

  // Skills
  createFormAddSkill(): void {
    this.formAddSkill = this.formBuilder.group({
      name: new UntypedFormControl('', [
        Validators.required,
        this.customValidator.whitespaceValidator(),
        this.duplicateSkillsValidator(),
        Validators.maxLength(100),
      ]),
      level: new UntypedFormControl(this.skillLastSelected, [Validators.required]),
    })
  }

  onSubmitFormAddSkill(skills: ISkill[] | null): void {
    this.formAddSkillSubmitted = true // Used for UI error messages

    if (this.formAddSkill.valid && skills) {
      // We need to double check here because the 2 forms can 'cross-talk'
      if (this.modifyItemType === 'skill' && this.modifyTask === 'edit') {
        this.editExistingSkill(skills)
      } else if (this.markAsMissingSkills) {
        this.addMissingSkill()
      } else {
        this.addLineItemSkill(skills)
      }
      this.formAddSkillSubmitted = false // resets forms error handling
      this.editStateRemove()
      this.closePopover()
    } else {
      this.formAddSkill.updateValueAndValidity()
      Validation.validateAll(this.formAddSkill)
    }
  }

  // Changes view/form state from read only to add/edit
  stateChange(value: string): void {
    this.state = value
    if (!value) {
      this.editStateRemove()
    }
  }

  // Adding and removing individual items intentionally separated
  addLineItemSkill(currentSkills: ISkill[]) {
    const name = this.formAddSkill.get('name')?.value
    const level = this.formAddSkill.get('level')?.value
    this.skillLastSelected = level
    const newSkill: ISkill = { name, level }
    const newSkills = removeDuplicatesByNameIgnoreCase([...currentSkills, newSkill])
    this.saveSkills(newSkills)
  }

  addMissingSkill() {
    const name = this.formAddSkill.get('name')?.value
    const level = this.formAddSkill.get('level')?.value
    this.skillLastSelected = level
    this.saveSkills([{ name, level }])
  }

  editExistingSkill(currentSkills: ISkill[] | null) {
    if (currentSkills) {
      const name = this.formAddSkill.get('name')?.value
      const level = this.formAddSkill.get('level')?.value
      this.skillLastSelected = level
      const newSkill: ISkill = { name, level }
      const newSkills = currentSkills.map((skill: ISkill) => (skill.name === this.modifyItem.name ? newSkill : skill))
      this.saveSkills(newSkills)
    }
  }

  removeSkill(item: string, currentSkills: ISkill[] | null) {
    if (currentSkills) {
      const newSkills = currentSkills.filter((value: ISkill) => value.name !== item)
      this.saveSkills(newSkills)
    }
    this.editStateRemove()
    this.closePopover()
  }

  userTagAction($event: any, skillsOrCerts: ISkill[] | null, index: number) {
    this.modifyItem = $event.item
    this.modifyTask = $event.task
    this.testName = $event.item.name
    this.testLevel = $event.item.level
    this.modifyItemType = 'skill'
    this.formAddSkill.patchValue({
      name: $event.item.name,
      level: $event.item.level,
    })
    this.skillLastSelected = $event.item.level
    // const inputField: HTMLElement = <HTMLElement>document.querySelectorAll('.add-skill .input-skill input')[0]
    // inputField.focus()

    if ($event.task === 'edit') {
      this.stateChange('edit')
      this.ctaTextSkill = 'Save'
      this.openPopover('skill-tag-' + index)
    }

    if ($event.task === 'add') {
      this.stateChange('add')
      this.ctaTextSkill = 'Add'
      this.openPopover('add-skill-action')
    }

    if ($event.task === 'remove') {
      this.removeSkill($event.item.name, skillsOrCerts as ISkill[])
    }
  }

  editStateRemove() {
    this.ctaTextSkill = 'Add'
    this.modifyTask = ''
    this.modifyItem = null
    this.formAddSkill.reset()
    this.formAddSkillSubmitted = false
    this.formAddSkill.controls['level'].setValue(this.skillLastSelected) // empty out text
  }

  saveSkills(newSkills: ISkill[]): void {
    const filteredSills = newSkills.map(skill => ({ name: skill.name, level: skill.level }))
    const sortedSkills = sort(filteredSills, compareBySkillRank)
    this.newSkills.emit(sortedSkills)
  }

  openPopover(targetElement: string) {
    this.popoverGlobalService.openPopover({
      content: this.skillPopover,
      targetElement: targetElement,
      closeOnEscape: true,
      closeOnClickOutside: true,
    })
  }

  closePopover() {
    this.popoverGlobalService.closePopover()
    this.stateChange('')
  }
}
