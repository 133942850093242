export interface ISocialMedia {
  facebook: string
  instagram: string
  linkedin: string
  snapchat: string
  tiktok: string
  twitter: string
}

export const socialMediaFields: Array<{ name: keyof ISocialMedia; label: string; logo: string; altName?: string }> = [
  { name: 'facebook', label: 'Facebook', logo: 'facebook' },
  { name: 'instagram', label: 'Instagram', logo: 'instagram' },
  { name: 'linkedin', label: 'LinkedIn', logo: 'linkedin' },
  { name: 'snapchat', label: 'SnapChat', logo: 'snapchat' },
  { name: 'tiktok', label: 'TikTok', logo: 'tiktok' },
  { name: 'twitter', label: 'X', logo: 'x', altName: 'x' },
]
