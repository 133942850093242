<button
  (click)="toggleMobileSideDrawer()"
  type="button"
  class="e11-inline-flex lg:e11-hidden e11-items-center e11-justify-center e11-p-2 e11-rounded-md e11-text-skin-primary hover:e11-text-skin-primary focus:e11-outline-none focus:e11-ring-2 focus:e11-ring-offset-2"
  aria-controls="mobile-menu"
  aria-expanded="false"
>
  <span class="e11-sr-only">Open main menu</span>
  <span class="material-icons-outlined" *ngIf="!mobileDrawerState">menu</span>
  <span class="material-icons-outlined" *ngIf="mobileDrawerState">close</span>
</button>
