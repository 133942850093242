import { Component, Input } from '@angular/core'
import { E11TemplateMap } from '@engineering11/ui-lib/e11-ui-utils'

/**
 * Useful for pdf mode - wrap content in an a-tag if desired
 */
@Component({
  selector: 'conditional-link-wrapper',
  template: `
    <ng-container *ngIf="!shouldWrap">
      <ng-container *ngTemplateOutlet="e11TemplateMap['Body']"></ng-container>
    </ng-container>

    <a *ngIf="shouldWrap && link" [href]="link">
      <ng-container *ngTemplateOutlet="e11TemplateMap['Body']"></ng-container>
    </a>
  `,
})
export class ConditionalLinkWrapperComponent extends E11TemplateMap {
  @Input() shouldWrap: boolean = false
  @Input() link?: string

  constructor() {
    super()
  }
}
