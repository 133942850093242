import { Pipe, PipeTransform } from '@angular/core'
import {
  filterPortfolioDocuments,
  filterPortfolioLinks,
  filterPortfolioPhotos,
  filterPortfolioVideos,
  getPortfolioItemsByType,
} from '../util/portfolio-items-by-type'
import { IPortfolio, IPortfolioDocument, IPortfolioLink, IPortfolioPhoto, IPortfolioVideo, PortfolioUiType } from '../model/portfolio.model'

@Pipe({ name: 'portfolioItemsByType' })
export class PortfolioItemsByTypePipe implements PipeTransform {
  transform(portfolio: IPortfolio[], itemType: PortfolioUiType): IPortfolio[] {
    return getPortfolioItemsByType(portfolio, itemType)
  }
}

@Pipe({ name: 'portfolioDocuments' })
export class PortfolioDocumentsPipe implements PipeTransform {
  transform(portfolio: IPortfolio[]): IPortfolioDocument[] {
    return filterPortfolioDocuments(portfolio)
  }
}

@Pipe({ name: 'portfolioPhotos' })
export class PortfolioPhotosPipe implements PipeTransform {
  transform(portfolio: IPortfolio[]): IPortfolioPhoto[] {
    return filterPortfolioPhotos(portfolio)
  }
}

@Pipe({ name: 'portfolioVideos' })
export class PortfolioVideosPipe implements PipeTransform {
  transform(portfolio: IPortfolio[]): IPortfolioVideo[] {
    return filterPortfolioVideos(portfolio)
  }
}

@Pipe({ name: 'portfolioLinks' })
export class PortfolioLinksPipe implements PipeTransform {
  transform(portfolio: IPortfolio[]): IPortfolioLink[] {
    return filterPortfolioLinks(portfolio)
  }
}

@Pipe({ name: 'portfolioPhotoUrls' })
export class PortfolioPhotoUrlsPipe implements PipeTransform {
  transform(portfolioPhotos: IPortfolioPhoto[]) {
    return portfolioPhotos.map(p => p.file.signedURL!)
  }
}

export const PortfolioFilterPipes = [
  PortfolioItemsByTypePipe,
  PortfolioPhotoUrlsPipe,
  PortfolioDocumentsPipe,
  PortfolioPhotosPipe,
  PortfolioVideosPipe,
  PortfolioLinksPipe,
]
