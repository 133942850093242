import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { BaseNotificationConfigProvider, INotificationsConfig } from '@engineering11/notifications-web'
import { environment } from '../../environments/environment'
import { E11Logger } from '@engineering11/web-api-error'
import { getCurrentUserId, getToken } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class NotificationConfigProvider extends BaseNotificationConfigProvider {
  constructor(private store: Store, private logger: E11Logger) {
    super()
  }

  get config(): INotificationsConfig {
    return {
      userId: this.store.pipe(getCurrentUserId),
      notificationsBaseURL: environment.services.notifications,
      token$: this.store.select(getToken),
    }
  }
}
