<div class="bottom">
  <div class="row">
    <div class="col-xs-4">
      <div class="countdown" [ngClass]="{ active: displayCount < 10, pulse: displayCount < 10 }">
        {{ 'Recording' | translate }}: {{ displayCount }}
      </div>
    </div>
    <div class="col-xs-4 text-center">
      <i class="fas fa-trash-alt" *ngIf="videoControlButtons.trash" (click)="setAction(videoPlayerActionsEnum.CLEAR)"></i>

      <span class="record" (click)="setAction(videoPlayerActionsEnum.RECORD)" *ngIf="videoControlButtons.record">
        <i class="fas fa-video"></i>
      </span>
      <span class="record" (click)="setAction(videoPlayerActionsEnum.STOP_RECORDING)" *ngIf="videoControlButtons.stopRecord">
        <i class="fas fa-stop"></i>
      </span>

      <i class="fas fa-play" (click)="setAction(videoPlayerActionsEnum.PLAY)" *ngIf="videoControlButtons.play"></i>
      <i class="fas fa-stop" (click)="setAction(videoPlayerActionsEnum.STOP)" *ngIf="videoControlButtons.stopPlayback"></i>
    </div>
    <div class="col-xs-4 text-center">
      <div class="controls" [ngClass]="{ inactive: !videoControlButtons.setting }">
        <video-recording-settings
          (closeSettings)="closeMenus()"
          (showTips)="showModalTips()"
          (teleprompterStatus)="toggleTeleprompter($event)"
          [teleprompter]="false"
          (videoRecordingSettingsChange)="videoRecordingSettingsChange($event)"
          [action]="currentPlayerStatus"
        >
        </video-recording-settings>
        <!-- <first-impression-support *ngIf="showMenuSupport" (closeSupport)="closeMenus()"> </first-impression-support> -->
        <i class="fas fa-save" *ngIf="videoControlButtons.save" (click)="setAction(videoPlayerActionsEnum.SAVE)"></i>
        <span (click)="toggleMenus('settings')">
          <i class="fas fa-cog"></i>
        </span>
        <span (click)="toggleMenus('support')">
          <i class="fas fa-question-circle"></i>
        </span>
      </div>
    </div>
  </div>
</div>
