import { Injectable } from '@angular/core'
import { BehaviorSubject, Subject } from 'rxjs'
import { filter, map } from 'rxjs/operators'
import { DraggedItem, ISwimlaneColumnConfig, SwimlaneConfig } from '../model/swimlane.model'
import { isNotNil } from '@engineering11/utility'
import { SwimlaneColumnComponent } from '../components/swimlane/partials'

@Injectable({
  providedIn: 'root',
})
export class SwimlaneService {
  protected swimlaneConfig: SwimlaneConfig = {
    columnsWidth: 320,
    columns: [],
  }

  private swimlaneConfigSource$ = new Subject<SwimlaneConfig>()

  private currentlyDraggingItemSource$ = new BehaviorSubject<boolean>(false)
  private currentlyDraggedItemDataSource$ = new BehaviorSubject<DraggedItem | undefined>(undefined)

  swimlaneConfig$ = this.swimlaneConfigSource$.pipe(filter(isNotNil))

  currentlyDraggingItem$ = this.currentlyDraggingItemSource$.asObservable()
  currentlyDraggedItemData$ = this.currentlyDraggedItemDataSource$.asObservable()
  activeColumnCount$ = this.swimlaneConfig$.pipe(map(config => config.columns.filter(column => column.active).length))

  constructor() {}

  protected get columns() {
    return this.swimlaneConfig.columns
  }

  private swimlaneConfigUpdated() {
    this.swimlaneConfigSource$.next(this.swimlaneConfig)
  }

  private registerColumns(columns: ISwimlaneColumnConfig[]) {
    this.swimlaneConfig.columns = columns
    this.swimlaneConfigUpdated()
  }

  findColumnConfigById(columnId: string) {
    return this.columns.find(column => column.id === columnId)
  }

  toggleActiveColumn(columnId: string) {
    this.swimlaneConfig.columns = this.columns.map(column => {
      if (column.id === columnId) {
        column.active = !column.active
      }
      return column
    })

    this.swimlaneConfigUpdated()
  }

  dragStarted(data: DraggedItem) {
    this.currentlyDraggingItemSource$.next(true)
    this.currentlyDraggedItemDataSource$.next(data)
  }

  dragStopped() {
    this.currentlyDraggingItemSource$.next(false)
    this.currentlyDraggedItemDataSource$.next(undefined)
  }

  setColumns(columns: SwimlaneColumnComponent[]) {
    const cmpToObjArr = columns.map(column => ({
      id: column.id,
      active: column.active,
      title: column.title,
    }))
    this.registerColumns(cmpToObjArr)
  }

  setSwimlaneConfigs(config: SwimlaneConfig) {
    if (config.columns) {
      this.setColumnsConfig(config.columns)
    }

    this.swimlaneConfigUpdated()
  }

  protected setColumnsConfig(updatedColumnsConfig: ISwimlaneColumnConfig[]) {
    const modifiedColumns = this.columns.map(columnConfig => {
      const updatedColumnConfig = updatedColumnsConfig.find(config => config.id === columnConfig.id)
      if (!updatedColumnConfig) return columnConfig
      return updatedColumnConfig
    })

    this.registerColumns(modifiedColumns)
  }
}
