import { animation, AnimationTriggerMetadata, animate, group, state, style, transition, trigger } from '@angular/animations'

export const dynamicAnimationFadeIn = animation([
  style({
    top: '{{ topStart }}',
    opacity: '{{ opacityStart }}',
  }),
  animate('{{ time }}'),
  style({
    top: '{{ topEnd }}',
    opacity: '{{ opacityEnd }}',
  }),
])

export const dynamicAnimationFadeOut = animation([
  style({
    top: '{{ topStart }}',
    opacity: '{{ opacityStart }}',
  }),
  animate('{{ time }}'),
  style({
    top: '{{ topEnd }}',
    opacity: '{{ opacityEnd }}',
  }),
])

export class AnimationFading {
  static fadeIn = AnimationFading.getFadeIn()
  static getFadeIn(): Array<AnimationTriggerMetadata> {
    return [trigger('animationFadeIn', [transition(':enter', [style({ opacity: 0 }), animate('0.4s ease-out', style({ opacity: 1 }))])])]
  }

  static fadeInSlow = AnimationFading.getFadeInSLow()
  static getFadeInSLow(): Array<AnimationTriggerMetadata> {
    return [trigger('animationFadeInSlow', [transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))])])]
  }

  static fadeOut = AnimationFading.getFadeOut()
  static getFadeOut(): Array<AnimationTriggerMetadata> {
    return [trigger('animationFadeOut', [transition(':leave', [style({ opacity: 1 }), animate('0.4s ease-out', style({ opacity: 0 }))])])]
  }

  static fadeOutSlow = AnimationFading.getFadeOutSlow()
  static getFadeOutSlow(): Array<AnimationTriggerMetadata> {
    return [trigger('animationFadeOutSlow', [transition(':leave', [style({ opacity: 1 }), animate('1s ease-out', style({ opacity: 0 }))])])]
  }

  static fadeInOut = AnimationFading.getFadeInOut()
  static getFadeInOut(): Array<AnimationTriggerMetadata> {
    return [
      trigger('animationFadeInOut', [
        transition(':enter', [style({ opacity: 0 }), animate('0.4s ease-out', style({ opacity: 1 }))]),
        transition(':leave', [style({ opacity: 1 }), animate('0.2s ease-in', style({ opacity: 0 }))]),
      ]),
    ]
  }

  static fadeInUp = AnimationFading.getFadeInUp()
  static getFadeInUp(): Array<AnimationTriggerMetadata> {
    return [
      trigger('animationFadeInUp', [
        transition(':enter', [
          group([
            style({ opacity: 0 }),
            animate('0.4s ease-out', style({ opacity: 1 })),
            style({ top: '2rem' }),
            animate('0.8s ease-out', style({ top: '0' })),
          ]),
        ]),
        transition(':leave', [style({ opacity: 1 }), animate('0.2s ease-in', style({ opacity: 0 }))]),
      ]),
    ]
  }

  static scaleUp = AnimationFading.getScaleUp()
  static getScaleUp(): Array<AnimationTriggerMetadata> {
    return [
      trigger('animationScaleUp', [
        transition(':enter', [
          group([
            style({ opacity: 0 }),
            animate('0.2s ease-out', style({ opacity: 1 })),
            style({ transform: 'scale(0.95)' }),
            animate('0.2s ease-out', style({ transform: 'scale(1)' })),
          ]),
        ]),
        transition(':leave', [
          group([
            style({ opacity: 1 }),
            animate('0.2s ease-out', style({ opacity: 0 })),
            style({ transform: 'scale(1)' }),
            animate('0.2s ease-in', style({ transform: 'scale(0.95)' })),
          ]),
        ]),
      ]),
    ]
  }

  static scaleSlideUp = AnimationFading.getScaleSlideUp()
  static getScaleSlideUp(): Array<AnimationTriggerMetadata> {
    return [
      trigger('animationScaleSlideUp', [
        transition(':enter', [
          group([
            style({ opacity: 0 }),
            animate('0.4s ease-out', style({ opacity: 1 })),
            style({ transform: 'scale(0.9)' }),
            animate('0.4s ease-in-out', style({ transform: 'scale(1)' })),
            style({ top: '1rem' }),
            animate('0.4s ease-in-out', style({ top: '0' })),
          ]),
        ]),
        transition(':leave', [style({ opacity: 1 }), animate('0.2s ease-in', style({ opacity: 0 }))]),
      ]),
    ]
  }

  static notification = AnimationFading.getNotification()
  static getNotification(): Array<AnimationTriggerMetadata> {
    return [
      trigger('animationNotification', [
        transition(':enter', [
          group([
            style({ opacity: 0 }),
            animate('0.5s ease-out', style({ opacity: 1 })),
            style({ right: '-8rem' }),
            animate('0.4s ease-out', style({ right: '1rem' })),
          ]),
        ]),
        transition(':leave', [style({ opacity: 1 }), animate('0.4s ease-in', style({ opacity: 0 }))]),
      ]),
    ]
  }

  static sideOver = AnimationFading.getSideOver()
  static getSideOver(): Array<AnimationTriggerMetadata> {
    return [
      trigger('animationSideOver', [
        transition(':enter', [
          group([
            style({ opacity: 0 }),
            animate('0.2s ease-out', style({ opacity: 1 })),
            style({ right: '-100%' }),
            animate('0.4s ease-out', style({ right: '0' })),
            style({ zIndex: '-1' }),
            animate('0s linear', style({ zIndex: '51' })),
          ]),
        ]),
        transition(':leave', [
          group([
            style({ opacity: 1 }),
            animate('0.4s ease-out', style({ opacity: 0 })),
            style({ right: '0' }),
            animate('0.4s ease-out', style({ right: '-50%' })),
          ]),
        ]),
      ]),
    ]
  }

  static shrinkFadeOut = AnimationFading.getShrinkFadeOut()
  static getShrinkFadeOut(): Array<AnimationTriggerMetadata> {
    return [
      trigger('animationShrinkFadeOut', [
        // transition(':enter', [style({ height: '0' }), animate('0s linear', style({ height: '150px' }))]),
        transition(':leave', [
          group([
            style({ opacity: 1 }),
            animate('0.2s ease-out', style({ opacity: 0 })),
            style({ transform: 'scaleY(1)' }),
            animate('0.2s ease-in', style({ transform: 'scaleY(0.75)' })),
          ]),
        ]),
      ]),
    ]
  }
}
