import * as Rollbar from 'rollbar'
import { environment } from '../environments/environment'

// ! Ensure accessToken is accurate

const rollbarConfig: Rollbar.Configuration = {
  // enabled: environment.env !== 'dev', // Don't emit when developing on local
  accessToken: '192c8358406c4f168f78cfc072d28c5f',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment.env,
    server: {
      root: 'webpack:///./',
    },
    client: {
      javascript: {
        source_map_enabled: true, // true by default

        // -- Add this into your configuration ---
        code_version: 'b133d5678fb84cf45076de93401a0446feb4b171',
        // ---------------------------------------

        // Optionally have Rollbar guess which frames the error was
        // thrown from when the browser does not provide line
        // and column numbers.
        guess_uncaught_frames: true,
      },
    },
  },
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig)
}
