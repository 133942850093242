import { AfterContentInit, Component, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList } from '@angular/core'
import { SwimlaneColumnComponent } from './partials'
import { Subject } from 'rxjs'
import { SwimlaneService } from '../../service/swimlane.service'
import { SwimlaneConfig } from '../../model/swimlane.model'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'swimlane',
  templateUrl: './swimlane.component.html',
  styleUrls: ['./swimlane.component.scss'],
})
export class SwimlaneComponent implements OnInit, AfterContentInit, OnDestroy {
  @ContentChildren(SwimlaneColumnComponent) columns!: QueryList<SwimlaneColumnComponent>

  @Input() config: SwimlaneConfig | undefined = undefined
  @Output() swimlaneConfigChanged = new EventEmitter<SwimlaneConfig>()

  destroy$ = new Subject<boolean>()
  activeColumnCount = 0

  constructor(protected swimlaneService: SwimlaneService) {}

  ngOnInit() {
    this.listenToConfig()
  }

  ngAfterContentInit() {
    this.swimlaneService.setColumns(this.columns.toArray())

    if (this.config !== undefined) {
      this.swimlaneService.setSwimlaneConfigs(this.config)
    }
  }

  listenToConfig() {
    //TODO: Refactor this to not need to use subscription
    this.swimlaneService.swimlaneConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => {
      this.activeColumnCount = config.columns.filter(column => column.active).length
      this.swimlaneConfigChanged.emit(config)
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }
}
