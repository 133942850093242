export const RejectionReasons = {
  /** Rejected - Basic Qualifications */
  BasicQualifications: 'BasicQualifications',
  /** Rejected - Not Best Qualified */
  NotBestQualified: 'NotBestQualified',
  /** Rejected - Failed Background Check */
  FailedBackgroundCheck: 'FailedBackgroundCheck',
  /** Rejected - Applicant does not meet Citizenship requirements */
  CitizenshipRequirements: 'CitizenshipRequirements',
  /** Rejected - Applicant has not submitted foreign citizen or e-verify eligibility requirements */
  NotSubmittedCitizenshipEVerify: 'NotSubmittedCitizenshipEVerify',
  /** Rejected - Rejected for unresponsiveness */
  RejectedForUnresponsiveness: 'RejectedForUnresponsiveness',
  /** Rejected - Other */
  Other: 'Other',
} as const

export type RejectionReasons = (typeof RejectionReasons)[keyof typeof RejectionReasons]

export const DispositionCodes = {
  /** Interviewing */
  SelectedForInterview: 'SelectedForInterview',
  /** Offered the Position */
  OfferSent: 'OfferSent',
  /** Candidate Unresponsive */
  CandidateUnresponsive: 'CandidateUnresponsive',
  /** Withdrawn - Applicant Withdrew */
  ApplicantWithdrew: 'ApplicantWithdrew',
  /** Withdrawn - Employer Withdrew on Behalf of Candidate */
  EmployerWithdrewCandidate: 'EmployerWithdrewCandidate',
  /** Rejected - Job has already been filled from within or has been closed */
  JobFilledOrClosed: 'JobFilledOrClosed',
  ...RejectionReasons,
} as const

export type DispositionCodes = (typeof DispositionCodes)[keyof typeof DispositionCodes]

export interface IDispositionCode {
  id: string
  applicationId: string
  dispositionCode: DispositionCodes
  senderId: string
  timestamp: Date
  /** Typed by the employer if they select 'Other' */
  otherInputString?: string
}

export interface IRejectionReason {
  rejectionReason?: RejectionReasons
  otherInputString?: string
}
