import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'
import { IWorkHistory } from '../../model/interfaces'

@Component({
  selector: 'ui-work-history-line-item-card',
  template: `
    <div
      class="e11-flex e11-flex-col e11-relative e11-h-full e11-border e11-border-skin-app-borders e11-rounded-lg e11-p-4 e11-cursor-pointer hover:e11-border-skin-primary-accent hover:e11-bg-skin-primary-accent/10 hover:e11-shadow-md"
    >
      <span
        *ngIf="removable"
        class="e11-line-item-delete e11-flex e11-items-center e11-justify-center e11-bg-white e11-rounded-full e11-border e11-border-skin-app-borders hover:e11-border-skin-primary-accent"
      >
        <span class="material-icons md-18 e11-text-skin-primary-accent hover:e11-text-skin-primary" (click)="deleteItem.emit(workHistory)">
          close
        </span>
      </span>
      <span class="e11-icon-with-attitude" (click)="viewDetail.emit(workHistory); editView.emit(true)">
        <span class="material-icons-outlined md-18 e11-text-skin-primary-accent hover:e11-text-skin-primary"> edit </span>
      </span>
      <span (click)="viewDetail.emit(workHistory); editView.emit(true)">
        <p class="e11-line-item-title e11-break-words e11-line-clamp-2 e11-mb-0 e11-font-bold">{{ workHistory.companyName }}</p>
        <div class="e11-break-words e11-line-clamp-2">{{ workHistory.jobTitle }}</div>
        <div class="e11-break-words e11-line-clamp-2" [innerHTML]="workHistory.jobDescription"></div>
        <date-display-start-end class="e11-text-skin-light e11-text-sm" [item]="workHistory"> </date-display-start-end>
      </span>
    </div>
  `,
  styleUrls: ['./ui-work-history-line-item-card.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiWorkHistoryLineItemCardComponent {
  @Input() workHistory!: IWorkHistory
  @Input() removable: boolean = false

  @Output() viewDetail = new EventEmitter()
  @Output() editView = new EventEmitter()
  @Output() deleteItem = new EventEmitter<IWorkHistory>()
}
