import {
  IPortfolio,
  IPortfolioDocument,
  IPortfolioFile,
  IPortfolioLink,
  IPortfolioPhoto,
  IPortfolioVideo,
  PortfolioItemType,
  PortfolioUiType,
} from '../model/portfolio.model'

export function getPortfolioItemsByType(portfolio: IPortfolio[], fileType: PortfolioUiType): IPortfolio[] {
  return portfolio.filter(p => getPortfolioItemUiType(p) === fileType)
}

type PortfolioUiTypeMimeCheck = PortfolioUiType.Photo | PortfolioUiType.Video | PortfolioUiType.Document
export const mimeTypeGroup: Record<PortfolioUiTypeMimeCheck, string[]> = {
  Photo: ['image'],
  Video: ['video'],
  Document: [
    'application/pdf',
    'application/msword',
    'application/vnd.ms-word',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.wordprocessingml',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml',
    'application/vnd.oasis.opendocument.presentation',
  ],
}

const matchMimeTypeToGroup = (mimeType: string) => {
  for (const [group, mimeTypes] of Object.entries(mimeTypeGroup)) {
    if (mimeTypes.some(type => mimeType.startsWith(type))) {
      return group as PortfolioUiTypeMimeCheck
    }
  }
  return PortfolioUiType.NotSupported
}

export function getPortfolioItemUiType(portfolioItem: IPortfolio): PortfolioUiType {
  if (portfolioItem.itemType === PortfolioItemType.Url) {
    return PortfolioUiType.Link
  }

  return matchMimeTypeToGroup(portfolioItem.file?.mimeType!)
}

export function isPortfolioFile(portfolioItem: IPortfolio): portfolioItem is IPortfolioFile {
  return portfolioItem.itemType === PortfolioItemType.FileUpload
}

export function isPortfolioDocument(portfolioItem: IPortfolio): portfolioItem is IPortfolioDocument {
  return getPortfolioItemUiType(portfolioItem) === PortfolioUiType.Document
}

export function isPortfolioPhoto(portfolioItem: IPortfolio): portfolioItem is IPortfolioPhoto {
  return getPortfolioItemUiType(portfolioItem) === PortfolioUiType.Photo
}

export function isPortfolioVideo(portfolioItem: IPortfolio): portfolioItem is IPortfolioVideo {
  return getPortfolioItemUiType(portfolioItem) === PortfolioUiType.Video
}

export function isPortfolioLink(portfolioItem: IPortfolio): portfolioItem is IPortfolioLink {
  return getPortfolioItemUiType(portfolioItem) === PortfolioUiType.Link
}

export function filterPortfolioDocuments(portfolio: IPortfolio[]): IPortfolioDocument[] {
  return portfolio.filter(isPortfolioDocument)
}

export function filterPortfolioPhotos(portfolio: IPortfolio[]): IPortfolioPhoto[] {
  return portfolio.filter(isPortfolioPhoto)
}

export function filterPortfolioVideos(portfolio: IPortfolio[]): IPortfolioVideo[] {
  return portfolio.filter(isPortfolioVideo)
}

export function filterPortfolioLinks(portfolio: IPortfolio[]): IPortfolioLink[] {
  return portfolio.filter(isPortfolioLink)
}
