import { Injectable } from '@angular/core'
import { MilitaryAffiliation } from '../model/enum'

@Injectable({ providedIn: 'root' })
export class MilitaryAffiliationService {
  affiliationUrlMap: Map<string, string>

  constructor() {
    this.affiliationUrlMap = new Map<string, string>()
    this.affiliationUrlMap.set(
      MilitaryAffiliation.AirForce,
      'https://storage.googleapis.com/cnect-prod-100.appspot.com/assets/military/air-force-seal.png'
    )
    this.affiliationUrlMap.set(MilitaryAffiliation.Army, 'https://content.cnected.com/assets/military/Army%20seal.png')
    this.affiliationUrlMap.set(MilitaryAffiliation.CoastGuard, 'https://content.cnected.com/assets/military/coast-guard-logo.png')
    this.affiliationUrlMap.set(MilitaryAffiliation.MarineCorps, 'https://content.cnected.com/assets/military/Marine%20seal.png')
    this.affiliationUrlMap.set(MilitaryAffiliation.Navy, 'https://content.cnected.com/assets/military/navy-seal.png')
    this.affiliationUrlMap.set(MilitaryAffiliation.SpaceForce, 'https://content.cnected.com/assets/military/space-force.png')
  }

  getUrl(affiliation: MilitaryAffiliation) {
    return this.affiliationUrlMap.get(affiliation)
  }
}
