<div class="skill-certifications">
  <!-- Tabs -->

  <div *ngIf="!pdfMode">
    <div *ngIf="matchedCerts.length !== 0; else emptyState">
      <e11-tabs
        tabTitleOverrides="e11-font-normal e11-text-base"
        [selectedTabId]="filterCurrent"
        (selectedTabChanged)="changedTab($event)"
        [tabsClassOverrides]="'e11-mb-2'"
      >
        <ng-container *ngFor="let filterType of filterTypes">
          <e11-tab
            *ngIf="!_showOnlyMissingCertifications || (_showOnlyMissingCertifications && filterType === FilterTypes.NoMatch)"
            [title]="translationFilterTypes[filterType] | translate"
            [tabId]="filterType"
          >
            <ng-container *ngIf="filterCurrent === filterType">
              <p *ngIf="userMode === UserMode.Employer">
                {{ employerFilterTypeCopy[filterType] | translate }}
              </p>
              <p *ngIf="userMode === UserMode.Candidate">
                {{ candidateFilterTypeCopy[filterType] | translate }}
              </p>
              <ng-container *ngIf="groupedCertifications[filterCurrent] && groupedCertifications[filterCurrent].length > 0; else noCertificate">
                <manage-certifications
                  [readOnly]="readOnly || !(userMode === UserMode.Candidate && filterCurrent === FilterTypes.NoMatch)"
                  [markAsMissingSkills]="readOnly || (userMode === UserMode.Candidate && filterCurrent === FilterTypes.NoMatch)"
                  [currentCertifications]="groupedCertifications[filterCurrent]"
                  [loading]="false"
                  (newCertifications)="newCertifications.emit($event)"
                ></manage-certifications>
              </ng-container>
              <ng-template #noCertificate>
                <h4 class="e11-font-bold e11-text-center e11-my-8">{{ 'No matching certifications' | translate }}</h4>
              </ng-template>
            </ng-container>
          </e11-tab>
        </ng-container>
      </e11-tabs>
    </div>
  </div>

  <!-- EXPANDED FOR PDF -->
  <div *ngIf="pdfMode">
    <ng-container *ngFor="let filterType of filterTypes">
      <ng-container *ngIf="groupedCertifications[filterType].length > 0">
        <section class="e11-mb-8" *ngIf="!_showOnlyMissingCertifications || (_showOnlyMissingCertifications && filterType === FilterTypes.NoMatch)">
          <p class="e11-mb-1 e11-text-md e11-text-skin-base e11-font-bold e11-linebreak ng-star-inserted">
            <span class="e11-border-b e11-border-skin-grey/50">{{ translationFilterTypes[filterType] | translate }}</span>
          </p>
          <p>
            {{ employerFilterTypeCopy[filterType] | translate }}
          </p>
          <ng-container *ngIf="groupedCertifications[filterType] && groupedCertifications[filterType].length > 0; else noCertificate">
            <manage-certifications
              [readOnly]="readOnly || !(userMode === UserMode.Candidate && filterType === FilterTypes.NoMatch)"
              [markAsMissingSkills]="readOnly || (userMode === UserMode.Candidate && filterType === FilterTypes.NoMatch)"
              [currentCertifications]="groupedCertifications[filterType]"
              [loading]="false"
              (newCertifications)="newCertifications.emit($event)"
            ></manage-certifications>
          </ng-container>
          <ng-template #noCertificate>
            <h4 class="e11-font-bold e11-text-center e11-my-8">{{ 'No matching certifications' | translate }}</h4>
          </ng-template>
        </section>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- Pending/open request -->
<request-open *ngIf="openRequest" [request]="openRequest"></request-open>

<ng-template #emptyState>
  <!-- Empty State / Clickable -->
  <div class="e11-w-full">
    <e11-empty-state
      [icon]="'class'"
      [iconSize]="'md-36'"
      title="{{ 'No Certifications' | translate }}"
      [clickable]="false"
      subtitle="{{
        userMode === UserMode.Candidate ? 'You have not added any certificates' : ('The candidate did not add any Certifications.' | translate)
      }}"
    >
    </e11-empty-state>
  </div>
</ng-template>
<div *ngIf="showRequestButton" class="e11-w-full e11-pt-4 e11-text-center">
  <e11-button
    *ngIf="userMode === UserMode.Employer && !openRequest"
    [ghost]="true"
    color="primary-accent"
    value="{{ 'Request Certifications' | translate }}"
    (click)="!requestsDisabled && requestModal()"
    [disabled]="requestsDisabled"
  >
  </e11-button>
</div>
