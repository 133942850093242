export enum ListMode {
  Horizontal,
  Vertical,
}

export enum UserMode {
  Candidate,
  Employer,
  Public,
}

export enum UserModeName {
  Candidate = 'Candidate',
  Employer = 'Employer',
  Public = 'Public',
}
