<div cdkScrollable class="e11-min-h-[800px] e11-max-h-[800px] e11-overflow-x-auto e11-overflow-y-auto">
  <div cdkDropListGroup class="e11-w-full e11-min-h-[800px] e11-flex e11-flex-row">
    <ng-content select="swimlane-column"></ng-content>

    <div *ngIf="!activeColumnCount" class="e11-w-full e11-flex e11-justify-center e11-items-center e11-flex-col e11-border-none">
      <p class="e11-mb-0">{{ "You haven't selected any column" }}</p>
      <p>{{ 'Select one or more columns to view in swimlane' }}</p>
      <swimlane-control></swimlane-control>
    </div>
  </div>
</div>
