import { Component, EventEmitter, Input, Output } from '@angular/core'

export interface TagItem {
  name: string
  level?: string
  editing?: boolean
}

export interface TagAction {
  item: TagItem
  task: 'edit' | 'remove' | 'add'
}

export type TagType = 'skill' | 'certification'

@Component({
  selector: 'ui-tag-candidate',
  templateUrl: './ui-tag.component.html',
  styleUrls: ['./ui-tag.component.scss'],
})
export class UiTagCandidateComponent {
  constructor() {}

  @Input() item?: TagItem // undefined means loading
  @Input() type: TagType = 'skill'
  @Input() readOnly = false
  @Input() markAsMissingSkills = false
  @Input() markAsRequired = false
  @Output() tagAction: EventEmitter<TagAction> = new EventEmitter<TagAction>()

  removeSkill() {
    this.tagAction.emit({ item: this.item!, task: 'remove' })
  }

  actionSelected() {
    if (this.markAsMissingSkills) {
      this.addSkill()
      return
    }

    this.editSkill()
  }

  editSkill() {
    this.tagAction.emit({ item: this.item!, task: 'edit' })
  }

  addSkill() {
    this.tagAction.emit({ item: this.item!, task: 'add' })
  }
}
