import { BaseListenConfig, BaseStaticConfig, Boolean, Default, IFeatures, Json } from '@engineering11/config-web'

// TODO: Experiment with nesting like-features (e.g. promotions, ai)

export interface ICnectFeatures {
  core: boolean
  portfolio: boolean
  virtualDialogues: boolean
  messaging: boolean
  notifications: boolean
  resumeSharing: boolean
  resumeParsing: boolean
  informationalContent: boolean
  candidateProfilePDF: boolean
  referrals: boolean
  network: boolean
  communities: boolean
  candidatePool: boolean
  jobApplicationChannels: boolean
  jobSearch: boolean
  promotions: boolean
  promoteResumeParsing: boolean
  employerJobPostPromotions: boolean
  aiSummary: boolean
  aiJobGeneration: boolean
  employerInterviewQuestions: boolean
  employerJobPromotions: boolean
  employerShareApplication: boolean
  employerJobAutomation: boolean
}

export type CnectFeature = keyof ICnectFeatures
export interface CnectFeatures extends ICnectFeatures, IFeatures {}

export const defaultFeatures: ICnectFeatures = {
  core: false,
  portfolio: false,
  virtualDialogues: false,
  messaging: false,
  notifications: false,
  resumeSharing: false,
  resumeParsing: false,
  informationalContent: false,
  candidateProfilePDF: false,
  referrals: false,
  network: false,
  communities: false,
  candidatePool: false,
  jobApplicationChannels: false,
  jobSearch: false,
  promotions: false,
  promoteResumeParsing: false,
  employerJobPostPromotions: false,
  aiSummary: false,
  aiJobGeneration: false,
  employerInterviewQuestions: false,
  employerJobPromotions: false,
  employerShareApplication: false,
  employerJobAutomation: false,
}

export class CnectConfig implements BaseStaticConfig {
  @Boolean()
  @Default(false)
  fromRemoteConfig!: boolean

  @Json()
  @Default(defaultFeatures)
  features!: CnectFeatures
}

export interface CnectListenConfig extends BaseListenConfig {}
