import { LANGUAGE_CODE_TO_NAME } from '@engineering11/utility'

export interface ILanguage {
  proficiency: number
  code: string // 2 letter language code - en, fr, etc..
}

export const proficiencyMap: Record<number, string> = {
  0: 'No Proficiency',
  1: 'Elementary / Beginner',
  2: 'Limited / Casual Conversational',
  3: 'Professional / Advanced',
  4: 'Full Professional / Translator',
  5: 'Native / Bilingual Proficiency',
}

export const languageCodeMap: Record<string, string> = {}

for (const code in LANGUAGE_CODE_TO_NAME) {
  if (LANGUAGE_CODE_TO_NAME.hasOwnProperty(code)) {
    const languageName = LANGUAGE_CODE_TO_NAME[code]
    languageCodeMap[code] = languageName
  }
}
