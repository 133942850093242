import { AppLayoutUnauthenticatedComponent } from '@admin/app/components/layouts/layout-unauthenticated/layout-unauthenticated.component'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthenticationLoginComponent } from './views/login/login.component'
import { redirectLoggedInToHomeGuard } from '@engineering11/auth-web'

const routes: Routes = [
  {
    path: '',
    component: AppLayoutUnauthenticatedComponent,
    children: [
      {
        path: '',
        component: AuthenticationLoginComponent,
        ...redirectLoggedInToHomeGuard(),
      },
      {
        path: 'login',
        component: AuthenticationLoginComponent,
        ...redirectLoggedInToHomeGuard(),
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthenticationRoutingModule {}
