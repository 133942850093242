<div class="recorder">
  <div class="video">
    <video #recordingElement class="video" controls="false" [ngStyle]="{ display: showRecordingVideoPlayer ? 'block' : 'none' }" autoplay>
      <!-- <source *ngIf="!isVideoRecording && videoBlobUrl"  [src]="videoBlobUrl"> -->
    </video>
    <video
      #videoElement
      class="video"
      controls="false"
      [ngStyle]="{ display: !showRecordingVideoPlayer ? 'block' : 'none' }"
      [volume]="1"
      [src]="videoBlobUrl"
    >
      <!-- <source *ngIf="!isVideoRecording && videoBlobUrl"  [src]="videoBlobUrl"> -->
    </video>
    <template #videoPlayer></template>
  </div>
</div>
