import { ICandidateUser, IEmployerUser } from '@cnect/user-shared'
import { UserType } from '@engineering11/user-shared'
import { ICnectUser } from '../../model/interfaces'

/**
 * Ensures user is a candidate, else throws an error
 * Should remain internal to store
 * @param user
 * @returns
 */
export const _candidateOrError = (user: ICnectUser | null): ICandidateUser | null => {
  if (!user) {
    return null
  }
  if (user.userType === UserType.Consumer) {
    return user
  }
  throw new Error(`User must be of type consumer, but is of type ${user.userType}`)
}

/**
 * Ensures user is a employer, else throws an error
 * Should remain internal to store
 * @param user
 * @returns
 */
export const _employerOrError = (user: ICnectUser | null): IEmployerUser | null => {
  if (!user) {
    return null
  }
  if (user.userType === UserType.Business) {
    return user
  }
  throw new Error(`User must be of type business, but is of type ${user.userType}`)
}
