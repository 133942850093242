import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'
import { Subscription } from 'rxjs'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { IWorkHistory } from '../../model/interfaces'
import { ViewportService } from '@engineering11/web-utilities'

@Component({
  selector: 'candidate-work-history',
  templateUrl: './candidate-work-history.component.html',
  styleUrls: ['./candidate-work-history.component.scss'],
})
export class CandidateWorkHistoryComponent implements OnInit, OnDestroy {
  workHistoryItem: IWorkHistory | undefined
  @Input() workHistory: IWorkHistory[] = []
  @Input() openRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false
  @Input() userMode = UserMode.Candidate
  @Input() showRequestButton = true
  @Output() requestFromComponent = new EventEmitter()

  userModeEnum = UserMode

  isMobile: boolean = false
  viewportSubscription: Subscription

  constructor(private viewportService: ViewportService, private backdropService: E11BackdropService) {
    this.viewportSubscription = this.viewportService.viewportSizeChanged$.subscribe(data => {
      this.isMobile = data == 'sm' || data == 'xs'
    })
  }

  ngOnInit(): void {
    if (this.workHistory.length > 0) {
      this.workHistoryItem = this.workHistory[0]
    }
  }

  ngOnDestroy() {
    this.viewportSubscription.unsubscribe()
  }

  workHistoryViewDetail(item: IWorkHistory) {
    this.workHistoryItem = item
  }

  requestModal() {
    //
    this.requestFromComponent.emit('Work History')
    // this.backdropService.backdropDisplay(true, 'dark')
  }
}
