import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'
import { UserMode } from '../../model/component-modes.enum'
import { DegreeLevel } from '../../model/enum'
import { IEducation } from '../../model/interfaces'

@Component({
  selector: 'ui-education-line-item',
  template: `
    <div class="e11-border e11-border-skin-app-borders e11-p-2 e11-mb-4 e11-rounded">
      <div class="e11-flex">
        <div class="e11-w-3/4">
          <p
            class="e11-font-bold e11-mb-0 e11-line-clamp-3 e11-break-normal"
            *ngIf="!education || education.schoolName"
            [ngClass]="{ 'e11-mb-1': !education }"
          >
            {{ education?.schoolName }}
          </p>
        </div>
        <div class="e11-w-1/4 e11-flex e11-justify-end e11-items-start">
          <span
            *ngIf="userMode === userModeEnum.Candidate && education"
            class="e11-flex e11-items-center e11-cursor-pointer e11-text-skin-primary-accent hover:e11-text-skin-primary e11-text-sm"
            (click)="viewDetail.emit(education); editView.emit(true)"
          >
            <span class="material-icons-outlined md-18 e11-text-skin-primary-accent hover:e11-text-skin-primary e11-mr-1"> edit </span>
            <!-- Edit -->
          </span>
        </div>
      </div>
      <div class="e11-break-all e11-text-sm e11-line-clamp-2" *ngIf="education">{{ stateToStatus.get(education.degreeLevel) || '' | translate }}</div>
      <div class="e11-break-all e11-text-sm e11-line-clamp-2 e11-skeleton-empty" *ngIf="!education || education.degreeTitle">
        {{ education?.degreeTitle }}
      </div>
      <div class="e11-break-all e11-text-sm e11-line-clamp-2" *ngIf="education?.degreeType">{{ education?.degreeType }}</div>
      <div *ngIf="education?.notCompleted" class="e11-text-sm">*{{ 'Not yet Completed' | translate }}</div>
      <date-display-start-end *ngIf="education" class="e11-text-skin-light e11-text-sm" [item]="education"> </date-display-start-end>
    </div>
  `,
  styleUrls: ['./ui-education-line-item.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiEducationLineItemComponent {
  userModeEnum = UserMode

  @Input() userMode: UserMode = UserMode.Candidate
  @Input() education?: IEducation // undefined means skeleton loader
  @Input() removable: boolean = false

  @Output() viewDetail = new EventEmitter()
  @Output() editView = new EventEmitter()
  @Output() deleteItem = new EventEmitter<IEducation>()

  degreeLevel = DegreeLevel

  stateToStatus = new Map<DegreeLevel, string>()
    .set(this.degreeLevel.NotSpecified, myDegreeLevel('Not Specified'))
    .set(this.degreeLevel.HighSchool, myDegreeLevel('High School'))
    .set(this.degreeLevel.VocationalHsDiploma, myDegreeLevel('Vocational High School Diploma'))
    .set(this.degreeLevel.VocationalDegree, myDegreeLevel('Vocational Degree'))
    .set(this.degreeLevel.Associates, myDegreeLevel("Associate's Degree"))
    .set(this.degreeLevel.Bachelors, myDegreeLevel("Bachelor's Degree"))
    .set(this.degreeLevel.Masters, myDegreeLevel("Master's Degree"))
    .set(this.degreeLevel.PhD, myDegreeLevel('Doctorate/PhD'))
}

const myDegreeLevel = (str: string) => `${str}`
