<!-- Empty State -->
<!-- Populated state -->

<div class="e11-grid e11-grid-cols-1 e11-gap-4" *ngIf="education.length > 0">
  <div class="e11-border e11-border-skin-app-borders e11-rounded e11-p-2" *ngFor="let item of education; let i = index">
    <p class="e11-font-bold e11-line-clamp-3 e11-break-normal e11-mb-0">{{ item.schoolName }}</p>
    <div class="e11-break-all e11-text-sm e11-line-clamp-2">{{ stateToStatus.get(item.degreeLevel) || '' | translate }}</div>
    <div class="e11-break-all e11-text-sm e11-line-clamp-2">
      <span>
        {{ item.degreeTitle }}
        <span class="e11-text-sm" *ngIf="item.notCompleted">({{ 'Not yet Completed' | translate }}) </span>
      </span>
    </div>
    <div *ngIf="item.degreeType" class="e11-break-all e11-text-sm e11-line-clamp-2 detail-line-item title">{{ item.degreeType }}</div>
    <date-display-start-end class="detail-line-item" [item]="item"></date-display-start-end>
  </div>
</div>
