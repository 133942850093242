import { Component, Input } from '@angular/core'
import { Optional } from '@engineering11/types'
import { IJobPostApplication } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { ICandidateJobVM } from '../../model/candidate-job.model'

type PersonalDetailFields =
  | 'email'
  | 'address'
  | 'militaryAffiliation'
  | 'firstName'
  | 'lastName'
  | 'candidateResponses'
  | 'phoneNumber'
  | 'photoURL'
  | 'website'
  | 'summary'
type OptionalDetailFields = 'candidateResponses'

export type ICandidatePersonalDisplayDetails = Optional<Pick<IJobPostApplication, PersonalDetailFields>, OptionalDetailFields>

@Component({
  selector: 'candidate-personal',
  templateUrl: './candidate-personal.component.html',
  styleUrls: ['./candidate-personal.component.scss'],
})
export class CandidatePersonalComponent {
  paramEmail = { value: 'Email' }
  paramAddress = { value: 'Address' }
  paramPhone = { value: 'Phone number' }
  paramSummary = { value: 'Summary' }
  paramMilitary = { value: 'Military affiliation' }
  paramVisa = { value: 'Visa sponsorship' }
  showConfirmationDetails: boolean = false
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() fromCandidate = false
  @Input() candidate: ICandidatePersonalDisplayDetails | null = null
  @Input() hideEmptyCards = false
  // @Input() candidate!: IJobPostApplication | IProcessedJobApplication
  @Input() jobPost?: ICandidateJobVM

  constructor() {}

  makeStarRatingArray(stars?: number) {
    if (!stars || stars < 1) {
      return []
    }
    let arr: number[] = new Array(stars)
    return arr
  }

  openCandidateWebsite(website: any) {
    let url: string = ''
    if (!/^http[s]?:\/\//.test(website)) {
      url += 'http://'
    }
    url += website
    window.open(url, '_blank')
  }

  toggleConfirmationDetails() {
    // This could be handled in the template but putting here incase we need to expand on the functionality later
    this.showConfirmationDetails = !this.showConfirmationDetails
  }
}
