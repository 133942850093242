import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core'
import { SwimlaneService } from '../../../service/swimlane.service'
import { ISwimlaneColumnConfig } from '../../../model/swimlane.model'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

@Component({
  selector: 'swimlane-control',
  template: `
    <div class="e11-flex e11-items-center e11-gap-1" [ngClass]="{ 'e11-pointer-events-none e11-cursor-not-allowed e11-opacity-50': isDisabled }">
      <div class="wrapper-columns e11-relative" (mouseenter)="showColumns = true" (mouseleave)="showColumns = false">
        <span class="e11-font-primary-demibold e11-pr-1 e11-pointer-events-none">{{ 'Show Columns:' }}</span>
        <span class="e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer e11-inline-flex e11-items-center">
          {{ activeColumnCount }} {{ 'of' }} {{ columns?.length }} <span class="material-icons">expand_more</span></span
        >
        <ul
          class="e11-absolute e11-z-20 e11-right-0 e11-bg-skin-white e11-border e11-border-skin-grey/50 e11-rounded-md e11-py-2"
          *ngIf="showColumns"
        >
          <li
            *ngFor="let column of columns"
            class="e11-py-1 e11-px-4 e11-text-sm hover:e11-bg-skin-grey/20 e11-text-skin-secondary e11-cursor-pointer e11-font-primary-medium"
            custom-option
          >
            <e11-input-checkbox
              (change)="selectColumn(column)"
              [checked]="column.active"
              [color]="'primary-accent'"
              [ring]="true"
              display="inline"
              label=" {{ column.title || 'Untitled' | translate }}"
            ></e11-input-checkbox>
          </li>
        </ul>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwimlaneControlComponent implements OnInit, OnDestroy, AfterViewChecked {
  columns: ISwimlaneColumnConfig[] = []
  activeColumnCount = 0
  showColumns = false
  @Input() isDisabled = false

  destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(protected swimlaneService: SwimlaneService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.swimlaneService.swimlaneConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => {
      this.columns = config.columns
      this.activeColumnCount = this.columns.filter(column => column.active).length
    })
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges()
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  selectColumn(columnConfig: ISwimlaneColumnConfig) {
    this.swimlaneService.toggleActiveColumn(columnConfig.id)
  }
}
