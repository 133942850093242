import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core'
import { CdkDragDrop } from '@angular/cdk/drag-drop'
import { SwimlaneColumnItemDirective } from './swimlane-column-item.directive'
import { SwimlaneService } from '../../../service/swimlane.service'
import { DroppedItemData, ISwimlaneColumnConfig } from '../../../model/swimlane.model'
import { SwimlaneColumnHeaderDirective } from './swimlane-column-header.directive'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { htmlId } from '@engineering11/utility'

@Component({
  selector: 'swimlane-column',
  template: `
    <ng-container *ngrxLet="currentlyDraggingItem$ as currentlyDraggingItem">
      <div
        (cdkDropListDropped)="drop($event)"
        [cdkDropListDisabled]="disableDragging"
        [cdkDropListSortingDisabled]="!enableSorting"
        [ngClass]="{ 'e11-hidden': !active }"
        [style.width.px]="width"
        [cdkDropListAutoScrollStep]="20"
        cdkDropList
        class="swimlane-column e11-pr-4 e11-mr-4 e11-relative e11-h-full e11-border-r e11-border-skin-grey/50"
      >
        <ng-container *ngrxLet="currentlyDraggedData$ as currentlyDraggedItemData">
          <div class="swimlane-column-header e11-sticky e11-top-0 e11-z-[1] e11-bg-skin-white e11-pb-1">
            <ng-container *ngIf="header" [ngTemplateOutlet]="header.templateRef"></ng-container>

            <div
              #columnDropPreview
              *ngIf="useDropzone && currentlyDraggingItem && currentlyDraggedItemData?.column?.id !== id"
              class="e11-mb-4 e11-cursor-grab e11-text-sm e11-gap-2 e11-rounded e11-border-2 e11-h-80 e11-w-full e11-border-skin-app-borders hover:e11-border-skin-primary-accent e11-flex e11-items-center e11-justify-center"
              id="column-drop-preview"
            >
              {{ currentlyDraggedItemData?.column?.title }} <span class="material-icons-outlined e11-text-sm">arrow_forward</span> {{ title }}
            </div>
          </div>

          <div
            (cdkDragEnded)="dragStopped()"
            (cdkDragStarted)="dragStarted($event)"
            *ngFor="let content of contents"
            [cdkDragData]="{ column: { id, title }, data: content.columnItemData }"
            [cdkDragDisabled]="content.disableDrag"
            [ngClass]="{ 'e11-cursor-grabbing': currentlyDraggingItem }"
            cdkDrag
            class="e11-mb-4 swimlane-column-item cdk-no-animation"
          >
            <ng-container *ngIf="useDropzone && !enableSorting">
              <div *cdkDragPlaceholder></div>
            </ng-container>
            <div [ngClass]="{ 'e11-opacity-0': currentlyDraggingItem && currentlyDraggedItemData?.column?.id !== id }">
              <ng-container [ngTemplateOutlet]="content.templateRef"></ng-container>
            </div>
          </div>

          <ng-container *ngIf="!currentlyDraggingItem">
            <ng-content></ng-content>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwimlaneColumnComponent implements OnInit, AfterViewChecked, OnDestroy, ISwimlaneColumnConfig {
  @ContentChild(SwimlaneColumnHeaderDirective) header!: SwimlaneColumnHeaderDirective
  @ContentChildren(SwimlaneColumnItemDirective, { descendants: true }) contents!: QueryList<SwimlaneColumnItemDirective>
  @ViewChild('columnDropPreview') columnDropPreview!: ElementRef

  @Input() id: string = htmlId('swimlane-column')
  @Input() title: string = ''

  @Input() enableSorting: boolean = true
  @Input() disableDragging: boolean = false
  @Input() useDropzone: boolean = true

  @Output() itemDropped = new EventEmitter<DroppedItemData>()

  /** The width of the column in pixels */
  width: number = 240
  active: boolean = true
  currentlyDraggingItem$ = this.swimlaneService.currentlyDraggingItem$
  currentlyDraggedData$ = this.swimlaneService.currentlyDraggedItemData$
  destroy$ = new Subject<boolean>()

  constructor(protected elementRef: ElementRef, protected swimlaneService: SwimlaneService, private cdr: ChangeDetectorRef) {
    this.listenToConfig()
  }

  private listenToConfig() {
    this.swimlaneService.swimlaneConfig$.pipe(takeUntil(this.destroy$)).subscribe(config => {
      const columnConfig = this.swimlaneService.findColumnConfigById(this.id)
      this.setActiveState(columnConfig?.active ?? true)
      this.width = columnConfig?.width ? columnConfig.width : config.columnsWidth
    })
  }

  ngOnInit() {
    this.listenToConfig()
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges()
  }

  ngOnDestroy() {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  dragStarted(event: any) {
    this.swimlaneService.dragStarted(event.source.data)
  }

  dragStopped() {
    setTimeout(() => {
      this.swimlaneService.dragStopped()
    }, 100) // wait for the animation to finish
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.useDropzone && !this.checkItemDroppedInZone(event)) {
      return
    }

    this.itemDropped.emit({
      data: event.item.data.data,
      previousColumn: {
        ...event.item.data.column,
        index: event.previousIndex,
      },
      currentColumn: {
        id: this.id,
        title: this.title,
        index: event.currentIndex,
      },
    })
  }

  private setActiveState(isActive: boolean) {
    this.active = isActive
    // fixes dividers not being hidden when column is inactive
    this.elementRef.nativeElement.classList.toggle('e11-border-none', !this.active)
  }

  private checkItemDroppedInZone(event: CdkDragDrop<string[]>) {
    if (!this.columnDropPreview) {
      return false
    }

    const element = this.columnDropPreview.nativeElement as HTMLElement
    const rect = element.getBoundingClientRect()
    const mouseX = event.dropPoint.x
    const mouseY = event.dropPoint.y

    return mouseX >= rect.left && mouseX <= rect.right && mouseY >= rect.top && mouseY <= rect.bottom
  }
}
