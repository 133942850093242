import { environment } from '@admin/environments/environment'
import { ErrorHandler, NgModule } from '@angular/core'
import { AngularFireModule } from '@angular/fire/compat'
import { AngularFireAuthModule, TENANT_ID } from '@angular/fire/compat/auth'
import { AngularFirestoreModule } from '@angular/fire/compat/firestore'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AccessModule } from '@engineering11/access-web'
import { NgAisModule } from '@engineering11/angular-instantsearch'
import { CustomerModule } from '@engineering11/customer-web'
import { FilesModule } from '@engineering11/files-web'
import { MapsModule } from '@engineering11/maps-web'
import { NotificationsEditorModule } from '@engineering11/notifications-web'
import { PlatformAdminModule } from '@engineering11/platform-admin-web'
import { RegistrationModule } from '@engineering11/registration-web'
import { E11InputModule } from '@engineering11/ui-lib/e11-input'
import { E11NotificationsModule } from '@engineering11/ui-lib/e11-notifications'
import { E11TextareaModule } from '@engineering11/ui-lib/e11-textarea'
import { IUserRestService, PlatformAdminUserRestService, UserModule } from '@engineering11/user-web'
import { E11ErrorModule, GlobalErrorHandler } from '@engineering11/web-api-error'
import { E11AlertNotificationService } from '@engineering11/web-ui-helpers'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import firebase from 'firebase/compat/app'
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha'
import { LoggerModule, NGXLogger, NgxLoggerLevel } from 'ngx-logger'
import { INPUTS_MAXLENGTH_BY_CONTEXT, WebpackTranslateLoader, componentStateReducer, errorHandlerOverrides } from 'shared-lib'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AccessConfigProvider } from './config/access.config'
import { AdminCustomerConfigProvider, AdminCustomerPartitionConfigProvider } from './config/customer.config'
import { AdminFilesConfigProvider } from './config/files.config'
import { NotificationConfigProvider } from './config/notification.config'
import { AdminRegistrationConfigProvider } from './config/registration.config'
import { SdkAdminConfigProvider } from './config/sdk-admin.config'
import { UserConfigProvider } from './config/user.config'
import { dynamicComponents } from './dynamic.components'
import { E11ComponentLibraryModule } from './modules/_component-library/component-library.module'
import { AuthenticationModule } from './modules/authentication/authentication.module'
import { rollbarFactory } from './rollbar'
import * as fromStore from './store/index'
import { CustomerClientSubscriptionsComponent, PaymentModule } from '@engineering11/payment-web'
import { PaymentConfigProvider } from './config/payment.config'

firebase.initializeApp(environment.firebaseConfig)
try {
  firebase.firestore().settings({ ignoreUndefinedProperties: true, merge: true })
} catch (e) {}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    E11ComponentLibraryModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
      defaultLanguage: 'en',
    }),
    RecaptchaModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    NgAisModule.forRoot(),
    StoreModule.forRoot({ ...fromStore.reducers, componentStore: componentStateReducer }),
    EffectsModule.forRoot(fromStore.effects),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50, connectInZone: true }) : [],
    LoggerModule.forRoot({ level: !environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.OFF, serverLogLevel: NgxLoggerLevel.OFF }),
    E11ErrorModule.forRoot({
      errorTrackerFactory: rollbarFactory,
      logger: NGXLogger,
      errorHandlerMap: errorHandlerOverrides,
      alertService: E11AlertNotificationService,
    }),
    E11InputModule.forRoot({ maxLengthByContext: INPUTS_MAXLENGTH_BY_CONTEXT }),
    E11TextareaModule.forRoot({ maxLengthByContext: INPUTS_MAXLENGTH_BY_CONTEXT }),
    E11NotificationsModule.forRoot(),
    AccessModule.forRoot({ configProvider: AccessConfigProvider }),
    PaymentModule.forRoot({
      configProvider: PaymentConfigProvider,
      // stripePublishableKey: environment.stripePublishableKey
    }),
    CustomerModule.forRoot({
      configProvider: AdminCustomerConfigProvider,
      partitionConfigProvider: AdminCustomerPartitionConfigProvider,
      dynamicCustomersTabComponents: [
        {
          label: 'Subscriptions',
          id: 'subscriptions',
          component: CustomerClientSubscriptionsComponent,
        },
      ],
    }),
    UserModule.forRoot({ configProvider: UserConfigProvider }),
    RegistrationModule.forRoot({ configProvider: AdminRegistrationConfigProvider }),
    NotificationsEditorModule.forRoot({ configProvider: NotificationConfigProvider }),
    FilesModule.forRoot({ configProvider: AdminFilesConfigProvider }),
    PlatformAdminModule.forRoot({ configProvider: SdkAdminConfigProvider, dynamicComponents }),
    AngularFireAuthModule,
    AngularFirestoreModule,
    MapsModule.forRoot({ mapsApiKey: environment.firebaseConfig.apiKey }),
    AuthenticationModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.v2ReCaptcha } as RecaptchaSettings,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: TENANT_ID, useValue: environment.tenantId },
    { provide: IUserRestService, useClass: PlatformAdminUserRestService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
