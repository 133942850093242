<div class="settings">
  <div class="row">
    <div class="col-xs-7">
      <h3 class="text-accent-blue">{{ 'Recording Settings' | translate }}</h3>
    </div>
    <div class="col-xs-5 text-right">
      <span class="control" (click)="close()">
        <i class="fas fa-lg fa-times text-accent-blue"></i>
      </span>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-5">
      {{ 'Video Input' | translate }}
    </div>
    <div class="col-xs-7">
      <select name="selectedVideoDevice" [(ngModel)]="selectedVideoDevice" (ngModelChange)="updateDefaultVideoDevice($event)">
        <option>{{ 'Select default Video input' | translate }}</option>
        <option *ngFor="let device of videoDevices" [value]="device.deviceId">
          {{ device.label }}
        </option>
      </select>
    </div>
  </div>

  <div class="row">
    <div class="col-xs-5">
      {{ 'Audio Input' | translate }}
    </div>
    <div class="col-xs-7">
      <select name="selectedAudioDevice" [(ngModel)]="selectedAudioDevice" (ngModelChange)="updateDefaultAudioDevice($event)">
        <option>{{ 'Select default Audio input' | translate }}</option>
        <option *ngFor="let device of audioDevices" [value]="device.deviceId">
          {{ device.label }}
        </option>
      </select>
    </div>
  </div>
  <ng-container *ngIf="videoRecordingSettings.enableTeleprompterSettings">
    <div class="row pointer">
      <div class="col-xs-5">
        {{ 'Show Teleprompter' | translate }}
      </div>
      <div class="col-xs-7 pointer">
        <e11-switch size="sm" [checked]="teleprompter" (valueChange)="toggleTeleprompter(teleprompter)"></e11-switch>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-5">
        {{ 'Teleprompter Speed' | translate }}
      </div>
      <div class="col-xs-7">
        <input type="range" min="0" max="100" (input)="(time.value); setTeleprompterSpeed($event)" #time />
        <!-- Keep in case needed later -->
        <!-- <span class="tooltip">
                <i class="fas fa-lg fa-info-circle"></i>
                <span>{{'The teleprompter speed is more of a duration based on the length of your video. As you increase this setting you increase the duration meaning your script will scroll slower. Decreasing the duration will make the script scroll faster.' | translate }}</span>
            </span> -->
      </div>
    </div>

    <div class="row">
      <div class="col-xs-5">
        {{ 'Teleprompter Size' | translate }}
      </div>
      <div class="col-xs-7">
        <input type="range" min="8" max="32" value="24" step="1" (input)="(size.value); setTeleprompterSize($event)" #size />
        <span>{{ videoRecordingSettings?.teleprompter?.size }}px</span>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-5">
        {{ 'Teleprompter Width' | translate }}
      </div>
      <div class="col-xs-7">
        <input type="range" min="1" max="100" step="1" value="100" (input)="(width.value); setTeleprompterWidth($event)" #width />
        <span>{{ videoRecordingSettings?.teleprompter?.width }}%</span>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-5">
        {{ 'Teleprompter Script' | translate }}
      </div>
      <div class="col-xs-7">
        <e11-button [color]="'secondary'" value="{{ 'Edit Script' | translate }}" [size]="'sm'" (click)="modalScript.open()"> </e11-button>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-5">
        {{ 'Test Settings' | translate }}
      </div>
      <div class="col-xs-7 testing">
        <span class="control" [ngClass]="{ 'text-disabled': !teleprompter }" *ngIf="!teleprompterTesting" (click)="teleprompterPlay()">
          {{ 'Play' | translate }} <i class="fas fa-play"></i
        ></span>
        <span class="control" [ngClass]="{ 'text-disabled': !teleprompter }" *ngIf="teleprompterTesting" (click)="teleprompterStop()">
          {{ 'Stop' | translate }} <i class="fas fa-stop"></i
        ></span>
      </div>
    </div>
  </ng-container>

  <div class="row pointer">
    <div class="col-xs-5">
      {{ 'Show Tips & Tutorial' | translate }}
    </div>
    <div class="col-xs-7">
      <e11-switch size="sm" [checked]="videoRecordingSettings.tutorialsEnabled" (valueChange)="showModalTips($event)"> </e11-switch>
    </div>
  </div>
</div>

<!-- Script modal -->
<e11-modal #modalScript [width]="'sm'" [showHeader]="false" [closer]="true" [showFooter]="false" [bodyHeight]="'large'">
  <div modal-body>
    <form action="" [formGroup]="formScript" (ngSubmit)="onSubmitFormScript()" novalidate>
      <div class="row">
        <div class="col-xs-12 text-left">
          <h3 class="text-accent-blue">
            {{ 'Script Editor' | translate }}
          </h3>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="script">
            <e11-textarea
              id="textarea_script"
              name="script"
              borderGlow="primary"
              [parentForm]="formScript"
              formControlName="script"
              [rows]="10"
              [cols]="0"
            >
            </e11-textarea>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 text-center">
          <e11-button
            id="button_cancel"
            value="{{ 'Cancel' | translate }}"
            [color]="'neutral'"
            [type]="'button'"
            [buttonGroup]="true"
            [ghost]="true"
            (click)="modalScript.close()"
            [buttonGroup]="true"
          >
          </e11-button>
          <e11-button id="button_save" value="{{ 'Save' | translate }}" [color]="'primary-accent'" [type]="'submit'"> </e11-button>
        </div>
      </div>
    </form>
  </div>
</e11-modal>
