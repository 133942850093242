import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { ModalTemplateKey } from '@engineering11/ui-lib/e11-modal'
import { isNotNil, omit } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { Store } from '@ngrx/store'
import { UiScrollLockingService } from 'projects/shared-lib/src/lib/service/scroll-locking.service'
import { filter, Subject, takeUntil, withLatestFrom } from 'rxjs'
import { hookToGlobalState } from '../../config/link-global.state'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { IPortfolio, PortfolioItemType, PortfolioUiType } from '../../model/portfolio.model'
import { portfolioBodyTextByUserMode } from './portfolio-constants'
import { PortfolioStore } from './portfolio.store.component'

@Component({
  selector: 'manage-portfolio',
  template: `
    <ng-container *ngrxLet="portfolio$ as portfolio">
      <div class="wrapper e11-mb-4">
        <div
          *ngIf="userMode !== UserMode.Public && showTitle"
          class="e11-flex e11-w-full e11-items-center e11-border-b e11-border-skin-grey/50 e11-pb-2 e11-mb-4"
        >
          <div class="e11-grow">
            <h4 class="e11-font-skin-secondary e11-font-bold">
              {{ 'Portfolio' | translate }}
            </h4>
          </div>
        </div>

        <div>
          <div *ngIf="portfolio.length > 0 || userMode === UserMode.Candidate; else EmployerEmpty">
            <h4 *ngIf="userMode === UserMode.Candidate" class="e11-text-base e11-mb-4">
              {{
                'Here in your Portfolio section, you can add links to your personal work or websites, documents, videos, and images you would like to showcase to employers or other people you may want to share this profile with.'
                  | translate
              }}
            </h4>
            <div class="e11-grid e11-grid-cols-1 xl:e11-grid-cols-2 e11-gap-4">
              <ui-portfolio-card
                [userMode]="userMode"
                [itemCount]="(portfolio | portfolioLinks).length"
                [itemType]="PortfolioUiType.Link"
                (cardClicked)="modalLinks.open()"
                [readonly]="readOnly"
                [loading]="loading"
                [items]="portfolio | portfolioLinks"
                [pdfMode]="pdfMode"
              ></ui-portfolio-card>

              <conditional-link-wrapper [link]="cleanURL" [shouldWrap]="pdfMode">
                <ng-template [e11Template]="'Body'">
                  <ui-portfolio-card
                    [userMode]="userMode"
                    [itemCount]="(portfolio | portfolioDocuments).length"
                    [itemType]="PortfolioUiType.Document"
                    (cardClicked)="modalDocuments.open()"
                    [items]="portfolio | portfolioDocuments"
                    [readonly]="readOnly"
                    [loading]="loading"
                    [pdfMode]="pdfMode"
                  ></ui-portfolio-card>
                </ng-template>
              </conditional-link-wrapper>

              <conditional-link-wrapper [link]="cleanURL" [shouldWrap]="pdfMode">
                <ng-template [e11Template]="'Body'">
                  <ui-portfolio-card
                    [userMode]="userMode"
                    [readonly]="readOnly"
                    [itemCount]="(portfolio | portfolioVideos).length"
                    [itemType]="PortfolioUiType.Video"
                    (cardClicked)="modalVideos.open()"
                    [items]="portfolio | portfolioVideos"
                    [loading]="loading"
                    [pdfMode]="pdfMode"
                  ></ui-portfolio-card>
                </ng-template>
              </conditional-link-wrapper>

              <conditional-link-wrapper [link]="cleanURL" [shouldWrap]="pdfMode">
                <ng-template [e11Template]="'Body'">
                  <ui-portfolio-card
                    [userMode]="userMode"
                    [readonly]="readOnly"
                    [itemCount]="(portfolio | portfolioPhotos).length"
                    [itemType]="PortfolioUiType.Photo"
                    (cardClicked)="modalPhotos.open()"
                    [items]="portfolio | portfolioPhotos"
                    [loading]="loading"
                    [pdfMode]="pdfMode"
                  ></ui-portfolio-card>
                </ng-template>
              </conditional-link-wrapper>
            </div>
          </div>
        </div>
      </div>
      <ng-template *ngIf="showRequestButton">
        <div class="e11-w-full e11-text-center e11-my-4">
          <e11-button
            *ngIf="!openRequest && userMode === UserMode.Employer"
            (click)="!requestsDisabled && requestModal()"
            [disabled]="requestsDisabled"
            color="primary-accent"
            [ghost]="true"
            value="{{ 'Request Portfolio Items' | translate }}"
          ></e11-button>
        </div>
        <!-- Pending/open request -->
        <request-open *ngIf="openRequest && userMode === UserMode.Employer" [request]="openRequest"></request-open>
      </ng-template>
      <ng-template #EmployerEmpty>
        <e11-empty-state
          *ngIf="!loading"
          [icon]="'collections_bookmark'"
          [clickable]="false"
          [iconSize]="'md-36'"
          title="{{ 'No Portfolio' | translate }}"
          subtitle="{{ 'Candidate did not supply a portfolio.' | translate }}"
          [containerClassOverrides]="'e11-mt-0'"
        >
        </e11-empty-state>
      </ng-template>
      <e11-modal
        #modalLinks
        [width]="'lg'"
        [bodyHeight]="'half'"
        [showFooter]="false"
        [showHeader]="true"
        title="{{ portfolioBodyTextByUserMode[userMode] + ' Links' | translate }}"
      >
        <ng-template e11Template="{{ ModalTemplateKey.Body }}">
          <portfolio-links
            [userMode]="readOnly ? UserMode.Employer : userMode"
            [portfolioLinks]="portfolio | portfolioLinks"
            (closeModal)="modalLinks.close()"
          ></portfolio-links>
        </ng-template>
      </e11-modal>
      <e11-modal
        #modalDocuments
        [width]="'lg'"
        [bodyHeight]="'large'"
        [showFooter]="false"
        [showHeader]="true"
        title="{{ portfolioBodyTextByUserMode[userMode] + ' Documents' | translate }}"
      >
        <ng-template e11Template="{{ ModalTemplateKey.Body }}">
          <portfolio-documents
            [userMode]="readOnly ? UserMode.Employer : userMode"
            [portfolioDocuments]="portfolio | portfolioDocuments | deepCopy"
            (closeModal)="modalDocuments.close()"
          ></portfolio-documents>
        </ng-template>
      </e11-modal>
      <e11-modal
        #modalVideos
        [width]="'lg'"
        [bodyHeight]="'large'"
        [showFooter]="false"
        [showHeader]="true"
        title="{{ portfolioBodyTextByUserMode[userMode] + ' Videos' | translate }}"
        (modalClosed)="resetScrollLock()"
      >
        <ng-template e11Template="{{ ModalTemplateKey.Body }}">
          <portfolio-videos
            [userMode]="readOnly ? UserMode.Employer : userMode"
            [portfolioVideos]="portfolio | portfolioVideos | deepCopy"
            (closeModal)="modalVideos.close()"
          ></portfolio-videos>
        </ng-template>
      </e11-modal>
      <e11-modal
        #modalPhotos
        [width]="'lg'"
        [bodyHeight]="'large'"
        [showFooter]="false"
        [showHeader]="true"
        title="{{ portfolioBodyTextByUserMode[userMode] + ' Images' | translate }}"
        (modalClosed)="resetScrollLock()"
      >
        <ng-template e11Template="{{ ModalTemplateKey.Body }}">
          <portfolio-photos
            [userMode]="readOnly ? UserMode.Employer : userMode"
            [portfolioPhotos]="portfolio | portfolioPhotos | deepCopy"
            (closeModal)="modalPhotos.close()"
          ></portfolio-photos>
        </ng-template>
      </e11-modal>
    </ng-container>
  `,
})
export class PortfolioComponent implements OnInit, OnDestroy {
  ModalTemplateKey = ModalTemplateKey
  PortfolioItemType = PortfolioItemType
  PortfolioUiType = PortfolioUiType
  portfolioBodyTextByUserMode = portfolioBodyTextByUserMode

  @Input() set portfolio(portfolio: IPortfolio[]) {
    this.portfolioStore.setAllPortfolio(portfolio)
  }

  @Input() cleanURL?: string
  @Input() pdfMode: boolean = false
  @Input() showRequestButton = true
  @Input() loading = false
  @Input() readOnly = false
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() openRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false
  @Input() showTitle: boolean = true
  @Output() newPortfolio: EventEmitter<IPortfolio[]> = new EventEmitter()
  @Output() requestFromComponent: EventEmitter<string> = new EventEmitter()

  portfolio$ = this.portfolioStore.portfolio$

  UserMode = UserMode

  destroy$ = new Subject<boolean>()

  constructor(
    private store: Store,
    private scrollLockService: UiScrollLockingService,
    private portfolioStore: PortfolioStore,
    private logger: E11Logger
  ) {}

  ngOnInit(): void {
    hookToGlobalState(this.portfolioStore.getState, 'portfolio', this.store)

    // When portfolios change within any of the internal components, emit out the change for the parent to handle
    this.portfolioStore.lastPortfolioInternalChange$
      .pipe(takeUntil(this.destroy$), filter(isNotNil), withLatestFrom(this.portfolio$))
      .subscribe(([lastPortfolioInternalChange, newPortfolio]) => {
        this.logger.log('PortfolioComponent', { lastPortfolioInternalChange, newPortfolio })
        // explicitly only sending file and thumb if they exist and not empty
        // this is to avoid breaking validation on the backend for applications
        const portfolioToEmit: IPortfolio[] = newPortfolio.map(p => ({
          ...p,
          file: p.file ? omit(p.file, 'signedURL') : undefined,
          thumb: p.thumb?.id ? omit(p.thumb, 'signedURL') : undefined,
        }))
        this.newPortfolio.emit(portfolioToEmit)
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  requestModal() {
    this.requestFromComponent.emit('Portfolio')
  }

  resetScrollLock() {
    this.scrollLockService.scrollLock(false)
  }
}
