import { IErrorTypeHandlerMap, TrackErrorE11ErrorHandler, TrackWarningE11ErrorHandler } from '@engineering11/web-api-error'
import { NotificationE11ErrorHandler } from './notification-error.handler'
import { ERROR_TYPES as SOCIAL_ERRORS } from '@engineering11/social-web'
import { ERROR_TYPES as MESSAGING_ERRORS } from '@engineering11/messaging-web'
import { FILES_ERRORS } from '@engineering11/files-web'

export const CNECT_ERRORS = Object.freeze({
  // SHARED
  DATE_UNPARSABLE: 'cnect/error/date-unparsable',

  // CANDIDATE
  APPLICATION_JOB_CLOSED: 'application/job-post-closed',
  RESUME_PARSE_DOCUMENT_ERROR: 'candidate/resume-parse-document-failure',
  RESUME_PARSE_FAILURE: 'candidate/resume-parse-failure',
  RESUME_FILE_TOO_LARGE: 'candidate/resume-file-too-large',
  JOB_URL_NOT_FOUND: 'candidate/job-search/job-url-not-found',
  CANDIDATE_JOB_NOT_FOUND: 'candidate/job-post-not-found',
  JOB_CUSTOMER_NOT_FOUND: 'candidate/job-customer-not-found',
})

export const errorHandlerOverrides: IErrorTypeHandlerMap = {
  [CNECT_ERRORS.DATE_UNPARSABLE]: TrackErrorE11ErrorHandler,
  [CNECT_ERRORS.APPLICATION_JOB_CLOSED]: NotificationE11ErrorHandler,
  [CNECT_ERRORS.RESUME_PARSE_DOCUMENT_ERROR]: NotificationE11ErrorHandler,
  [CNECT_ERRORS.RESUME_PARSE_FAILURE]: NotificationE11ErrorHandler,
  [CNECT_ERRORS.RESUME_FILE_TOO_LARGE]: NotificationE11ErrorHandler,
  [CNECT_ERRORS.JOB_URL_NOT_FOUND]: NotificationE11ErrorHandler,
  [CNECT_ERRORS.CANDIDATE_JOB_NOT_FOUND]: NotificationE11ErrorHandler,
  [CNECT_ERRORS.JOB_CUSTOMER_NOT_FOUND]: NotificationE11ErrorHandler,
  [MESSAGING_ERRORS.CUSTOM_DATA]: TrackWarningE11ErrorHandler,
  [FILES_ERRORS.FILE_TOO_LARGE]: NotificationE11ErrorHandler,
  [FILES_ERRORS.FILE_INVALID_TYPE]: NotificationE11ErrorHandler,
}
Object.values(SOCIAL_ERRORS).forEach(value => (errorHandlerOverrides[value] = NotificationE11ErrorHandler))

/**
 * Returns whether the given error type is handled within cnect
 * This can be useful to specify a specific error handler for errors from a specific endpoint or operation that are unhandled
 * e.g. some operations should ALWAYS show a notification unless explicitly handled otherwise
 * @param type
 * @returns
 */
export function isHandledCnectErrorType(type: string): boolean {
  return Object.keys(errorHandlerOverrides).includes(type)
}
