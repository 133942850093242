<form *ngIf="videoFile" class="e11-h-full e11-divide-y e11-divide-gray-200 e11-flex e11-flex-col e11-bg-white e11-shadow-xl">
  <div class="e11-flex-1 e11-h-0 e11-overflow-y-auto e11-overscroll-none">
    <div class="e11-py-6 e11-px-4 e11-bg-skin-primary sm:e11-px-6">
      <div class="e11-flex e11-items-center e11-justify-between">
        <h3 class="e11-text-white e11-mb-0" id="slide-over-title">
          {{ title | translate }}
        </h3>
        <div class="e11-ml-3 e11-h-7 e11-flex e11-items-center">
          <button
            (click)="close()"
            [ngClass]="{ 'e11-cursor-wait': uploadLoading }"
            class="e11-bg-skin-primary e11-rounded-md e11-text-skin-primary-accent hover:e11-text-white focus:e11-outline-none focus:e11-ring-2 focus:e11-ring-white"
            type="button"
          >
            <span class="e11-sr-only">Close panel</span>
            <svg aria-hidden="true" class="e11-h-6 e11-w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 18L18 6M6 6l12 12" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="e11-flex-1 e11-flex e11-flex-col e11-justify-between">
      <div class="e11-px-4 e11-divide-y e11-divide-skin-app-borders sm:e11-px-6">
        <div class="e11-space-y-6 e11-pt-6 pb-5">
          <video-upload-file-form
            #videoUploadFileForm
            (submitted)="uploadLoading = $event"
            (submittedSuccessfully)="submittedSuccessfully($event)"
            [filename]="filename"
            [videoLength]="videoRecordedTimeSet"
            [video]="videoFile"
            [makePublic]="makePublic"
          >
          </video-upload-file-form>
        </div>
      </div>
    </div>
  </div>
  <div class="e11-flex-shrink-0 e11-px-4 e11-py-4 e11-flex e11-justify-end">
    <e11-button (click)="close()" [buttonGroup]="true" [disabled]="uploadLoading" [ghost]="true" [value]="'Delete video' | translate" color="neutral">
    </e11-button>

    <e11-button
      (click)="videoUploadFileForm.onSubmitForm()"
      [color]="'primary-accent'"
      [disabled]="uploadLoading"
      [iconPosition]="'left'"
      [iconSize]="'md-18'"
      [icon]="'save'"
      value="{{ uploadLoading ? 'Saving...' : ('Save' | translate) }}"
    >
    </e11-button>
  </div>
</form>
