import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { isNotNil } from '@engineering11/utility'
import { ViewportService } from '@engineering11/web-utilities'
import { filter, map } from 'rxjs/operators'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class AppHeaderComponent implements OnInit {
  @Input() mobileDrawerState = false

  @Output() mobileDrawerStateChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  isMobile$ = this.viewportService.viewportSizeChanged$.pipe(
    map(data => data == 'sm' || data == 'xs'),
    filter(isNotNil)
  )

  constructor(private viewportService: ViewportService) {}

  ngOnInit(): void {}

  toggleMobileSideDrawer() {
    this.mobileDrawerStateChange.emit(!this.mobileDrawerState)
  }
}
