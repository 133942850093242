import { Injectable } from '@angular/core'
import {
  BaseCustomerConfigProvider,
  CustomerPartitionConfigProvider,
  ICustomerConfig,
  ICustomerPartitionConfig,
  ICustomerPartitionConfigProvider,
} from '@engineering11/customer-web'
import { Store } from '@ngrx/store'
import { getCustomerKey, getToken } from 'shared-lib'
import { environment } from '../../environments/environment'
import { map } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class AdminCustomerConfigProvider extends BaseCustomerConfigProvider {
  constructor(private store: Store) {
    super()
  }
  get config(): ICustomerConfig {
    return {
      baseUrl: environment.services.customer,
      token$: this.store.select(getToken),
      customerKey$: this.store.select(getCustomerKey).pipe(map(ck => (ck ? ck : undefined))),
    }
  }
}

@Injectable({ providedIn: 'root' })
export class AdminCustomerPartitionConfigProvider extends CustomerPartitionConfigProvider implements ICustomerPartitionConfigProvider {
  get config(): ICustomerPartitionConfig {
    return {
      listOfPartitionsUrl: '/company/partition-tabs',
      basePartitionPartial: '/apps',
    }
  }
}
