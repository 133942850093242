<!-- Request Information -->
<div class="e11-w-full e11-text-center e11-my-4" *ngIf="!education || education.length === 0">
  <e11-button
    *ngIf="!openRequest && userMode === userModeEnum.Employer"
    color="primary-accent"
    [ghost]="true"
    value="{{ 'Request Education' | translate }}"
    (click)="!requestsDisabled && requestModal()"
    [disabled]="requestsDisabled"
  ></e11-button>
</div>

<!-- Pending/open request -->
<request-open *ngIf="openRequest && userMode === userModeEnum.Employer" [request]="openRequest"></request-open>
