import { Component, OnInit } from '@angular/core'
import { INavigationItem } from '@engineering11/ui-lib/e11-nav-left'
import { Subject } from 'rxjs'

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
})
export class AppNavHeaderComponent implements OnInit {
  navStatus: boolean = true
  destroy$: Subject<boolean> = new Subject<boolean>()
  navigationItems: INavigationItem[] = []

  constructor(/*private navigationService: NavigationService*/) {
    //this.navigationItems = navigationService.navigationItems
  }

  ngOnInit(): void {
    // this.navigationService.navLeftStatusObservable$.pipe(takeUntil(this.destroy$)).subscribe(response => {
    //   this.navStatus = response
    // })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  toggleNav() {
    //this.navigationService.navLeftToggle(!this.navStatus)
  }
}
