// import { ITenantConfig } from '../model/environment.model'

import { toKebabCase } from '@engineering11/utility'
import { Env, ITenantConfig, PRODUCTION_ENV, ProjectId, ServiceNames, envToProjectId } from '../model/environment.model'

export interface ServicePathBuilder {
  build(serviceName: string): string
}

export class AppEnginePathBuilder implements ServicePathBuilder {
  constructor(private env: Env, private protocol = 'https') {}
  build(serviceName: ServiceNames) {
    const projectId = envToProjectId[this.env]
    const formattedName = toKebabCase(serviceName) // We rely on serviceName deterministically being the camelCase version of service-name
    return `${this.protocol}://${formattedName}-rest-dot-${projectId}.uc.r.appspot.com/${formattedName}` // 'https://jobs-rest-dot-cnect-dev.uc.r.appspot.com/jobs',
  }
}

export class CloudRunPathBuilder implements ServicePathBuilder {
  constructor(private projectHash: string, private protocol = 'https') {}
  build(serviceName: string, withFormattedName = true) {
    // https://access-rest-4j27drdyra-uc.a.run.app
    const formattedName = toKebabCase(serviceName) // We rely on serviceName deterministically being the camelCase version of service-name
    let url = `${this.protocol}://${formattedName}-rest-${this.projectHash}-uc.a.run.app`
    if (withFormattedName) {
      url += `/${formattedName}`
    }
    return url
  }
}

export class ApiGatewayPathBuilder implements ServicePathBuilder {
  constructor(private env: Env, private protocol = 'https') {}
  /** Will convert serviceName to service-name (camelCase to kebab-case) */
  build(serviceName: ServiceNames) {
    const root = getEnvRoot(this.env)
    const formattedName = toKebabCase(serviceName) // We rely on serviceName deterministically being the camelCase version of service-name
    return `${this.protocol}://${root}api.cnected.com/${formattedName}`
  }
}

export function buildTenantConfig(env: Env, app: 'employer' | 'network'): ITenantConfig {
  const root = getEnvRoot(env)
  return {
    appConfigBaseURL: `https://${root}content.cnected.com/assets/bootstrap`,
    defaultTenant: `${root}${app}.cnected.com`,
  }
}

/**
 * By standard, production env paths omit the env - this is the helper for implementing that
 */
function getEnvRoot(env: Env) {
  return env !== PRODUCTION_ENV ? `${env}-` : ''
}
