<form class="md:e11-mt-4 e11-text-center" [formGroup]="feedbackForm" (ngSubmit)="onSubmitForm()" novalidate>
  <h3 class="e11-text-skin-primary-accent e11-mb-4">{{ ratingText | translate }}</h3>
  <rating-slider (ratingSelected)="rating = $event"></rating-slider>
  <div class="e11-text-skin-error e11-mb-4" [ngClass]="{ 'e11-invisible': !submitted || rating }">
    {{ 'Please provide a rating' | translate }}
  </div>

  <h3 class="e11-text-skin-primary-accent e11-mb-4">{{ commentText | translate }}</h3>
  <div class="e11-flex e11-justify-center e11-items-center">
    <div class="e11-w-full md:e11-w-3/5">
      <e11-textarea name="comment" id="id_comment" [rows]="4" [parentForm]="feedbackForm" formControlName="comment">
        <e11-input-errors [parentForm]="feedbackForm" [formControl]="f.comment"></e11-input-errors>
      </e11-textarea>
    </div>
  </div>

  <div class="e11-my-4"></div>

  <e11-button [type]="'submit'" [color]="'primary-accent'" [value]="'Submit' | translate"></e11-button>
</form>
