import { Component, Input } from '@angular/core'
import { UserMode } from '../../../model/component-modes.enum'

@Component({
  selector: 'portfolio-document-empty-state',
  template: `
    <div class="e11-flex e11-justify-center e11-items-center">
      <div class="e11-flex e11-w-4/5 e11-p-4 e11-mb-4 e11-border e11-border-skin-app-borders e11-border-dashed e11-rounded-lg e11-bg-skin-app-bg">
        <div class="e11-flex e11-grow e11-items-center e11-justify-start">
          <span class="e11-pr-2 material-icons-outlined"> description </span>
          <p class="e11-m-0 e11-text-sm">
            {{
              userMode === UserMode.Candidate
                ? 'You currently have no documents uploaded! To add some documents, click the upload button!'
                : ('No documents provided with this portfolio' | translate)
            }}
          </p>
        </div>
      </div>
    </div>
  `,
})
export class PortfolioDocumentEmptyStateComponent {
  @Input() userMode = UserMode.Candidate

  UserMode = UserMode

  constructor() {}
}
