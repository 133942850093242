<e11-loader-dots
  *ngIf="this.processing"
  [fullScreen]="true"
  [color]="'e11-bg-skin-primary-accent'"
  label="{{ 'Saving video...' | translate }}"
></e11-loader-dots>

<form (ngSubmit)="onSubmitForm()" [formGroup]="formUploadVideo" class="" [ngClass]="{ 'e11-opacity-10 e11-pointer-events-none': this.processing }">
  <div class="">
    <e11-input
      [disabled]="formReadOnly || thumbnailsLoading || generateThumbLoading"
      [hasError]="f.name.invalid && formSubmitted"
      [parentForm]="formUploadVideo"
      [required]="true"
      formControlName="name"
      label="{{ 'Name your video' | translate }}"
      name="name"
    >
      <e11-input-errors [formControl]="f.name" [parentForm]="formUploadVideo" label="{{ 'Name' | translate }}"></e11-input-errors>
    </e11-input>
  </div>

  <div *ngIf="_videoURL" class="e11-mb-2">
    <video [controls]="true" [poster]="thumbnails.length > 0 ? thumbnails[selectedThumbnail] : null" [src]="_videoURL"></video>
  </div>
  <div class="e11-mb-2">
    <div *ngIf="thumbnails.length > 0" class="e11-flex e11-items-center e11-justify-between e11-mb-4">
      <h4 class="e11-font-bold">
        {{ 'Available thumbnail images' | translate }}
      </h4>
      <!-- @DO NOT REMOVE not ready yet -->
      <a (click)="generateThumbs(_video)" class="e11-cursor-pointer e11-text-skin-secondary e11-flex e11-items-center e11-gap-1">
        <span class="material-icons-outlined">replay</span> {{ 'Re-generate' | translate }}
      </a>
    </div>

    <ul class="e11-mt-2 e11-h-full e11-grid e11-grid-cols-2 e11-gap-x-4 e11-gap-y-8" role="list">
      <li *ngFor="let thumbnail of thumbnails; let i = index" class="e11-relative e11-cursor-pointer">
        <div
          (click)="file.click()"
          *ngIf="i === maxThumbs - 1"
          class="e11-absolute e11--right-2 e11--top-2 e11-z-10 e11-rounded-full e11-text-skin-base e11-bg-white e11-shadow"
        >
          <span class="material-icons-outlined e11-text-center e11-pt-1 e11-text-[16px] e11-h-6 e11-w-6">edit </span>
        </div>
        <div
          [ngClass]="{ 'e11-ring-2 e11-ring-offset-2 e11-ring-offset-gray-100 e11-ring-skin-primary': selectedThumbnail === i }"
          class="e11-group e11-block e11-w-full e11-aspect-w-4 e11-aspect-h-3 e11-rounded-lg e11-bg-gray-100 focus-within:e11-ring-2 focus-within:e11-ring-offset-2 focus-within:e11-ring-offset-gray-100 focus-within:e11-ring-skin-primary e11-overflow-hidden"
        >
          <img [src]="thumbnail" class="e11-object-cover e11-pointer-events-none group-hover:e11-opacity-75" />
          <button (click)="selectedThumbnail = i" class="e11-absolute e11-inset-0 focus:e11-outline-none" type="button">
            <span class="e11-sr-only">Select this thumbnails</span>
          </button>
        </div>
      </li>
      <li *ngIf="thumbnails.length < maxThumbs" class="e11-relative e11-cursor-pointer">
        <button
          (click)="file.click()"
          *ngIf="!generateThumbLoading"
          class="e11-h-full e11-relative e11-block e11-w-full e11-border-2 e11-border-skin-app-borders e11-border-dashed e11-rounded-lg e11-p-5 e11-text-center hover:e11-border-skin-app-borders focus:e11-outline-none focus:e11-ring-2 focus:e11-ring-offset-2 focus:e11-ring-secondary"
          type="button"
        >
          <svg
            class="e11-mx-auto e11-h-8 e11-w-8 e11-text-skin-light"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M12 4v16m8-8H4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          </svg>
          <p class="e11-mt-1 e11-text-sm e11-text-skin-base e11-mb-0">
            {{ 'You can add a custom thumbnail image here' | translate }}
          </p>
          <span class="e11-mt-2 e11-block e11-text-sm e11-font-medium e11-text-skin-dark"> {{ 'Upload image' | translate }} </span>
        </button>
      </li>
    </ul>
    <div *ngIf="generateThumbLoading">
      <h4 class="e11-text-skin-complimentary e11-text-center e11-font-bold">{{ 'Preparing thumbnails...' | translate }}</h4>
      <e11-loader-dots [color]="'e11-bg-skin-primary'" [size]="'sm'"></e11-loader-dots>
    </div>
  </div>
  <div>
    <input #file (change)="onFileChange($event)" [disabled]="formReadOnly" [accept]="this.thumbImageTypes" class="e11-hidden" id="file" type="file" />
  </div>
</form>
