<div class="skill-certifications">
  <!-- Tabs -->

  <div *ngIf="!pdfMode">
    <div *ngIf="matchedSkills.length !== 0; else emptyState">
      <e11-tabs
        tabTitleOverrides="e11-font-normal e11-text-base"
        [selectedTabId]="filterCurrent"
        (selectedTabChanged)="changedTab($event)"
        [tabsClassOverrides]="'e11-mb-2'"
      >
        <ng-container *ngFor="let filterType of filterTypes">
          <e11-tab
            *ngIf="!showOnlyMissing || (showOnlyMissing && filterType === FilterTypes.NoMatch)"
            [title]="translationFilterTypes[filterType] | translate"
            [tabId]="filterType"
          >
            <ng-container *ngIf="filterCurrent === filterType">
              <p *ngIf="userMode === UserMode.Employer">
                {{ employerFilterTypeCopy[filterType] | translate }}
              </p>
              <p *ngIf="userMode === UserMode.Candidate">
                {{ candidateFilterTypeCopy[filterType] | translate }}
              </p>
              <ng-container *ngIf="groupedSkills[filterCurrent] && groupedSkills[filterCurrent].length > 0; else noSkill">
                <manage-skills
                  [readOnly]="readOnly || !(userMode === UserMode.Candidate && filterCurrent === FilterTypes.NoMatch)"
                  [markAsMissingSkills]="readOnly || (userMode === UserMode.Candidate && filterCurrent === FilterTypes.NoMatch)"
                  [currentSkills]="groupedSkills[filterCurrent]"
                  [loading]="false"
                  (newSkills)="newSkills.emit($event)"
                ></manage-skills>
              </ng-container>
              <ng-template #noSkill>
                <h4 class="e11-font-bold e11-text-center e11-my-8">{{ 'No matching skills' | translate }}</h4>
              </ng-template>
            </ng-container>
          </e11-tab>
        </ng-container>
      </e11-tabs>
    </div>
  </div>

  <!-- EXPANDED FOR PDF -->
  <div *ngIf="pdfMode">
    <ng-container *ngFor="let filterType of filterTypes">
      <ng-container *ngIf="groupedSkills[filterType].length > 0">
        <section class="e11-mb-8" *ngIf="!showOnlyMissing || (showOnlyMissing && filterType === FilterTypes.NoMatch)">
          <p class="e11-mb-1 e11-text-md e11-text-skin-base e11-font-bold e11-linebreak ng-star-inserted">
            <span class="e11-border-b e11-border-skin-grey/50">{{ translationFilterTypes[filterType] | translate }}</span>
          </p>
          <p>
            {{ employerFilterTypeCopy[filterType] | translate }}
          </p>
          <ng-container *ngIf="groupedSkills[filterType] && groupedSkills[filterType].length > 0; else noSkill">
            <manage-skills
              [readOnly]="readOnly || !(userMode === UserMode.Candidate && filterType === FilterTypes.NoMatch)"
              [markAsMissingSkills]="readOnly || (userMode === UserMode.Candidate && filterType === FilterTypes.NoMatch)"
              [currentSkills]="groupedSkills[filterType]"
              [loading]="false"
              (newSkills)="newSkills.emit($event)"
            ></manage-skills>
          </ng-container>
          <ng-template #noSkill>
            <h4 class="e11-font-bold e11-text-center e11-my-8">{{ 'No matching skills' | translate }}</h4>
          </ng-template>
        </section>
      </ng-container>
    </ng-container>
  </div>
</div>

<!-- Pending/open request -->
<request-open *ngIf="openRequest" [request]="openRequest"></request-open>

<ng-template #emptyState>
  <!-- Empty State / Clickable -->
  <e11-empty-state
    [icon]="'construction'"
    [iconSize]="'md-36'"
    title="{{ 'No Skills' | translate }}"
    [clickable]="false"
    subtitle="{{ userMode === UserMode.Candidate ? 'You have not added any skills.' : ('The candidate did not add skills.' | translate) }}"
  >
  </e11-empty-state>
</ng-template>

<div *ngIf="showRequestButton" class="e11-w-full e11-pt-4 e11-text-center">
  <e11-button
    *ngIf="userMode === UserMode.Employer && !openRequest"
    [ghost]="true"
    color="primary-accent"
    value="{{ 'Request Skills' | translate }}"
    (click)="!requestsDisabled && requestModal()"
    [disabled]="requestsDisabled"
  >
  </e11-button>
</div>
