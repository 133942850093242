import { Component, EventEmitter, Output } from '@angular/core'

const SENTIMENTS = [
  'sentiment_very_dissatisfied', // Alternative: mood_bad
  'sentiment_dissatisfied',
  'sentiment_neutral',
  'sentiment_satisfied_alt', // alternative: sentiment_satisfied
  'sentiment_very_satisfied',
]

@Component({
  selector: 'rating-slider',
  template: `
    <div class="e11-flex e11-justify-center e11-mb-4">
      <div
        *ngFor="let r of ratingScale | range"
        (click)="selectRating(r)"
        class="e11-ml-2 e11-border e11-rounded-md e11-border-skin-grey e11-p-2 e11-w-12 e11-h-11 md:e11-w-16 md:e11-h-14 e11-flex e11-items-center e11-justify-center e11-cursor-pointer"
        [ngClass]="{ 'e11-bg-skin-primary-accent/20': rating === r }"
      >
        <span class="material-icons"> {{ SENTIMENTS[r] }} </span>
      </div>
    </div>
  `,
})
export class RatingSliderComponent {
  @Output() ratingSelected = new EventEmitter<number>()
  rating?: number
  ratingScale = 5

  SENTIMENTS = SENTIMENTS
  constructor() {}

  selectRating(rating: number) {
    this.rating = rating
    this.ratingSelected.emit(rating)
  }
}
