import { Injectable } from '@angular/core'
import { BaseUserConfigProvider, IUserConfig } from '@engineering11/user-web'
import { Store } from '@ngrx/store'
import { environment } from '../../environments/environment'
import { getCurrentToken } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class UserConfigProvider extends BaseUserConfigProvider {
  constructor(private store: Store) {
    super()
  }

  get config(): IUserConfig {
    return {
      userBaseURL: environment.services.user,
      token$: this.store.select(getCurrentToken),
    }
  }
}
