import { Injectable } from '@angular/core'
import { BaseFilesConfigProvider, IFilesConfig } from '@engineering11/files-web'
import { Store } from '@ngrx/store'
import { environment } from '../../environments/environment'
import { getCustomerKey, getToken } from 'shared-lib'
import { map } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class AdminFilesConfigProvider extends BaseFilesConfigProvider {
  constructor(private store: Store) {
    super()
  }
  get config(): IFilesConfig {
    return {
      filesBaseURL: environment.services.files,
      CNAME: environment.cdnCname,
      customerKey$: this.store.select(getCustomerKey).pipe(map(ck => (ck ? ck : undefined))),
      token$: this.store.select(getToken),
      validationConfig: {},
    }
  }
}
