import { Component, EventEmitter, Input, Output } from '@angular/core'
import { openInNewTab } from '@engineering11/web-utilities'
import { IPortfolioUrl, PortfolioUiType } from '../../../model/portfolio.model'
import { UserMode } from '../../../model/component-modes.enum'

@Component({
  selector: 'portfolio-links-line-item',
  template: `
    <div *ngIf="userMode === UserMode.Employer || userMode === UserMode.Public">
      <div
        class="e11-flex e11-w-full e11-grow e11-p-4 e11-mb-4 e11-border e11-border-skin-app-borders e11-rounded-lg e11-cursor-pointer hover:e11-bg-skin-primary-accent/10 hover:e11-border-skin-primary-accent"
      >
        <a
          *ngIf="portfolioItem?.url"
          e11ExternalLink
          [href]="portfolioItem?.url"
          class="e11-flex hover:e11-text-skin-primary e11-items-center e11-justify-start"
        >
          <span class="material-icons-outlined e11-mr-2 md-18"> launch </span>
          <span class="e11-line-clamp-1 e11-word-break e11-skeleton-empty e11-mr-2">{{ portfolioItem?.url }}</span>
        </a>
      </div>
    </div>

    <div *ngIf="userMode === UserMode.Candidate">
      <div class="e11-flex e11-mb-4 e11-items-center e11-justify-between e11-text-skin-primary-accent e11-cursor-pointer">
        <a e11ExternalLink class="e11-flex hover:e11-text-skin-primary" *ngIf="portfolioItem?.url" [href]="portfolioItem?.url">
          <span class="material-icons-outlined e11-mr-2 md-18"> launch </span>
          <span class="e11-line-clamp-1 e11-word-break e11-skeleton-empty e11-mr-2">{{ portfolioItem?.url }}</span>
        </a>
        <div class="e11-flex">
          <span class="material-icons-outlined e11-mr-2 md-18 hover:e11-text-skin-primary" (click)="this.confirmation.open()"> close</span>
          <span class="material-icons-outlined e11-mr-2 md-18 hover:e11-text-skin-primary" (click)="editLink.emit()"> edit </span>
        </div>
      </div>
    </div>

    <e11-confirmation
      #confirmation
      confirmTitle="{{ 'Delete this link' | translate }}"
      confirmText="{{ 'confirm' | translate }}"
      cancelText="{{ 'cancel' | translate }}"
      [backdropStyle]="'dark'"
      (answer)="confirmationAnswer($event)"
    >
      {{ 'Are you sure you want to remove this link? You can always add it back if you need to.' | translate }}
    </e11-confirmation>
  `,
})
export class PortfolioLinksLineItemComponent {
  @Input() portfolioItem?: IPortfolioUrl
  @Input() userMode = UserMode.Candidate
  @Output() editLink = new EventEmitter<undefined>()
  @Output() deleteLink = new EventEmitter<undefined>()

  PortfolioUiType = PortfolioUiType
  UserMode = UserMode

  constructor() {}

  openWebsite(url: string) {
    openInNewTab(url)
  }

  confirmationAnswer(res: boolean) {
    if (res) this.deleteLink.emit()
  }
}
