import { Component, EventEmitter, Input, Output } from '@angular/core'
import { E11SideOverService } from '@engineering11/ui-lib/e11-side-over'
import { IUploadedVideoResult } from '@engineering11/multimedia-web'
import { uuid } from '@engineering11/utility'

@Component({
  selector: 'video-upload-file',
  templateUrl: './video-upload-file.component.html',
  styleUrls: ['./video-upload-file.component.scss'],
})
export class VideoUploadFileComponent {
  @Input() title: string = 'Upload Video'
  @Input() filename: string = uuid()
  @Input() videoFile: File | undefined
  @Input() videoRecordedTimeSet: string = '00:00'
  @Input() makePublic: boolean = false

  @Output() videoUploaded = new EventEmitter<IUploadedVideoResult>()

  uploadLoading: boolean = false

  constructor(private sideOverService: E11SideOverService) {}

  close() {
    this.sideOverService.overlayToggle(false)
    this.videoFile = undefined
    this.videoRecordedTimeSet = '00:00'
  }

  submittedSuccessfully(uploadedVideoResult: IUploadedVideoResult) {
    this.uploadLoading = false
    this.videoUploaded.emit(uploadedVideoResult)
    this.close()
  }
}
