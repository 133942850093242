import { Component, EventEmitter, Input, Output } from '@angular/core'
import { groupByKey } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { RequestedUpdateFields } from '../../../model/candidate-application.model'
import { UserMode } from '../../../model/component-modes.enum'
import { MatchType } from '../../../model/enum'
import { ICertification, IMatchedCertification } from '../../../model/interfaces'

const FilterTypes = Object.freeze({ ...MatchType, NoMatch: 'NoMatch' as const })
type FilterKeys = keyof typeof FilterTypes
const employerFilterTypeCopy: Record<FilterKeys, string> = {
  [FilterTypes.Exact]: 'Exact matches to the certifications listed in the job post',
  [FilterTypes.NoMatch]: 'Certifications listed in the job post that the candidate does not have ',
  [FilterTypes.NotListed]: 'Certifications the candidate has that were not listed in the job post',
  [FilterTypes.Partial]: 'Partial matches to the certifications listed in the job post',
}

const candidateFilterTypeCopy: Record<FilterKeys, string> = {
  [FilterTypes.Exact]: employerFilterTypeCopy.Exact,
  [FilterTypes.Partial]: employerFilterTypeCopy.Partial,
  [FilterTypes.NoMatch]: 'Certifications you have that were not listed in the job post',
  [FilterTypes.NotListed]: 'Certifications listed in the job post that you do not have',
}

@Component({
  selector: 'certifications-result',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss'],
})
export class CandidateCertificationsResultComponent {
  FilterTypes = FilterTypes
  employerFilterTypeCopy = employerFilterTypeCopy
  candidateFilterTypeCopy = candidateFilterTypeCopy
  UserMode = UserMode
  filterTypes = Object.values(FilterTypes)
  filterCurrent: FilterKeys = MatchType.Exact

  translationFilterTypes = {
    Exact: 'Exact',
    Partial: 'Partial',
    NotListed: 'Other',
    NoMatch: 'Missing',
  }

  groupedCertifications: Record<string, ICertification[]> = Object.values(FilterTypes).reduce(
    (prv, nxt) => ({
      ...prv,
      [nxt]: [],
    }),
    {}
  )

  // Needed for empty state
  matchedCerts!: IMatchedCertification[]

  constructor(private logger: E11Logger) {}

  @Output() requestFromComponent = new EventEmitter()
  @Output() newCertifications = new EventEmitter<ICertification[]>()

  @Input() openRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false
  @Input() showRequestButton = true
  @Input() pdfMode: boolean = false
  @Input() userMode: UserMode = UserMode.Candidate

  @Input() set certifications(matchedCertifications: IMatchedCertification[]) {
    this.matchedCerts = matchedCertifications
    this.groupedCertifications = { ...this.groupedCertifications, ...groupByKey(matchedCertifications, 'matchType') }
  }

  @Input() set missingCertifications(missingCertifications: ICertification[]) {
    this.groupedCertifications = { ...this.groupedCertifications, NoMatch: missingCertifications }
  }

  @Input() readOnly = false

  _showOnlyMissingCertifications = false

  @Input() set showOnlyMissingCertifications(showOnlyMissing: boolean) {
    this._showOnlyMissingCertifications = showOnlyMissing

    if (this._showOnlyMissingCertifications) this.filterCurrent = FilterTypes.NoMatch
  }

  requestModal() {
    this.requestFromComponent.emit('Certifications')
  }

  changedTab(event: any) {
    this.filterCurrent = event.tabId as FilterKeys
  }
}
