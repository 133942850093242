<div
  class="e11-relative e11-flex e11-justify-center e11-items-center e11-w-full e11-rounded e11-bg-skin-black focus-within:e11-ring-2 focus-within:e11-ring-offset-2 focus-within:e11-ring-offset-gray-100 focus-within:e11-ring-skin-primary e11-overflow-hidden e11-mb-2"
  [ngClass]="{ 'e11-ring-2 e11-ring-offset-2 e11-ring-offset-gray-100 e11-ring-skin-primary': isActive }"
  (click)="onClick.emit(video)"
>
  <div class="e11-absolute e11-flex flex-row e11-items-center e11-justify-center e11-w-full e11-z-10 e11-cursor-pointer">
    <span class="material-icons-outlined e11-text-7xl e11-text-white/70"> play_circle </span>
  </div>
  <img
    [src]="video.thumb.signedURL"
    [alt]="video.name"
    class="e11-object-cover e11-pointer-events-none group-hover:e11-opacity-75 e11-cursor-pointer"
    [ngClass]="{
      'e11-max-h-[180px]': videoSize === videoSizeEnum.ExtraSmall,
      'e11-max-h-[240px]': videoSize === videoSizeEnum.Small,
      'e11-max-h-[400px]': videoSize === videoSizeEnum.Medium,
      'e11-max-h-[640px]': videoSize === videoSizeEnum.Large
    }"
  />
  <button type="button" class="e11-absolute e11-inset-0 focus:e11-outline-none e11-cursor-pointer">
    <span class="e11-sr-only">{{ video.name }}</span>
  </button>
</div>
<div class="e11-flex e11-w-full">
  <div class="e11-grow e11-flex e11-items-center">
    <span *ngIf="showName" class="e11-text-sm">{{ 'Name:' | translate }} {{ video.name }}</span>
    <span *ngIf="showTime">
      <span class="e11-flex e11-items-center e11-bg-skin-app-bg-dark e11-text-skin-white e11-text-sm e11-px-2 e11-py-1 e11-rounded e11-ml-4">
        {{ video.videoLength || '--:--' }}
      </span>
    </span>
  </div>
  <div *ngIf="showRemove">
    <e11-tooltip
      [color]="'dark'"
      [title]="'First Impression Video'"
      tooltipText="{{ 'Remove this video from here. This does not delete it from your video library.' | translate }}"
    >
      <span
        class="e11-cursor-pointer e11-flex e11-items-center e11-justify-center e11-p-1 hover:e11-text-skin-dark"
        title="{{ 'Remove this video from here. This does not delete it from your video library.' | translate }}"
      >
        <div
          class="e11-flex e11-items-start e11-justify-end e11-text-skin-secondary e11-cursor-pointer hover:e11-brightness-70 e11-px-2 e11-py-1 hover:e11-bg-skin-grey/10 e11-text-sm"
          (click)="removeFIV.emit(video)"
        >
          {{ 'Remove video' | translate }}
        </div>
      </span>
    </e11-tooltip>
  </div>
</div>
