import { Injectable } from '@angular/core'
import { BaseAuthConfigProvider, IAuthConfig } from '@engineering11/auth-web'
import { environment } from '../../environments/environment'

// TODO: Fix with appConfigService

@Injectable({ providedIn: 'root' })
export class AuthConfigProvider extends BaseAuthConfigProvider {
  constructor() {
    super()
  }
  get config(): IAuthConfig {
    return {
      authBaseURL: environment.services.auth,
      tenantId: environment.tenantId,
      bootstrapId: '',
    }
  }
}
