import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import { VideoUtilService } from '@engineering11/multimedia-web'
import { Size } from '@engineering11/ui-lib/e11-button'
import { formatSecondsMMSS } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { Subject } from 'rxjs'
import { MAX_DURATION_VIDEO, MIN_DURATION_VIDEO } from '../../constants'
import { VideoFileUploadData } from '../../model/candidate-video.model'
import { NotificationService } from '@engineering11/web-ui-helpers'
import { INotificationMessage } from '@engineering11/ui-lib/e11-notifications'

@Component({
  selector: 'app-video-file-upload',
  templateUrl: './video-file-upload.component.html',
  styleUrls: ['./video-file-upload.component.scss'],
})
export class VideoFileUploadComponent implements OnDestroy {
  @Input() maxDuration = MAX_DURATION_VIDEO
  @Input() minDuration = MIN_DURATION_VIDEO
  @Input() buttonOnly: boolean = false
  @Input() showButtonIcon: boolean = false
  @Input() buttonSize: Size = 'md'
  @Input() buttonCopy: string = 'Browse'

  @Input() disabled: boolean = false
  @Input() disabledText: string | undefined = undefined

  @Output() newVideoData = new EventEmitter<VideoFileUploadData>()

  loading = false

  destroy$: Subject<boolean> = new Subject<boolean>()

  constructor(private logger: E11Logger, private videoUtilService: VideoUtilService, private notificationsService: NotificationService) {}

  ngOnDestroy(): void {
    this.destroy$.next(true)
    this.destroy$.complete()
  }

  async fileInputChange(event: any) {
    this.logger.log({ event })
    const videoFile: File = event.target.files[0]
    this.logger.log({ videoFile })
    if (!videoFile) {
      return
    }
    this.loading = true

    const duration = await this.videoUtilService.getVideoDuration(videoFile)
    this.loading = false
    const isValidDuration = this.validateDuration(duration)
    if (isValidDuration) {
      const durationStr = formatSecondsMMSS(duration)
      this.newVideoData.emit({ videoFile, thumbs: [], durationStr })
    }
  }

  private validateDuration(duration: number): boolean {
    this.logger.log('validating duration of: ', duration)
    if (duration > this.maxDuration) {
      const notification: INotificationMessage = {
        title: 'Video Too Long',
        message: `The video must be less than ${this.maxDuration} seconds. Please upload another video.`,
        type: 'danger',
        autoClose: false,
        dismissOnRouteChange: true,
      }
      this.notificationsService.popNotification(notification)
      return false
    }
    if (duration < this.minDuration) {
      const notification: INotificationMessage = {
        title: 'Video Too Short',
        message: `The video must be more than ${this.minDuration} seconds. Please upload another video.`,
        type: 'danger',
        autoClose: false,
      }
      this.notificationsService.popNotification(notification)
      return false
    }
    return true
  }
}
