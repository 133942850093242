import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'
import { DegreeLevel } from '../../model/enum'
import { IEducation } from '../../model/interfaces'

@Component({
  selector: 'ui-education-single-card-horizontal',
  template: `
    <div class="item-card" (click)="viewDetail.emit(education)">
      <div class="header">
        {{ education.schoolName }}
      </div>
      <div class="body">
        <div class="body-title">
          <div class="education-title">
            <div class="title text-accent-blue bold">{{ education.degreeTitle }}</div>
            <div class="title text-grey">{{ stateToStatus.get(education.degreeLevel) || '' | translate }}</div>
            <div *ngIf="education.degreeType" class="text-grey">{{ education.degreeType }}</div>
          </div>
          <div *ngIf="education.notCompleted" class="education-status">
            <div class="text-grey">{{ 'Not yet Completed' | translate }}</div>
          </div>
        </div>
        <div class="spacer-2rem"></div>
        <date-display-start-end class="dates text-grey" [item]="education"> </date-display-start-end>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
})
export class UiEducationSingleCardHorizontalComponent {
  @Input()
  education!: IEducation

  @Output()
  viewDetail = new EventEmitter()

  @Output()
  editView = new EventEmitter()

  degreeLevel = DegreeLevel

  stateToStatus = new Map<DegreeLevel, string>()
    .set(this.degreeLevel.NotSpecified, myDegreeLevel('Not Specified'))
    .set(this.degreeLevel.HighSchool, myDegreeLevel('High School'))
    .set(this.degreeLevel.VocationalHsDiploma, myDegreeLevel('Vocational High School Diploma'))
    .set(this.degreeLevel.VocationalDegree, myDegreeLevel('Vocational Degree'))
    .set(this.degreeLevel.Associates, myDegreeLevel("Associate's Degree"))
    .set(this.degreeLevel.Bachelors, myDegreeLevel("Bachelor's Degree"))
    .set(this.degreeLevel.Masters, myDegreeLevel("Master's Degree"))
    .set(this.degreeLevel.PhD, myDegreeLevel('Doctorate/PhD'))
}

const myDegreeLevel = (str: string) => `${str}`
