import { createAction, createReducer, on, props, Store } from '@ngrx/store'
import { isEqual } from 'lodash'
import { Observable } from 'rxjs'
import { distinctUntilChanged } from 'rxjs/operators'

export const updateComponentState = createAction('[NGRX Component Store] Update Action', props<{ componentName: string; componentState: any }>())

export const initialState: any = {}

export const componentStateReducer = createReducer(
  initialState,
  on(updateComponentState, (state, { componentName, componentState }) => ({ ...state, [componentName]: { ...componentState } }))
)

export const hookToGlobalState = (componentState$: Observable<any>, componentName: string, globalStore: Store) => {
  componentState$.pipe(distinctUntilChanged((prev, next) => isEqual(prev, next))).subscribe(componentState => {
    globalStore.dispatch(updateComponentState({ componentName, componentState }))
  })
}
