import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { IPortfolio, IPortfolioDocument } from '../../../model/portfolio.model'
import { UserMode } from '../../../model/component-modes.enum'

@Component({
  selector: 'portfolio-documents-preview',
  template: `
    <e11-file-viewer
      *ngIf="viewDocument"
      [name]="viewDocument.filename"
      [url]="viewDocument.url"
      (close)="viewDocument = undefined"
    ></e11-file-viewer>
    <ng-container *ngIf="signingFiles">
      <div *ngFor="let i of 3 | range" class="e11-flex e11-gap-4 e11-items-center e11-mb-2 e11-skeleton-empty"></div>
    </ng-container>

    <div *ngIf="!signingFiles" class="e11-mb-4">
      <div
        *ngFor="let item of portfolioDocuments | slice : 0 : limit"
        class="e11-cursor-pointer e11-flex e11-text-skin-primary-accent e11-text-sm e11-gap-4 e11-items-center"
      >
        <div class="e11-px-2 e11-mr-2">
          <span class="material-icons-outlined md-18 e11-ml-8"> description </span>
        </div>
        <p class="e11-m-0 e11-flex-1 e11-overflow-hidden e11-text-ellipsis e11-break-words" (click)="openDocument(item)">{{ item.file?.fileName }}</p>
      </div>
    </div>

    <div class="e11-flex e11-justify-end">
      <p
        (click)="viewMoreClicked.emit()"
        *ngIf="portfolioDocuments.length > limit"
        class="e11-mb-2 e11-text-xs e11-text-skin-primary-accent e11-text-end e11-cursor-pointer e11-w-fit"
      >
        {{ portfolioDocuments.length - limit }} {{ 'more document(s)...' | translate }}
      </p>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioDocumentsPreviewComponent implements OnInit {
  @Input() portfolioDocuments: (IPortfolioDocument | IPortfolio)[] = []
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() signingFiles: boolean = true

  @Output() viewMoreClicked = new EventEmitter()

  limit: number = 3
  viewDocument: { filename: string; url: string } | undefined = undefined

  ngOnInit() {
    if (this.userMode === UserMode.Public) {
      this.limit = this.portfolioDocuments.length
    }
  }

  openDocument(item: IPortfolio) {
    this.viewDocument = {
      filename: item.file?.fileName!,
      url: item.file?.signedURL!,
    }
  }
}
