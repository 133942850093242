import { Component } from '@angular/core'

// TODO: Remove hard coded dimensions

@Component({
  selector: 'ui-skeleton-card',
  template: `
    <div
      class="e11-w-56 e11-group e11-block e11-aspect-h-7 e11-rounded-lg e11-bg-gray-100 focus-within:e11-ring-2 focus-within:e11-ring-offset-2 focus-within:e11-ring-offset-gray-100 focus-within:e11-ring-skin-primary e11-overflow-hidden e11-animate-pulse"
    >
      <div class="e11-rounded-lg e11-bg-skin-grey/20 e11-h-36"></div>
    </div>
  `,
})
export class UISkeletonCardComponent {}
