import { Component, EventEmitter, Input, Output } from '@angular/core'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { IPortfolio } from '../../model/portfolio.model'

@Component({
  selector: 'candidate-portfolio-request',
  template: `
    <!-- Request Information -->
    <div class="e11-w-full e11-text-center e11-my-4" *ngIf="!portfolio || portfolio.length === 0">
      <e11-button
        *ngIf="!openRequest && userMode === userModeEnum.Employer"
        color="primary-accent"
        [ghost]="true"
        value="{{ 'Request Portfolio' | translate }}"
        (click)="!requestsDisabled && requestModal()"
        [disabled]="requestsDisabled"
      ></e11-button>
    </div>

    <!-- Pending/open request -->
    <request-open *ngIf="openRequest && userMode === userModeEnum.Employer" [request]="openRequest"></request-open>
  `,
})
export class CandidatePortfolioComponent {
  @Input() portfolio: IPortfolio[] | undefined
  @Input() openRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false
  @Input() userMode = UserMode.Candidate
  @Output() requestFromComponent = new EventEmitter()

  userModeEnum = UserMode

  constructor() {}

  requestModal() {
    this.requestFromComponent.emit('Portfolio')
  }
}
