import { AuthConfigProvider } from '@admin/app/config/auth.config'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AuthModule } from '@engineering11/auth-web'
import { IUserRestService, PlatformAdminUserRestService, UserModule } from '@engineering11/user-web'
import { TranslateModule } from '@ngx-translate/core'
import { RecaptchaModule } from 'ng-recaptcha'
import { SharedModule } from '../_shared/shared.module'
import { AuthenticationRoutingModule } from './authentication.routing'
import { AuthenticationLoginComponent } from './views/login/login.component'
import { UserConfigProvider } from '@admin/app/config/user.config'

@NgModule({
  declarations: [AuthenticationLoginComponent],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    TranslateModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    AuthModule.forRoot({ configProvider: AuthConfigProvider }),
    UserModule.forRoot({ configProvider: UserConfigProvider }),
  ],
  exports: [],
  providers: [{ provide: IUserRestService, useClass: PlatformAdminUserRestService }],
})
export class AuthenticationModule {}
