import { UserMode } from '../../model/component-modes.enum'
import { PortfolioUiType } from '../../model/portfolio.model'

export const MAX_PORTFOLIO_FILE_SIZE = 300 * 1000 * 1000 // 300MB

export const portfolioTypeToIcon: Record<PortfolioUiType, string> = {
  Document: 'description',
  Photo: 'image',
  Link: 'link',
  Video: 'smart_display',
  NotSupported: '',
}
export const portfolioTypeToTitle: Record<PortfolioUiType, string> = {
  Document: 'Documents',
  Photo: 'Photos',
  Link: 'Links',
  Video: 'Videos',
  NotSupported: '',
}

export const portfolioTypeToBgColor: Record<PortfolioUiType, string> = {
  Document: 'e11-bg-neutral-700',
  Photo: 'e11-bg-skin-success',
  Link: 'e11-bg-skin-complimentary',
  Video: 'e11-bg-skin-primary-accent',
  NotSupported: '',
}

export const portfolioTypeToBorderColor: Record<PortfolioUiType, string> = {
  Document: 'e11-border-skin-grey/70',
  Photo: 'e11-border-skin-success',
  Link: 'e11-border-skin-complimentary',
  Video: 'e11-border-skin-primary-accent',
  NotSupported: '',
}

export const portfolioTypeToTextColor: Record<PortfolioUiType, string> = {
  Document: 'e11-text-skin-neutral-700',
  Photo: 'e11-text-skin-success',
  Link: 'e11-text-skin-complimentary',
  Video: 'e11-text-skin-primary-accent',
  NotSupported: '',
}

export const portfolioBodyTextByUserMode: Record<UserMode, string> = {
  [UserMode.Employer]: 'Candidate',
  [UserMode.Candidate]: 'Portfolio',
  [UserMode.Public]: 'Portfolio',
}

export const portfolioDescriptionByType: Record<PortfolioUiType, string> = {
  Document: 'Here are some documents I would like to share showing work I have done, or things that represent my career or goals.',
  Photo: 'Here are some images I would like to share showing work I have done, or things that represent my career or goals.',
  Link: 'Here are some links I would like to share showing work I have done, or things that represent my career or goals.',
  Video: 'Here are some videos I would like to share showing work I have done, or things that represent my career or goals.',
  NotSupported: '',
}
