import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { UserMode } from '../../../model/component-modes.enum'
import { IPortfolioUrl, PortfolioUiType } from '../../../model/portfolio.model'
import { PortfolioStore } from '../portfolio.store.component'

@Component({
  selector: 'portfolio-links',
  template: `
    <div
      [ngClass]="
        userMode === UserMode.Candidate ? 'e11-grid e11-grid-cols-1 md:e11-grid-cols-2 e11-gap-8' : 'e11-flex e11-justify-center e11-items-center'
      "
    >
      <div *ngIf="userMode === UserMode.Candidate" class="e11-flex-col e11-items-center e11-justify-center">
        <div class="e11-flex e11-gap-2 e11-items-center e11-mb-4">
          <span class="material-icons-outlined md-36">link</span>
          <p class="e11-font-primary-demibold e11-mb-0">
            {{ 'Add a website link to your portfolio' | translate }}
          </p>
        </div>
        <portfolio-links-form (closeModal)="closeModal.emit()" [userMode]="userMode" [editLink]="currentLink"></portfolio-links-form>
      </div>

      <div
        [ngClass]="
          userMode === UserMode.Candidate
            ? 'e11-flex-col e11-justify-center e11-items-center'
            : 'e11-flex e11-justify-center e11-items-center e11-w-4/5'
        "
      >
        <div *ngIf="userMode === UserMode.Candidate" class="e11-flex e11-justify-between">
          <p class="e11-font-primary-demibold e11-mb-6">{{ _portfolioLinks.length }} {{ 'link(s)' | translate }}</p>
          <portfolio-clear [userMode]="userMode" [itemType]="PortfolioUiType.Link" [portfolioItems]="_portfolioLinks"></portfolio-clear>
        </div>

        <div *ngIf="_portfolioLinks.length > 0; else Empty" class="e11-overflow-y-scroll e11-overflow-hidden e11-w-full">
          <div *ngFor="let portfolioItem of _portfolioLinks">
            <portfolio-links-line-item
              [userMode]="userMode"
              [portfolioItem]="portfolioItem"
              (editLink)="editPortfolioLink(portfolioItem)"
              (deleteLink)="deletePortfolioLink(portfolioItem.id)"
            ></portfolio-links-line-item>
          </div>
        </div>
      </div>
    </div>

    <ng-template #Empty>
      <e11-empty-state [border]="'dashed'" [icon]="'link'" [clickable]="false" title="{{ 'No Links Added' | translate }}"></e11-empty-state>
    </ng-template>
  `,
})
export class PortfolioLinksComponent {
  _portfolioLinks: IPortfolioUrl[] = []
  currentLink?: IPortfolioUrl

  @ViewChild('formDirective') formDirective?: NgForm

  @Input() set portfolioLinks(portfolio: IPortfolioUrl[]) {
    this._portfolioLinks = portfolio
  }

  @Input() userMode = UserMode.Candidate

  @Output() closeModal = new EventEmitter()

  PortfolioUiType = PortfolioUiType
  UserMode = UserMode

  constructor(private portfolioStore: PortfolioStore) {}

  editPortfolioLink(linkToEdit: IPortfolioUrl) {
    this.currentLink = linkToEdit
  }

  deletePortfolioLink(linkId: string) {
    this.portfolioStore.onPortfolioItemDeleted(linkId)
  }
}
