import { Component, Input, Output, EventEmitter } from '@angular/core'
import { ICandidateVideo } from '../../../model/candidate-video.model'
import { Size } from '@engineering11/ui-lib/e11-ui-config'
import { Sizes } from '../../../constants'

@Component({
  selector: 'ui-video-card',
  templateUrl: './ui-video-card.component.html',
})
export class UiVideoCardComponent {
  @Input() video!: ICandidateVideo
  @Input() isActive: boolean = false
  @Input() showName: boolean = true
  @Input() showTime: boolean = true
  @Input() showRemove: boolean = true
  @Input() videoSize: Size = 'md'

  videoSizeEnum = Sizes

  @Output() onClick: EventEmitter<ICandidateVideo> = new EventEmitter()
  @Output() removeFIV: EventEmitter<ICandidateVideo> = new EventEmitter()
}
