import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { FilesService } from '@engineering11/files-web'
import { Size } from '@engineering11/ui-lib/e11-ui-config'
import { deepCopy } from '@engineering11/utility'
import { ICandidateVideo } from '../../../model/candidate-video.model'
import { IFirstImpression } from '../../../model/interfaces'
import { AnimationFading } from '../../../e11-animations'
import { E11ModalComponent } from '@engineering11/ui-lib/e11-modal'
import { PlayerSize } from '@engineering11/multimedia-web'
import { UserMode } from '../../../model/component-modes.enum'

@Component({
  selector: 'ui-video-stand-alone',
  templateUrl: './ui-video-stand-alone.component.html',
  animations: [AnimationFading.fadeInSlow, AnimationFading.fadeOut, AnimationFading.fadeOutSlow],
})
export class UiVideoStandAloneComponent {
  // @Input() video!: ICandidateVideo
  @ViewChild('modalItem') modal!: E11ModalComponent

  @Input() isActive: boolean = false

  // @Input() isList: boolean = false
  @Input() size: Size = 'md'
  @Input() showName: boolean = true
  @Input() showTime: boolean = true
  @Input() showRemove: boolean = true
  @Input() showFooter: boolean = true
  @Input() userMode: UserMode = UserMode.Candidate

  @Output() onClick: EventEmitter<ICandidateVideo> = new EventEmitter()
  @Output() removeFIV: EventEmitter<ICandidateVideo | null> = new EventEmitter()
  @Output() loadingVideo: EventEmitter<boolean> = new EventEmitter()

  userModeEnum = UserMode
  loadingSingleVideo: boolean = true
  videoPlayerResource!: { thumb?: string; video?: string }
  playerSizeEnum = PlayerSize
  showPlayer = false

  enumUserMode = UserMode

  _candidateFirstImpression?: ICandidateVideo
  @Input() set candidateFirstImpression(value: IFirstImpression | undefined | null) {
    if (value) {
      this.signVideo(value)
    }
    this._candidateFirstImpression = undefined
  }

  constructor(private filesService: FilesService) {}

  async signVideo(firstImpression: IFirstImpression) {
    this.loadingSingleVideo = true
    let firstImpressionResult = deepCopy(firstImpression)
    if (firstImpressionResult && firstImpressionResult.video && firstImpressionResult.thumb) {
      const fileReceipts = await this.filesService.signedURLs([firstImpressionResult.video, firstImpressionResult.thumb])
      let thumb = fileReceipts.find(r => r.id === firstImpressionResult.thumb.id)
      let video = fileReceipts.find(r => r.id === firstImpressionResult.video.id)
      firstImpressionResult.video.signedURL = video?.signedURL
      firstImpressionResult.thumb.signedURL = thumb?.signedURL
      this._candidateFirstImpression = firstImpressionResult as ICandidateVideo
      this.loadingSingleVideo = false
      this.loadingVideo.emit(false)
    }
  }

  openVideoPlayer(firstImpression: IFirstImpression) {
    if (firstImpression.thumb?.signedURL) {
      this.videoPlayerResource = {
        thumb: firstImpression.thumb.signedURL,
      }

      if (firstImpression.video) {
        this.filesService.signedURLs([firstImpression.video]).then(val => {
          if (val.length > 0 && val[0].signedURL) {
            this.videoPlayerResource.video = val[0].signedURL
            this.videoPlayerResource = Object.assign({}, this.videoPlayerResource)
          }
        })
      }

      if (this.userMode !== this.enumUserMode.Public) {
        this.modal.open()
      }
      this.showPlayer = true
    }
  }

  removeVideo() {
    this.removeFIV.emit(null)
  }
}
