import { Component, Input } from '@angular/core'
import { ICandidateResponses, IRequestedUpdate } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'

@Component({
  selector: 'job-application-info-icons',
  template: `
    <!-- CONFIRMATIONS -->
    <div class="e11-inline-flex e11-items-center e11-text-sm">
      <!-- Experience -->
      <span class="e11-flex e11-items-center e11-mr-1 e11-text-skin-light">
        <e11-tooltip
          [color]="'dark'"
          tooltipText="{{
            'Job related experience: ' + responses?.yearsRelevantExperience + ' years, ' + responses?.monthsRelevantExperience + ' months.'
              | translate
          }}"
        >
          <span
            class="material-icons-outlined md-18"
            [ngClass]="{ 'e11-text-skin-primary-accent': responses?.yearsRelevantExperience !== 0 || responses?.monthsRelevantExperience !== 0 }"
            >calendar_month</span
          >
        </e11-tooltip>
      </span>
      <span class="e11-text-skin-light e11-flex e11-items-center">
        <!-- Work hours -->
        <span *ngIf="responses?.hasOwnProperty('hoursConfirmed') && responses?.hoursConfirmed !== null">
          <e11-tooltip
            [color]="'dark'"
            tooltipText="{{
              responses?.hoursConfirmed ? 'This candidate can work the listed hours.' : ('The applicant cannot work the listed hours.' | translate)
            }}"
          >
            <span class="material-icons-outlined md-18 e11-mx-1" [ngClass]="{ 'e11-text-skin-primary-accent': responses?.hoursConfirmed }"
              >schedule</span
            >
          </e11-tooltip>
        </span>
        <!-- Travel -->
        <span *ngIf="responses?.hasOwnProperty('travelConfirmed') && responses?.travelConfirmed !== null">
          <e11-tooltip
            [color]="'dark'"
            tooltipText="{{
              responses?.travelConfirmed
                ? 'This candidate can meet the travel requirements.'
                : ('This candidate can not meet the travel requirements.' | translate)
            }}"
          >
            <span
              class="material-icons-outlined md-18 e11-mx-1 e11-rotate-45"
              [ngClass]="{ 'e11-text-skin-primary-accent': responses?.travelConfirmed }"
              >flight</span
            >
          </e11-tooltip>
        </span>
        <!-- Work in US -->
        <e11-tooltip
          [color]="'dark'"
          [tooltipText]="
            responses?.legalWorkAuthorization
              ? 'This candidate can legally work in: ' +
                (responses?.legalWorkAuthorizationCountryCode ?? jobPostCountryCode ?? 'US' | country) +
                '' +
                (responses?.tempWorkAuthorizationExpiration ? ' until ' + (getTempExpireDate() | date : 'MMM. Y') : '.')
              : ('This candidate can not legally work in: ' + (responses?.legalWorkAuthorizationCountryCode ?? jobPostCountryCode ?? 'US' | country)
                | translate)
          "
        >
          <span class="material-icons-outlined md-18 e11-mx-1" [ngClass]="{ 'e11-text-skin-primary-accent': responses?.legalWorkAuthorization }"
            >public</span
          >
        </e11-tooltip>
        <!-- Visa / Sponsorship -->
        <e11-tooltip
          [color]="'dark'"
          tooltipText="{{
            responses?.visaSponsorshipRequired ? 'This candidate requires sponsorship.' : ('This candidate does not require sponsorship.' | translate)
          }}"
        >
          <span class="material-icons-outlined md-18 e11-mx-1" [ngClass]="{ 'e11-text-skin-primary-accent': responses?.visaSponsorshipRequired }"
            >badge</span
          >
        </e11-tooltip>
        <!-- pending request -->
        <e11-tooltip [color]="'dark'" [tooltipText]="tooltipText | translate">
          <span *ngIf="pendingRequestedUpdate || pendingVirtualDialogue" class="material-icons-outlined md-18 e11-mx-1 e11-text-skin-secondary"
            >pending</span
          >
        </e11-tooltip>
      </span>
    </div>
  `,
})
export class JobsApplicationInfoIconsComponent {
  @Input() responses?: ICandidateResponses
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() jobPostCountryCode: string | undefined
  @Input() pendingVirtualDialogue!: boolean
  @Input() set requestedUpdate(update: IRequestedUpdate[] | undefined) {
    this.pendingRequestedUpdate = update?.some(request => !request.fulfilled) ?? false
  }
  pendingRequestedUpdate: boolean = false

  constructor() {}

  getTempExpireDate() {
    if (!this.responses || !this.responses.legalWorkAuthorizationType || !this.responses.tempWorkAuthorizationExpiration) {
      return ''
    }
    return this.responses.tempWorkAuthorizationExpiration.year + '-' + this.responses.tempWorkAuthorizationExpiration.month
  }
  get tooltipText(): string {
    if (this.pendingRequestedUpdate && this.pendingVirtualDialogue) {
      return 'There are multiple outstanding requests for this candidate.'
    } else if (this.pendingVirtualDialogue) {
      return 'There are outstanding questions awaiting responses for this candidate.'
    } else return 'There are requested updates pending on this application.'
  }
}
