import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { VideoRecordingService } from '@engineering11/multimedia-web'
import { VIDEO_PLAYER_ACTIONS, VIDEO_RECORD_STATUS } from '../../video-recording.component'

export interface ITeleprompterSettings {
  script: string
  visible: boolean
  speed: number
  size: number
  width: number
  testSetting: boolean
}

export interface IVideoRecordingSettings {
  teleprompter: ITeleprompterSettings
  tipsEnabled: boolean
  enableTeleprompterSettings: boolean
  action?: VIDEO_PLAYER_ACTIONS
  tutorialsEnabled: boolean
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'video-recording-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class VideoRecordingSettingsComponent implements OnInit, OnChanges {
  @ViewChild('modalScript') modalScript: any
  @Input() action!: VIDEO_RECORD_STATUS
  @Input() teleprompter: boolean = true

  @Input()
  videoRecordingSettings: IVideoRecordingSettings = {
    teleprompter: {
      script: '',
      speed: 0,
      size: 24,
      width: 100,
      visible: true,
      testSetting: false,
    },
    tipsEnabled: true,
    enableTeleprompterSettings: true,
    tutorialsEnabled: false,
  }

  @Output() videoRecordingSettingsChange = new EventEmitter<IVideoRecordingSettings>()

  @Output() closeSettings = new EventEmitter()
  @Output() teleprompterStatus = new EventEmitter()
  @Output() teleprompterTest = new EventEmitter()

  // Forms
  formScript = new UntypedFormGroup({})
  script: string = ''

  teleprompterTesting: boolean = false

  audioDevices: MediaDeviceInfo[] = []
  videoDevices: MediaDeviceInfo[] = []

  selectedAudioDevice!: string
  selectedVideoDevice!: string

  // @Output() showTips = new EventEmitter()
  // @Output() scriptUpdate = new EventEmitter()
  // @Output() teleprompterSpeedOutput = new EventEmitter()
  // @Output() teleprompterSizeOutput = new EventEmitter()
  // @Output() teleprompterWidthOutput = new EventEmitter()

  constructor(private formBuilder: UntypedFormBuilder, private videoRecordingService: VideoRecordingService) {}

  @Input()
  set teleprompterScript(value: string) {
    this.script = value
    this.formScript.get('script')?.setValue(this.script)
  }

  ngOnInit(): void {
    this.videoRecordingService.watchInputDevices().subscribe(() => {
      this.audioDevices = this.videoRecordingService.getAvailableActiveAudioDevices()
      this.videoDevices = this.videoRecordingService.getAvailableActiveVideoDevices()
      this.selectedAudioDevice = this.videoRecordingService.getActiveAudioDevice()?.deviceId || ''
      this.selectedVideoDevice = this.videoRecordingService.getActiveVideoDevice()?.deviceId || ''
    })

    this.createFormScript()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.processChange(changes.action.currentValue)
  }

  processChange(value: VIDEO_PLAYER_ACTIONS) {
    switch (value) {
      case VIDEO_PLAYER_ACTIONS.RECORD:
      case VIDEO_PLAYER_ACTIONS.PLAY:
        return this.teleprompterPlay()
      case VIDEO_PLAYER_ACTIONS.STOP_RECORDING:
      case VIDEO_PLAYER_ACTIONS.STOP:
      case VIDEO_PLAYER_ACTIONS.SAVE:
      case VIDEO_PLAYER_ACTIONS.CLEAR:
      case VIDEO_PLAYER_ACTIONS.ABORT:
        return this.teleprompterStop()
    }
  }

  updateDefaultAudioDevice(audioDevice: MediaDeviceInfo) {
    const device = this.audioDevices.find(v => v.deviceId === this.selectedAudioDevice)
    if (device) {
      this.videoRecordingService.setActiveAudioDevice(device)
    }
  }
  updateDefaultVideoDevice(videoDevice: MediaDeviceInfo) {
    const device = this.videoDevices.find(v => v.deviceId === this.selectedVideoDevice)
    if (device) {
      this.videoRecordingService.setActiveVideoDevice(device)
    }
  }

  close() {
    this.closeSettings.emit(true)
  }

  showModalTips(event: any) {
    this.videoRecordingSettings.tipsEnabled = true
    this.videoRecordingSettingsChange.emit(this.videoRecordingSettings)
    // this.showTips.emit(true)
  }

  toggleTeleprompter(value: boolean) {
    this.teleprompterStatus.emit(!value) // Emit current state, toggle it in parent
    this.teleprompterTesting ? (this.teleprompterTesting = false) : null
  }

  createFormScript() {
    this.formScript = this.formBuilder.group({
      script: new UntypedFormControl(this.script),
    })
  }

  onSubmitFormScript() {
    // Lets just refresh the script right here...
    // The subscription in the 'home' component isn't picking up script changes
    // this.script = this.formScript.get('script')?.value
    // this.scriptUpdate.emit(this.script)
    this.videoRecordingSettings.teleprompter.script = this.formScript.get('script')?.value
    this.videoRecordingSettingsChange.emit(this.videoRecordingSettings)
    this.modalScript.close()
  }

  setTeleprompterSpeed(event: any) {
    this.videoRecordingSettings.teleprompter.speed = 100 - event.value
    this.videoRecordingSettingsChange.emit(this.videoRecordingSettings)
    // this.teleprompterSpeed = 100 - event.target.value
    //  this.teleprompterSpeedOutput.emit(this.teleprompterSpeed)
  }

  setTeleprompterSize(event: any) {
    this.videoRecordingSettings.teleprompter.size = event.target.value
    this.videoRecordingSettingsChange.emit(this.videoRecordingSettings)
    // this.teleprompterSize = event.target.value
    // this.teleprompterSizeOutput.emit(this.teleprompterSize)
  }

  setTeleprompterWidth(event: any) {
    this.videoRecordingSettings.teleprompter.width = event.target.value
    this.videoRecordingSettingsChange.emit(this.videoRecordingSettings)
    // this.teleprompterSize = event.target.value
    // this.teleprompterWidth = event.target.value
    // this.teleprompterWidthOutput.emit(this.teleprompterWidth)
  }

  teleprompterPlay() {
    if (this.teleprompter) {
      this.teleprompterTesting = true
      this.teleprompterTest.emit(true)
    }
  }

  teleprompterStop() {
    this.teleprompterTesting = false
    this.teleprompterTest.emit(false)
  }
}
