import { Component, EventEmitter, Input, Output } from '@angular/core'
import { E11Logger } from '@engineering11/web-api-error'
import { IPortfolioDocument, PortfolioUiType } from '../../../model/portfolio.model'
import { UserMode } from '../../../model/component-modes.enum'
import { PortfolioStore } from '../portfolio.store.component'
import { mimeTypeGroup } from '../../../util/portfolio-items-by-type'

@Component({
  selector: 'portfolio-documents',
  template: `
    <div class="e11-flex e11-gap-4 e11-flex-wrap md:e11-flex-nowrap">
      <div class="e11-w-full md:e11-w-1/2" *ngIf="userMode === UserMode.Candidate">
        <div class="e11-flex e11-gap-2 e11-items-center e11-mb-4">
          <span class="material-icons-outlined md-36">description</span>
          <p class="e11-font-primary-demibold e11-mb-0">
            {{ 'Add a document to your portfolio' | translate }}
          </p>
        </div>

        <sdk-file-drop
          (newFile)="uploadPortfolioDocument([$event])"
          [allowedFileTypes]="documentFileTypes"
          [dropInstruction]="'Drag and drop your document here' | translate"
          [loading]="!(itemLoaded$ | async)"
        ></sdk-file-drop>
      </div>

      <div class="e11-w-full md:e11-w-1/2 e11-pt-1">
        <div class="e11-flex e11-justify-between">
          <p class="e11-font-primary-demibold e11-mb-6">{{ _portfolioDocuments?.length }} {{ 'document(s)' | translate }}</p>
          <portfolio-clear [userMode]="userMode" [itemType]="PortfolioUiType.Document" [portfolioItems]="_portfolioDocuments"></portfolio-clear>
        </div>
        <div class="e11-mb-8">
          <ng-container *ngFor="let portfolioItem of _portfolioDocuments">
            <portfolio-document-line-item
              title="Click to view document"
              [portfolioDocument]="portfolioItem"
              [userMode]="userMode"
              (deleteDocument)="deletePortfolioDocument(portfolioItem)"
            ></portfolio-document-line-item>
          </ng-container>

          <portfolio-document-empty-state
            *ngIf="_portfolioDocuments && !_portfolioDocuments.length"
            [userMode]="userMode"
          ></portfolio-document-empty-state>

          <!--Loading-->
          <ng-container *ngIf="!_portfolioDocuments">
            <portfolio-document-line-item
              *ngFor="let i of 3 | range"
              [userMode]="userMode"
              [portfolioDocument]="undefined"
            ></portfolio-document-line-item>
          </ng-container>
        </div>
      </div>
    </div>
  `,
})
export class PortfolioDocumentsComponent {
  PortfolioUiType = PortfolioUiType
  currentDocument?: IPortfolioDocument

  UserMode = UserMode

  _portfolioDocuments?: IPortfolioDocument[]
  documentFileTypes = mimeTypeGroup.Document
  @Input() set portfolioDocuments(portfolioDocuments: IPortfolioDocument[] | undefined) {
    this._portfolioDocuments = portfolioDocuments
  }
  @Input() userMode = UserMode.Candidate

  @Output() closeModal = new EventEmitter()
  itemLoaded$ = this.portfolioStore.itemLoaded$

  constructor(private portfolioStore: PortfolioStore, private logger: E11Logger) {}

  async ngOnChanges() {
    if (this._portfolioDocuments) this.portfolioStore.signFiles(this._portfolioDocuments)
  }

  async deletePortfolioDocument(item: IPortfolioDocument) {
    this.portfolioStore.deletePortfolioFile(item)
  }

  async uploadPortfolioDocument(files: File[]) {
    this.portfolioStore.uploadPortfolioFiles(files)
  }
}
