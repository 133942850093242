import { Component, EventEmitter, Input, Output } from '@angular/core'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { IReference } from '../../model/interfaces'

@Component({
  selector: 'candidate-references',
  templateUrl: './candidate-references.component.html',
  styleUrls: ['./candidate-references.component.scss'],
})
export class CandidateReferencesComponent {
  reference?: IReference
  @Input() references: IReference[] = []
  @Input() openRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false
  @Input() userMode = UserMode.Candidate
  @Output() requestFromComponent = new EventEmitter()

  userModeEnum = UserMode

  constructor() {}
  requestModal() {
    this.requestFromComponent.emit('References')
  }

  displayReference(reference: IReference) {
    this.reference = reference
  }

  close() {
    this.reference = undefined
  }
}
