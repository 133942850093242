<div class="row">
  <div class="col-xs-12 text-center">
    <div class="avatar-upload">
      <div class="avatar-preview" [ngClass]="{ loading: loading }">
        <div class="e11-absolute e11-top-[33px]">
          <e11-loader-line *ngIf="loading" [width]="'70px'"></e11-loader-line>
        </div>
        <span class="material-icons e11-text-6xl" *ngIf="!currentPhotoUrl">person</span>
        <div *ngIf="currentPhotoUrl" id="imagePreview" [style.backgroundImage]="'url(' + currentPhotoUrl + ')'"></div>
      </div>
    </div>
  </div>
</div>

<div class="e11-w-full">
  <div class="e11-flex e11-items-center e11-justify-center e11-mb-4 e11-px-6 lg:e11-px-16 e11-flex-wrap e11-gap-4">
    <e11-button
      id="button_submit"
      value="{{ 'Remove photo' | translate }}"
      color="secondary"
      [type]="'button'"
      [ghost]="true"
      [buttonGroup]="true"
      (click)="removeCurrentPhoto()"
      [disabled]="!currentPhotoUrl || loading"
    >
    </e11-button>
    <input class="input-file-uploader" type="file" id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput (change)="addNewPhoto($event)" />
    <e11-button
      id="button_upload"
      value="{{ loading ? 'Loading...' : ('Upload from computer' | translate) }}"
      color="primary-accent"
      [type]="'button'"
      [icon]="'file_upload'"
      [iconPosition]="'left'"
      (click)="fileInput.click()"
      [disabled]="loading"
    >
    </e11-button>
  </div>
</div>
