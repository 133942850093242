import { Injectable } from '@angular/core'

export interface ICookie {
  name: string
  value: string
  expireDays: number
  session?: boolean
  path?: string
  protocol?: string
  secure?: boolean
}

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  public getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';')
    let caLen: number = ca.length
    let cookieName = `${name}=`
    let c: string

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '')
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length)
      }
    }
    return ''
  }

  public deleteCookie(cookieName: string) {
    this.setCookie({ name: cookieName, value: '', expireDays: -1 })
  }

  /**
   * Expires default 1 day
   * If params.session is set and true expires is not added
   * If params.path is not set or value is not greater than 0 its default value will be root "/"
   * Secure flag can be activated only with https implemented
   * Examples of usage:
   * {service instance}.setCookie({name:'token',value:'abcd12345', session:true, expireDays:10  }); <- This cookie will not expire
   */
  public setCookie(params: ICookie) {
    let d: Date = new Date()
    d.setTime(d.getTime() + (params.expireDays ? params.expireDays : 1) * 24 * 60 * 60 * 1000)
    document.cookie =
      (params.name ? params.name : '') +
      '=' +
      (params.value ? params.value : '') +
      ';' +
      (params.session && params.session == true ? '' : 'expires=' + d.toUTCString() + ';') +
      `domain=.${location.hostname};` +
      'path=' +
      (params.path && params.path.length > 0 ? params.path : `/`) +
      ';' +
      (location.protocol === 'https:' && params.secure && params.secure == true ? 'secure' : '')
  }
}
