import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
  selector: 'mobile-drawer-icon',
  templateUrl: 'mobile-drawer-icon.component.html',
})
export class MobileDrawerIconComponent implements OnInit {
  @Input() mobileDrawerState = false

  @Output() mobileDrawerStateChange: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor() {}

  ngOnInit() {}

  toggleMobileSideDrawer() {
    this.mobileDrawerStateChange.emit(!this.mobileDrawerState)
  }
}
