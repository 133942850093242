import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ListMode, UserMode } from '../../model/component-modes.enum'

import { IReference, ISelectableReference } from '../../model/interfaces'

@Component({
  selector: 'ui-reference',
  templateUrl: './ui-reference.component.html',
  styleUrls: ['./ui-reference.component.scss'],
})
export class UiReferencesComponent {
  listModeEnum = ListMode
  userModeEnum = UserMode

  @Input() listMode: ListMode = ListMode.Horizontal
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() references: ISelectableReference[] = []
  @Input() loading = false
  @Input() selectable = false

  @Output() viewDetail = new EventEmitter()
  @Output() editView = new EventEmitter()
  @Output() addItem = new EventEmitter()
  @Output() deleteItem = new EventEmitter<ISelectableReference>()
  @Output() selectionChange: EventEmitter<ISelectableReference[]> = new EventEmitter()

  reference!: IReference

  select(reference: ISelectableReference) {
    this.selectionChange.emit(this.references)
  }

  selectAll() {
    this.references.forEach(reference => (reference.selected = true))
    this.selectionChange.emit(this.references)
  }

  deselectAll() {
    this.references.forEach(reference => (reference.selected = false))
    this.selectionChange.emit(this.references)
  }
}
