import { IVideoRecordingSettings } from './../settings/settings.component'
import { Component, Input } from '@angular/core'
import { VIDEO_PLAYER_ACTIONS } from '../../video-recording.component'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'video-recording-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss'],
})
export class VideoRecordingControlsComponent {
  displayCount = 0
  videoPlayerActionsEnum = VIDEO_PLAYER_ACTIONS

  videoControlButtons = {
    trash: true,
    record: true,
    stopRecord: false,
    play: false,
    stopPlayback: false,
    save: false,
    setting: true,
    helpIcon: true,
  }

  currentPlayerStatus: any

  @Input() showControls = false

  // videoRecordingSettings: IVideoRecordingSettings = {

  // }

  constructor() {}

  setAction(action: VIDEO_PLAYER_ACTIONS) {}
  toggleMenus(event: any) {}
  videoRecordingSettingsChange(event: any) {}
  showModalTips() {}
  toggleTeleprompter(event: any) {}
  closeMenus() {}
}
