import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class UiScrollLockingService {
  scrollStatusSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  scrollStatusObservable$

  backdropStyleSource: BehaviorSubject<string> = new BehaviorSubject<string>('')
  backdropStyleObservable$

  constructor() {
    this.scrollStatusObservable$ = this.scrollStatusSource.asObservable()
    this.backdropStyleObservable$ = this.backdropStyleSource.asObservable()
  }

  scrollLock(status: boolean) {
    this.scrollStatusSource.next(status)
    const body = document.getElementsByTagName('body')[0]
    if (status) {
      body.classList.add('scroll-lock')
    } else {
      body.classList.remove('scroll-lock')
    }
  }
}
