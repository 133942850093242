import { Component, Input } from '@angular/core'
import { UserMode } from '../../model/component-modes.enum'
import { PortfolioUiType } from '../../model/portfolio.model'
import { portfolioTypeToIcon, portfolioTypeToTitle } from './portfolio-constants'

@Component({
  selector: 'portfolio-upload-empty',
  template: `
    <div class="e11-gap-4">
      <div class="e11-relative e11-flex e11-p-0 e11-h-48 e11-bg-skin-grey/20 e11-items-center e11-justify-center e11-mb-4">
        <div *ngIf="userMode === UserMode.Candidate" class="e11-text-center e11-text-skin-grey">
          <span class="material-icons-outlined e11-text-6xl"> {{ typeToIcon[itemType] }} </span>
          <p>{{ "You don't have any " + portfolioTypeToTitle[itemType] + ' on your portfolio' | translate }}</p>
        </div>
        <div *ngIf="userMode === UserMode.Employer" class="e11-text-center">
          <span class="material-icons-outlined e11-text-6xl"> cancel </span>
          <p class="e11-w-40">{{ 'No ' + portfolioTypeToTitle[itemType] + ' provided with portfolio' | translate }}</p>
        </div>
      </div>
    </div>
  `,
})
export class PortfolioUploadEmptyComponent {
  portfolioTypeToTitle = portfolioTypeToTitle
  typeToIcon = portfolioTypeToIcon
  UserMode = UserMode

  @Input() userMode = UserMode.Candidate
  @Input() itemType!: PortfolioUiType

  constructor() {}
}
