import { Injectable } from '@angular/core'
import { isNotNil } from '@engineering11/utility'
import { Store } from '@ngrx/store'
import { filter, firstValueFrom, switchMap, take } from 'rxjs'
import { getCurrentUserId } from '../../store/user/user.selectors'
import { FeedbackRequest } from './feedback.model'
import { FeedbackRepository } from './feedback.repository'

@Injectable({ providedIn: 'root' })
export class FeedbackService {
  private userId$ = this.store.pipe(getCurrentUserId).pipe(filter(isNotNil))
  constructor(private feedbackRepository: FeedbackRepository, private store: Store) {}

  async add(feedbackRequest: FeedbackRequest) {
    const userId = await firstValueFrom(this.userId$)
    return this.feedbackRepository.add({ ...feedbackRequest, userId })
  }

  feedbackExists(feature: string) {
    return this.userId$.pipe(
      take(1),
      switchMap(userId => this.feedbackRepository.feedbackExists({ feature, userId }))
    )
  }

  getUserFeedbackForFeature(feature: string) {
    return this.userId$.pipe(
      take(1),
      switchMap(userId => this.feedbackRepository.getUserFeedbackForFeature({ feature, userId }))
    )
  }
}
