import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { VideoRecordingService } from '@engineering11/multimedia-web'
import { randomIntBetween } from '@engineering11/utility'
import { Subject, Subscription, timer } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { SoundMeter } from '../../util/soundmeter'

declare global {
  interface Window {
    AudioContext: typeof AudioContext
    webkitAudioContext: typeof AudioContext
  }
}
// declare module 'DecibelMeter'

export enum VIDEO_RECORD_STATUS {
  IDLE = 'IDLE',
  RECORDING = 'RECORDING',
  STOP_RECORDING = 'STOP_RECORDING',
  PLAYBACK = 'PLAYBACK',
  STOP_PLAYBACK = 'STOP_PLAYBACK',
}

export enum VIDEO_PLAYER_ACTIONS {
  IDLE = 'IDLE',
  RECORD = 'RECORD',
  STOP_RECORDING = 'STOP_RECORDING',
  PLAY = 'PLAY',
  STOP = 'STOP',
  SAVE = 'SAVE',
  ABORT = 'ABORT',
  CLEAR = 'CLEAR',
  FULLSCREEN = 'FULLSCREEN',
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'video-recording',
  templateUrl: './video-recording.component.html',
  styleUrls: ['./video-recording.component.scss'],
})
export class VideoRecordingComponent implements OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('videoElement') videoElement: any
  video: any = {}

  @ViewChild('recordingElement') recordingElement: any
  recordingVideo: any = {}

  showRecordingVideoPlayer = true

  isPlaying = false
  displayControls = true
  isAudioRecording = false
  isVideoRecording = false
  videoRecordedTime!: string
  videoPlayActions = VIDEO_PLAYER_ACTIONS
  destroy$: Subject<boolean> = new Subject<boolean>()
  timerSubscription: Subscription = new Subscription()

  videoBlobUrl!: SafeUrl | null

  videoBlob!: Blob

  videoName!: string

  videoStream!: MediaStream

  videoConf: MediaStreamConstraints = {
    video: { deviceId: undefined, facingMode: 'user', width: { exact: 1280 }, height: { exact: 720 } },
    audio: {
      deviceId: '',
      channelCount: 1,
      echoCancellation: false,
      sampleRate: 48000,
      sampleSize: 16,
    },
  }
  streams: MediaStream[] = []

  @Input()
  timeCounter: number = 60

  @Input()
  maximumRecordSec = 60

  @Output()
  timerStatus = new EventEmitter()

  @Input() action: VIDEO_PLAYER_ACTIONS = VIDEO_PLAYER_ACTIONS.IDLE

  @Output()
  uploadFile = new EventEmitter()

  @Output()
  recorderStatus: EventEmitter<VIDEO_RECORD_STATUS> = new EventEmitter<VIDEO_RECORD_STATUS>()

  @Output()
  thumbnailStatus: EventEmitter<boolean> = new EventEmitter<boolean>()

  @Output()
  thumbnails = new EventEmitter()

  audioContext!: AudioContext

  soundMeter!: SoundMeter

  @Output()
  soundMeterInstant: EventEmitter<number> = new EventEmitter<number>()
  soundMeterInterval!: NodeJS.Timeout

  constructor(private ref: ChangeDetectorRef, private videoRecordingService: VideoRecordingService, private sanitizer: DomSanitizer) {
    this.videoRecordingService
      .recordingFailed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.isVideoRecording = false
        this.ref.detectChanges()
      })

    this.videoRecordingService
      .getRecordedTime()
      .pipe(takeUntil(this.destroy$))
      .subscribe(time => {
        this.videoRecordedTime = time
        this.ref.detectChanges()
      })

    this.videoRecordingService
      .getStream()
      .pipe(takeUntil(this.destroy$))
      .subscribe(stream => {
        this.videoStream = stream
        this.ref.detectChanges()
      })

    this.videoRecordingService
      .getRecordedBlob()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.videoBlob = data.blob
        this.videoName = data.title
        if (data.url) {
          this.videoBlobUrl = this.sanitizer.bypassSecurityTrustUrl(data.url)
        }

        this.ref.detectChanges()
      })
  }
  ngOnDestroy(): void {
    this.streams.forEach(stream => {
      this.videoRecordingService.stopStreamTrack(stream)
    })

    this.destroy$.next(true)
    this.destroy$.unsubscribe()

    this.timerSubscription.unsubscribe()
    this.soundMeter?.stop()
    clearInterval(this.soundMeterInterval)
  }

  ngAfterViewInit(): void {
    this.setupAudioContext()
    this.setStreamMode()
  }

  setStreamMode() {
    this.video = this.videoElement.nativeElement
    this.recordingVideo = this.recordingElement.nativeElement
    this.video.controls = this.recordingVideo.controls = false

    this.videoRecordingService
      .watchInputDevices()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        ;(this.videoConf.video as MediaTrackConstraintSet).deviceId = this.videoRecordingService.getActiveVideoDevice()?.deviceId
        ;(this.videoConf.audio as MediaTrackConstraintSet).deviceId = this.videoRecordingService.getActiveAudioDevice()?.deviceId

        this.videoRecordingService.getAvailableDevice(this.videoConf).then(stream => {
          this.streams.push(stream)
          this.showRecordingVideoPlayer = true
          this.recordingVideo.srcObject = stream
          this.recordingVideo.muted = 'muted'
          setTimeout(() => {
            this.audioDecibelMeter(stream)
          }, 100)
        })
      })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.action.currentValue) {
      setTimeout(() => {
        this.processChange(changes.action.currentValue)
      }, 50)
    }
  }
  setupAudioContext() {
    try {
      window.AudioContext = window.AudioContext || window.webkitAudioContext
      this.audioContext = new AudioContext()
    } catch (e) {
      alert('Web Audio API not supported.')
    }
  }

  audioDecibelMeter(stream: MediaStream) {
    this.soundMeter = new (SoundMeter as any)(this.audioContext)
    this.soundMeter.connectToSource(stream, (e: any) => {
      this.soundMeterInterval = setInterval(() => {
        this.soundMeterInstant.emit(+this.soundMeter.instant.toFixed(2) * 2)
      }, 200)
    })
  }

  processChange(value: VIDEO_PLAYER_ACTIONS) {
    switch (value) {
      case VIDEO_PLAYER_ACTIONS.RECORD:
        this.recorderStatus.emit(VIDEO_RECORD_STATUS.RECORDING)
        return this.startVideoRecording()
      case VIDEO_PLAYER_ACTIONS.STOP_RECORDING:
        this.timerSubscription.unsubscribe()
        this.recorderStatus.emit(VIDEO_RECORD_STATUS.STOP_RECORDING)
        return this.stopVideoRecording()
      case VIDEO_PLAYER_ACTIONS.PLAY:
        this.recorderStatus.emit(VIDEO_RECORD_STATUS.PLAYBACK)
        return this.playVideoRecording()
      case VIDEO_PLAYER_ACTIONS.STOP:
        this.recorderStatus.emit(VIDEO_RECORD_STATUS.STOP_PLAYBACK)
        return this.stopVideoPlayback()
      case VIDEO_PLAYER_ACTIONS.SAVE:
        return this.upload()
      case VIDEO_PLAYER_ACTIONS.CLEAR:
        return this.clearVideoRecordedData()
      case VIDEO_PLAYER_ACTIONS.ABORT:
        return this.abortVideoRecording()
    }
  }

  startVideoRecording() {
    if (!this.isVideoRecording) {
      this.isVideoRecording = true
      this.startTimer()
      this.showRecordingVideoPlayer = true
      this.videoRecordingService.startRecording(this.videoConf).then(stream => {
        this.streams.push(stream)
        this.video.srcObject = null

        this.recordingVideo.srcObject = stream
        this.recordingVideo.muted = 'muted'
      })
    }
  }

  playVideoRecording() {
    if (!this.isVideoRecording) {
      this.video = this.videoElement.nativeElement
      this.isVideoRecording = false
      this.video.controls = false
      this.video.muted = null
      this.video.volume = 1
      this.video.play()
    }
  }

  stopVideoPlayback() {
    this.video = this.videoElement.nativeElement
    this.video.pause()
  }

  abortVideoRecording() {
    this.clearTimer()
    if (this.isVideoRecording) {
      this.isVideoRecording = false
      this.videoRecordingService.abortRecording()
      this.video.controls = false
    }
  }

  stopVideoRecording() {
    if (this.isVideoRecording) {
      this.videoRecordingService.stopRecording()
      this.timerSubscription.unsubscribe()
      this.isVideoRecording = false
      this.showRecordingVideoPlayer = false
      this.video.controls = false
      this.video.volume = 0.5
      this.video.pause()
      this.streams.forEach(stream => {
        this.videoRecordingService.stopStreamTrack(stream)
      })
      this.ref.detectChanges()
      setTimeout(async () => {
        await this.emitThumbImages(this.getActiveFile())
      }, 500)
    }
  }

  clearVideoRecordedData() {
    this.clearTimer()
    this.videoBlobUrl = null
    this.video.srcObject = null
    this.video.controls = false
    this.showRecordingVideoPlayer = true
    this.ref.detectChanges()
    this.setStreamMode()
  }
  getActiveFile() {
    return new File([this.videoBlob], this.videoName, {
      type: 'video/mp4',
    })
  }

  async upload() {
    const file = this.getActiveFile()
    this.uploadFile.emit(file)
    await this.emitThumbImages(file)
  }

  async emitThumbImages(file: File) {
    const files = await this.generateThumbImages(file)
    this.thumbnails.emit(files)
  }

  async generateThumbImages(videoFile: File) {
    this.thumbnailStatus.emit(true)
    const thumbs = []

    for (let i in new Array(3).fill(null)) {
      thumbs.push(await this.generateVideoThumbnail(videoFile, randomIntBetween(1, this.maximumRecordSec - this.timeCounter - 1)))
    }

    this.thumbnailStatus.emit(false)
    return thumbs
  }

  async generateVideoThumbnail(file: File, delay = 0) {
    return new Promise(resolve => {
      const canvas = document.createElement('canvas')
      const video = document.createElement('video')

      // this is important
      video.autoplay = true
      video.muted = true
      video.src = URL.createObjectURL(file)

      //!important for safari
      video.onloadedmetadata = e => {
        video.currentTime = delay
      }
      video.onloadeddata = () => {
        video.currentTime = delay
        let ctx = canvas.getContext('2d')
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight
        video.currentTime = delay
        // video.load()
        ctx!.drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

        video.pause()
        const img = canvas.toDataURL('image/png')
        canvas.remove()
        video.remove()
        return resolve(img)
      }
    })
  }

  startTimer() {
    this.clearTimer()
    this.timerSubscription = timer(1000, 1000).subscribe(() => {
      // if(this.isVideoRecording) {
      this.timeCounter -= 1
      this.timerStatus.emit({ currentTime: this.timeCounter })
      if (this.timeCounter === 0) {
        this.processChange(VIDEO_PLAYER_ACTIONS.STOP_RECORDING)
      }
      // }
    })
  }

  clearTimer() {
    this.timerSubscription.unsubscribe()
    this.timeCounter = this.maximumRecordSec
    this.timerStatus.emit({ currentTime: this.timeCounter })
  }
}
