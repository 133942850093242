<div class="skill-certifications" *ngIf="matchedSkills.length > 0 || matchedCerts.length > 0; else emptyState">
  <!-- Tabs -->
  <!--<div class="row skill-filters">
    <div class="col-xs-12 nopadding">
      <ul class="flex">
        <li
          *ngFor="let filterType of filterTypes"
          class="filter e11-flex-1 e11-p-0"
          (click)="filterCurrent = filterType"
          [ngClass]="{ active: filterCurrent == filterType }"
        >
          {{ translationFilterTypes[filterType] | translate }}
        </li>
      </ul>
    </div>
  </div>-->

  <div class="row">
    <div class="col-xs-12">
      <p *ngIf="filterCurrent == filterTypeEnum.Exact">
        {{ 'Exact matches to the skills and certifications listed in the job post' | translate }}
      </p>
      <p *ngIf="filterCurrent == filterTypeEnum.Partial">
        {{ 'Partial matches to the skills and certifications listed in the job post' | translate }}
      </p>

      <div *ngIf="filterCurrent == filterTypeEnum.NotListed">
        <p *ngIf="userMode === userModeEnum.Employer">
          {{ 'Skills and certifications the candidate has that were not listed in the job post' | translate }}
        </p>
        <p *ngIf="userMode === userModeEnum.Candidate">
          {{ 'Skills and certifications you have that were not listed in the job post' | translate }}
        </p>
      </div>

      <div *ngIf="filterCurrent == filterTypeEnum.NoMatch">
        <p *ngIf="userMode === userModeEnum.Employer">
          {{ 'Skills and certifications listed in the job post that the candidate does not have' | translate }}
        </p>
        <p *ngIf="userMode === userModeEnum.Candidate">
          {{ 'Skills and certifications listed in the job post that you do not have' | translate }}
        </p>
      </div>

      <div class="spacer-2rem"></div>

      <h3 class="text-black">{{ 'Skills' | translate }}</h3>
      <ng-container *ngIf="groupedSkills[filterCurrent] && groupedSkills[filterCurrent].length > 0; else noSkill">
        <div class="candidate-attribute-wrapper">
          <div *ngFor="let skill of groupedSkills[filterCurrent]">
            <ui-skill [skill]="skill"></ui-skill>
          </div>
        </div>
      </ng-container>

      <ng-template #noSkill>
        <h4 class="e11-font-bold e11-text-center e11-my-8">{{ 'No matching skills' | translate }}</h4>
      </ng-template>

      <div class="clearfix"></div>
      <div class="spacer-2rem"></div>

      <!-- Certifications -->
      <h3 class="text-black">{{ 'Certifications' | translate }}</h3>
      <ng-container *ngIf="groupedCertifications[filterCurrent] && groupedCertifications[filterCurrent].length > 0; else noCertificate">
        <div class="candidate-attribute-wrapper">
          <div *ngFor="let certification of groupedCertifications[filterCurrent]">
            <ui-skill [skill]="certification"></ui-skill>
          </div>
        </div>
      </ng-container>

      <ng-template #noCertificate>
        <h4 class="e11-font-bold e11-text-center e11-my-8">{{ 'No matching certifications' | translate }}</h4>
      </ng-template>
      <div class="clearfix"></div>
    </div>
  </div>
</div>

<!-- Pending/open request -->
<request-open *ngIf="processRequests()" [request]="openRequest"></request-open>

<ng-template #emptyState>
  <div class="empty-state text-center e11-border-2 e11-border-skin-app-borders e11-border-dashed e11-rounded-lg e11-pt-8">
    <h3 class="text-grey italic">{{ 'No Skills or Certifications Added' | translate }}</h3>
    <p class="text-grey" *ngIf="userMode === userModeEnum.Candidate">{{ 'You have not added any skills or certificates' | translate }}</p>
    <p class="text-grey" *ngIf="userMode === userModeEnum.Employer">
      {{ 'Candidate has not added any skills or certifications' | translate }}
    </p>

    <e11-button
      *ngIf="userMode === userModeEnum.Employer && !processRequests()"
      [size]="'sm'"
      color="secondary"
      value="{{ 'Request Skills & Certifications' | translate }}"
      (click)="!requestsDisabled && requestModal()"
      [disabled]="requestsDisabled"
    >
    </e11-button>

    <div class="spacer-2rem"></div>
  </div>
</ng-template>

<div>
  <e11-tabs tabTitleOverrides="e11-font-normal e11-text-base " [selectedTabId]="filterCurrent" (selectedTabChanged)="changedTab($event)">
    <e11-tab *ngFor="let filterType of filterTypes" [title]="translationFilterTypes[filterType] | translate" [tabId]="filterType">
      <ng-container *ngIf="filterCurrent === filterType">
        <p *ngIf="filterCurrent === filterTypeEnum.Exact">
          {{ 'Exact matches to the skills and certifications listed in the job post' | translate }}
        </p>
        <p *ngIf="filterCurrent === filterTypeEnum.Partial">
          {{ 'Partial matches to the skills and certifications listed in the job post' | translate }}
        </p>

        <div *ngIf="filterCurrent === filterTypeEnum.NotListed">
          <p *ngIf="userMode === userModeEnum.Employer">
            {{ 'Skills and certifications the candidate has that were not listed in the job post' | translate }}
          </p>
          <p *ngIf="userMode === userModeEnum.Candidate">
            {{ 'Skills and certifications you have that were not listed in the job post' | translate }}
          </p>
        </div>

        <div *ngIf="filterCurrent === filterTypeEnum.NoMatch">
          <p *ngIf="userMode === userModeEnum.Employer">
            {{ 'Skills and certifications listed in the job post that the candidate does not have' | translate }}
          </p>
          <p *ngIf="userMode === userModeEnum.Candidate">
            {{ 'Skills and certifications listed in the job post that you do not have' | translate }}
          </p>
        </div>

        <h3 class="text-black">{{ 'Skills' | translate }}</h3>
        <ng-container *ngIf="groupedSkills[filterCurrent] && groupedSkills[filterCurrent].length > 0; else noSkill">
          <manage-skills [readOnly]="true" [currentSkills]="groupedSkills[filterCurrent]" [loading]="false"></manage-skills>
        </ng-container>

        <ng-template #noSkill>
          <h4 class="e11-font-bold e11-text-center e11-my-8">{{ 'No matching skills' | translate }}</h4>
        </ng-template>
      </ng-container>
    </e11-tab>
  </e11-tabs>
</div>
