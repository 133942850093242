import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core'
import { UserMode } from '../../model/component-modes.enum'
import { IReference } from '../../model/interfaces'

@Component({
  selector: 'ui-reference-line-item',
  template: `
    <div class="e11-border e11-border-skin-app-borders e11-p-2 e11-mb-4 e11-rounded">
      <div class="e11-flex">
        <div class="e11-w-3/4">
          <p *ngIf="reference" class="e11-font-bold e11-line-item-title e11-break-all e11-line-clamp-2 e11-mb-0">
            {{ reference.firstName }} {{ reference.lastName }}
          </p>
          <h4 *ngIf="!reference" [ngClass]="{ 'e11-mb-2': !reference }" class="e11-h3-skeleton"></h4>
        </div>
        <div *ngIf="reference" class="e11-relative e11-w-1/4 e11-flex e11-justify-end">
          <span
            *ngIf="userMode === userModeEnum.Candidate"
            class="e11-flex e11-items-center e11-absolute e11--top-1 e11--right-1 e11-cursor-pointer e11-text-skin-primary-accent hover:e11-text-skin-primary e11-text-sm e11-rounded-full e11-p-1 hover:e11-bg-skin-grey/20"
            (click)="viewDetail.emit(reference); editView.emit(true)"
          >
            <span class="material-icons-outlined md-18 e11-text-skin-primary-accent hover:e11-text-skin-primary"> edit </span>
            <!-- Edit -->
          </span>
        </div>
      </div>
      <div *ngIf="reference" class="e11-break-all e11-line-clamp-2 e11-text-sm">{{ 'Type' | translate }}: {{ reference.referenceType }}</div>
      <div *ngIf="reference" class="e11-break-all e11-line-clamp-2 e11-text-sm">{{ 'Company' | translate }}: {{ reference.companyName }}</div>
      <div *ngIf="reference" class="e11-break-all e11-line-clamp-2 e11-text-sm">
        {{ 'Relationship' | translate }}: {{ reference.referenceRelationship }}
      </div>
      <div class="e11-text-sm e11-flex e11-items-center" [ngClass]="{ 'e11-mb-2': !reference }">
        <span class="material-icons-outlined md-14 e11-mr-2"> email </span>
        <span *ngIf="!reference || reference.email" class="e11-skeleton-empty">{{ reference?.email }}</span>
        <span *ngIf="reference && !reference.email" class="e11-italic e11-text-skin-light">{{ 'No email supplied' | translate }}</span>
      </div>
      <div class="e11-text-sm e11-flex e11-items-center" [ngClass]="{ 'e11-mb-2': !reference }">
        <span class="material-icons-outlined md-14 e11-mr-2"> phone_iphone </span>
        <span *ngIf="!reference || reference.phone" class="e11-skeleton-empty">{{ reference?.phone }}</span>
        <span *ngIf="reference && !reference.phone" class="e11-italic e11-text-skin-light">{{ 'No phone supplied' | translate }}</span>
      </div>
    </div>
  `,
  styleUrls: ['./ui-reference-line-item.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UiReferenceLineItemComponent {
  userModeEnum = UserMode

  @Input()
  userMode: UserMode = UserMode.Candidate

  @Input()
  reference: IReference | undefined = undefined

  @Input()
  removable: boolean = false

  @Output()
  viewDetail = new EventEmitter()

  @Output()
  editView = new EventEmitter()

  @Output()
  deleteItem = new EventEmitter<IReference>()
}
