import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { IPortfolio } from '../../../model/portfolio.model'
import { UserMode } from '../../../model/component-modes.enum'

@Component({
  selector: 'portfolio-photo-line-item',
  template: `
    <div [ngClass]="{ 'e11-skeleton': !image }" class="group/image-card e11-flex e11-relative e11-p-0 e11-h-48 e11-bg-skin-grey/20 e11-mb-4">
      <div
        *ngIf="image"
        [style.backgroundImage]="'url(' + image?.file?.signedURL + ')'"
        class="e11-absolute e11-inset-0 e11-z-5 e11-bg-contain e11-bg-no-repeat e11-bg-center"
      ></div>
      <div
        class="e11-opacity-100 md:e11-opacity-0 group-hover/image-card:e11-opacity-100 hover:e11-opacity-100 transition-opacity duration-100 ease-in-out e11-relative e11-flex e11-items-end e11-justify-end e11-z-10 e11-p-2 e11-w-full"
      >
        <e11-button
          *ngIf="userMode === UserMode.Candidate"
          value="{{ 'Delete' | translate }}"
          [size]="'sm'"
          [buttonGroup]="true"
          [color]="'secondary'"
          [skeletonLoading]="!image"
          (click)="confirmation.open()"
        ></e11-button>
        <e11-button
          value="{{ 'View' | translate }}"
          [size]="'sm'"
          [color]="'primary-accent'"
          [skeletonLoading]="!image"
          (click)="viewClicked.emit()"
        ></e11-button>
      </div>
    </div>

    <e11-confirmation
      #confirmation
      confirmTitle="{{ 'Delete this photo' | translate }}"
      confirmText="{{ 'confirm' | translate }}"
      [closeOnEscape]="false"
      cancelText="{{ 'cancel' | translate }}"
      [backdropStyle]="'dark'"
      (answer)="confirmationAnswer($event)"
    >
      {{ 'Are you sure you want to remove this photo? You can always add it back if you need to.' | translate }}
    </e11-confirmation>
  `,
})
export class PortfolioPhotoLineItemComponent implements OnInit {
  @Input() image?: IPortfolio
  @Input() userMode = UserMode.Candidate
  @Output() viewClicked = new EventEmitter()
  @Output() deletePhoto = new EventEmitter()

  UserMode = UserMode

  constructor() {}

  ngOnInit() {}

  confirmationAnswer(res: boolean) {
    if (res) this.deletePhoto.emit()
  }
}
