import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { E11Logger } from '@engineering11/web-api-error'
import { FeedbackRequest } from './feedback.model'
import { FeedbackStore } from './feedback.store'
const COMMENT_MAX_LENGTH = 600

@Component({
  selector: 'lib-feedback-gatherer',
  templateUrl: './feedback-gatherer.component.html',
  styleUrls: ['./feedback-gatherer.component.scss'],
})
export class FeedbackGathererComponent {
  @Input() feature!: string
  @Input() ratingText = 'How would you rate your experience with this feature?'
  @Input() commentText = 'Is there any additional feedback you would like to give?'

  @Output() feedbackSubmitted = new EventEmitter()

  ratingScale = 5
  rating?: number
  submitted = false

  feedbackForm = new FormGroup({
    comment: new FormControl(null, [Validators.maxLength(COMMENT_MAX_LENGTH)]),
  })

  constructor(private logger: E11Logger, private feedbackStore: FeedbackStore) {}

  get f() {
    return this.feedbackForm.controls as {
      [key: string]: FormControl
    }
  }

  async onSubmitForm() {
    this.submitted = true
    if (!this.rating) {
      return
    }
    const feedback: FeedbackRequest = {
      feature: this.feature,
      comment: this.feedbackForm.value.comment ?? null,
      rating: this.rating ?? null,
      ratingScale: this.ratingScale,
    }
    // If a loader is desired, consider using the global loader within the store - but a loader is not recommended for feedback submission
    this.feedbackStore.onAdd(feedback)
    this.feedbackSubmitted.emit()
  }
}
