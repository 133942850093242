import { Component, EventEmitter, Input, Output } from '@angular/core'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { ILanguage } from '../../model/language.model'

@Component({
  selector: 'candidate-language',
  template: `<!-- Request Information -->
    <div class="e11-w-full e11-text-center e11-my-4" *ngIf="!languages || languages.length === 0">
      <e11-button
        *ngIf="!openRequest"
        color="primary-accent"
        [ghost]="true"
        value="{{ 'Request Languages' | translate }}"
        (click)="!requestsDisabled && requestModal()"
        [disabled]="requestsDisabled"
      ></e11-button>
    </div>

    <!-- Pending/open request -->
    <request-open *ngIf="openRequest" [request]="openRequest"></request-open> `,
  styleUrls: [],
})
export class CandidateLanguageComponent {
  @Input() languages: ILanguage[] = []
  @Input() openRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false
  @Output() requestFromComponent = new EventEmitter()

  requestModal() {
    //
    this.requestFromComponent.emit('Language')
    // this.backdropService.backdropDisplay(true, 'dark')
  }
}
