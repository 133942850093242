import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { FilesService, IFileReceipt } from '@engineering11/files-web'
import { E11ConfirmationComponent } from '@engineering11/ui-lib/e11-confirmation'
import { E11ModalComponent } from '@engineering11/ui-lib/e11-modal'
import { PortfolioStore } from '../portfolio.store.component'
import { IPortfolio, IPortfolioFile, IPortfolioVideo, PortfolioUiType } from '../../../model/portfolio.model'
import { UserMode } from '../../../model/component-modes.enum'

@Component({
  selector: 'portfolio-videos',
  template: `
    <ng-container *ngrxLet="itemLoaded$ as itemLoaded">
      <div class="e11-flex e11-justify-between e11-items-center e11-flex-wrap">
        <div *ngIf="userMode === UserMode.Candidate" class="e11-flex e11-gap-2 e11-items-center e11-mb-4">
          <span class="material-icons-outlined md-36">smart_display</span>
          <p class="e11-font-primary-demibold e11-mb-0">
            {{ 'Add a video to your portfolio' | translate }}
          </p>
        </div>

        <div class="e11-flex e11-w-full md:e11-w-1/2 e11-justify-between">
          <p class="e11-font-primary-demibold e11-mb-6">{{ _portfolioVideos.length }} {{ 'video(s)' | translate }}</p>
          <portfolio-clear [userMode]="userMode" [itemType]="PortfolioUiType.Video" [portfolioItems]="_portfolioVideos"></portfolio-clear>
        </div>
      </div>

      <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-2 e11-gap-4 e11-auto-rows-auto">
        <div *ngIf="userMode === UserMode.Candidate">
          <sdk-file-drop
            (newFile)="uploadPortfolioVideos([$event])"
            [allowedFileTypes]="['video/*']"
            [dropInstruction]="'Drag and drop your video here' | translate"
            [loading]="!itemLoaded"
          ></sdk-file-drop>
        </div>

        <portfolio-upload-empty *ngIf="!_portfolioVideos.length" [userMode]="userMode" [itemType]="PortfolioUiType.Video"></portfolio-upload-empty>

        <portfolio-video-line-item
          *ngFor="let video of _portfolioVideos; let i = index"
          [video]="video"
          [userMode]="userMode"
          (deleteVideo)="deletePortfolioVideo(video)"
        ></portfolio-video-line-item>
      </div>
    </ng-container>
  `,
})
export class PortfolioVideosComponent {
  _portfolioVideos: IPortfolioVideo[] = []
  currentVideo?: IPortfolio

  @Input() set portfolioVideos(portfolioPhotos: IPortfolioVideo[] | undefined) {
    this._portfolioVideos = portfolioPhotos ?? []
  }
  @Input() userMode = UserMode.Candidate

  @Output() closeModal = new EventEmitter()
  @Output() closeResponse = new EventEmitter()
  @Output() passPortfolio: EventEmitter<IPortfolio[]> = new EventEmitter()
  @ViewChild('confirmation') confirmation!: E11ConfirmationComponent
  @ViewChild('modalVideo') modalVideo!: E11ModalComponent

  itemLoaded$ = this.portfolioStore.itemLoaded$

  UserMode = UserMode

  PortfolioUiType = PortfolioUiType
  thumbs: string[] = []

  constructor(private filesService: FilesService, private portfolioStore: PortfolioStore) {}

  async ngOnChanges() {
    if (this._portfolioVideos) this.portfolioStore.signFiles(this._portfolioVideos)
  }

  async uploadPortfolioVideos(files: File[]) {
    this.portfolioStore.uploadPortfolioFiles(files)
  }

  async signFiles(files: IFileReceipt[]) {
    return await this.filesService.signedURLs(files)
  }

  async deletePortfolioVideo(item: IPortfolioFile) {
    this.portfolioStore.deletePortfolioFile(item)
  }
}
