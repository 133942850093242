<button
  *ngIf="!buttonOnly"
  (click)="videoInput.click()"
  class="e11-relative e11-block e11-w-full e11-p-6 e11-text-center e11-cursor-pointer e11-border e11-border-skin-app-borders e11-rounded-lg hover:e11-border-skin-app-borders hover:e11-bg-skin-primary-accent/10 focus:e11-outline-none focus:e11-ring-2 focus:e11-ring-offset-2 focus:e11-ring-secondary e11-mb-2"
>
  <span class="material-icons-outlined md-36 e11-text-skin-light"> file_upload </span>
  <h3 class="e11-mb-2 e11-text-skin-complimentary">{{ 'Upload your own video' | translate }}</h3>
  <p class="e11-text-sm">
    {{ (' Video may be up to ' | translate) + maxDuration + (' seconds and in .mp4 or .webm format.' | translate) }}
  </p>
  <!-- The parents click will propagate -->
  <e11-button
    *ngIf="!showButtonIcon"
    [color]="'primary-accent'"
    [disabledText]="disabledText"
    [disabled]="disabled"
    [loadingText]="'Processing' | translate"
    [loading]="loading"
    [size]="buttonSize"
    [value]="buttonCopy | translate"
  ></e11-button>
</button>
<div [ngClass]="{ 'e11-flex e11-justify-center': !buttonOnly }" class="">
  <input #videoInput (change)="fileInputChange($event); videoInput.value = ''" accept=".mp4, .webm" class="e11-hidden" type="file" />

  <e11-button
    (click)="videoInput.click()"
    *ngIf="showButtonIcon"
    [color]="'primary-accent'"
    [disabledText]="disabledText"
    [disabled]="disabled"
    [iconPosition]="'left'"
    [iconSize]="'md-18'"
    [icon]="'file_upload'"
    [loadingText]="'Processing' | translate"
    [loading]="loading"
    [size]="buttonSize"
    [value]="buttonCopy | translate"
  ></e11-button>
</div>
