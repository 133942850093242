<div class="e11-h-screen e11-overflow-hidden e11-flex">
  <nav class="e11-hidden lg:e11-flex e11-h-full e11-flex-col e11-bg-skin-app-bg-dark e11-p-6">
    <div class="app-header-logo e11-mb-6">
      <img
        src="https://content.cnected.com/assets/bootstrap/employer.cnected.com/logo-light.png"
        class="e11-h-16 e11-max-h-16 e11-object-contain e11-object-left"
      />
    </div>
    <ng-container *ngTemplateOutlet="navItem"></ng-container>
  </nav>

  <main class="e11-flex-1 e11-flex-col e11-min-w-0 e11-h-full">
    <app-header [mobileDrawerState]="mobileDrawerState" (mobileDrawerStateChange)="mobileDrawerState = $event; mobileNavSideOver.open()"></app-header>
    <div class="e11-overflow-scroll e11-h-full e11-p-6">
      <router-outlet></router-outlet>
      <div class="e11-h-24"></div>
    </div>
  </main>
</div>

<e11-side-over-container #mobileNavSideOver [size]="'lg'" [sideOverPosition]="'left'" [containerClassOverrides]="'e11-bg-transparent e11-w-3/4'">
  <ng-template e11Template="content">
    <div class="e11-bg-skin-app-bg-dark e11-h-full e11-flex e11-flex-col e11-shadow-xl">
      <div class="app-header-logo e11-p-6">
        <img
          src="https://content.cnected.com/assets/bootstrap/employer.cnected.com/logo-light.png"
          class="e11-h-16 e11-max-h-16 e11-object-contain e11-object-left"
        />
      </div>
      <ng-container *ngTemplateOutlet="navItem"></ng-container>
    </div>
  </ng-template>
</e11-side-over-container>

<ng-template #navItem>
  <e11-nav-left
    class="e11-flex-grow"
    [navigationList]="navigationList"
    [mobileTabs]="mobileTabs"
    [mobileSideDrawerToggled]="mobileDrawerState"
    (mobileSideDrawerToggledChange)="mobileDrawerState = $event; mobileNavSideOver.close()"
  ></e11-nav-left>
</ng-template>
