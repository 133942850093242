import { Directive, Input, TemplateRef } from '@angular/core'

@Directive({
  selector: '[swimlaneColumnItem]',
})
export class SwimlaneColumnItemDirective {
  @Input() columnItemData: any
  @Input() disableDrag: boolean = false

  constructor(public templateRef: TemplateRef<unknown>) {}
}
