<div
  (click)="!readOnly && actionSelected(); $event.stopPropagation()"
  *ngIf="item"
  [ngClass]="{ editing: item.editing, 'e11-cursor-pointer': !readOnly }"
  class="ui-tag-candidate {{ type }} {{ item.level }}"
  id="ui-tag-candidate-{{ type }}-{{ item.name }}"
  title="{{ item.name }}  {{ readOnly ? '' : (' - Click to edit' | translate) }}"
>
  <div class="e11-flex">
    <div [ngClass]="{ 'e11-line-clamp-1': type === 'skill' }" class="e11-block e11-text-sm e11-font-primary-demibold e11-mb-0">
      {{ item.name }}
    </div>
    <span *ngIf="markAsRequired">*</span>
  </div>
  <div *ngIf="type === 'skill'" class="break"></div>
  <div *ngIf="type === 'skill'" class="e11-block e11-text-xs">{{ item.level }}</div>
  <div
    (click)="removeSkill(); $event.stopPropagation()"
    *ngIf="!readOnly && !markAsMissingSkills"
    alt="Remove"
    class="ui-tag-candidate-remove e11-text-white/80 hover:e11-text-white e11-flex e11-items-center"
    title="Remove"
  >
    <span class="material-icons-outlined md-18"> cancel </span>
  </div>
  <div
    (click)="actionSelected(); $event.stopPropagation()"
    *ngIf="!readOnly"
    class="ui-tag-candidate-edit e11-text-white/80 hover:e11-text-white e11-flex e11-items-center"
    title="Edit"
  >
    <span class="material-icons-outlined md-18"> {{ markAsMissingSkills ? 'add_circle' : 'edit' }} </span>
  </div>
</div>
<div *ngIf="!item" class="ui-tag-candidate e11-skeleton-empty !e11-w-32"></div>
