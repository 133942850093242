import { CustomerPermissions } from '@engineering11/customer-web'

export enum Permission {
  /** Currently, if you can list templates, you can create them */
  JOB_TEMPLATE_LIST = 'JOB_TEMPLATE_LIST',
  TEAM_MANAGE = 'TEAM_MANAGE',
  COMPANY_MANAGE = 'COMPANY_MANAGE',
  /**
   * Below permissions whether the user can access these features. Some individual items may still be
   * hidden based on other permissions
   */
  ApplicationsView = 'applications/view',
  PromotionsView = 'promotions/view',
  JobsView = 'jobs/view',
  CreateJob = 'jobs/create-job',
  ViewDispositionCodes = 'jobs/view-disposition-codes',
  CandidatePoolCustomerSearch = 'candidate-pool/customer-pool-viewer',
  CandidatePoolCustomerBuildAudience = 'candidate-pool/customer-pool-editor',
  CandidatePoolTenantSearch = 'candidate-pool/tenant-pool-viewer',
  CandidatePoolTenantBuildAudience = 'candidate-pool/tenant-pool-editor',
}
export enum JobAutomationRolePermissions {
  /** For ROLES only - A temporary hack to support AND logic between roles and permissions - NOT to be used without one of the above product-permissions */
  AdministrateAutomation = 'jobs-automation/administrate',
}

export enum JobAutomationPermissions {
  CustomizeMessage = 'jobs-automation/customize-message',
  ManageAutomation = 'jobs-automation/manage-automation', // Company Admin
  SuperUserAutomation = 'jobs-automation/super-user-manage-automation', // System Admin
}

/**
 * All recognised permissions should be noted in this type
 */
export type AllPermissions = Permission | CustomerPermissions | JobAutomationPermissions

export const allPermissions: AllPermissions[] = [
  ...Object.values(Permission),
  ...Object.values(JobAutomationPermissions),
  ...Object.values(CustomerPermissions),
]
