import { Pipe, PipeTransform } from '@angular/core'
import { formSelectOptionsCountry } from '@engineering11/web-utilities'

@Pipe({
  name: 'country',
})
export class CountryPipe implements PipeTransform {
  transform(countryCode: string): string {
    return formSelectOptionsCountry.find(country => country.value === countryCode)?.name ?? ''
  }
}
