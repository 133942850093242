import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Color } from '@engineering11/ui-lib/e11-ui-config'
import { E11Logger } from '@engineering11/web-api-error'
import { portfolioTypeToBgColor, portfolioTypeToBorderColor, portfolioTypeToTextColor, portfolioTypeToTitle } from './portfolio-constants'
import { PortfolioUiType } from '../../model/portfolio.model'
import { UserMode } from '../../model/component-modes.enum'
import { mimeTypeGroup } from '../../util/portfolio-items-by-type'

const portfolioFileTypeAccept: Record<PortfolioUiType, string> = {
  Document: mimeTypeGroup.Document.join(','),
  Photo: 'image/*',
  Video: 'video/*', // Currently video is handled by a dedicated component
  Link: '',
  NotSupported: '',
}

const typeToButtonColor: Record<PortfolioUiType, Color> = {
  Document: 'neutral',
  Photo: 'success',
  Video: 'primary-accent',
  Link: 'complimentary',
  NotSupported: 'neutral',
}

@Component({
  selector: 'portfolio-item-header',
  template: `
    <div
      class="e11-p-4 e11-flex e11-justify-between e11-w-full e11-border e11-rounded-lg e11-items-center e11-mb-4  {{ typeToBorderColor[itemType] }} {{
        typeToTextColor[itemType]
      }}"
    >
      <input
        type="file"
        id="imageUpload"
        class="e11-hidden"
        multiple
        accept="{{ portfolioFileTypeAccept[itemType] }}"
        #fileInput
        (change)="selectFile($event)"
      />
      <div class="e11-flex e11-items-center">
        <h1 class="e11-mr-2">{{ itemCount }}</h1>
        <p class="e11-mb-0">{{ portfolioTypeToTitle[itemType] | translate }}</p>
      </div>
      <div *ngIf="userMode === UserMode.Candidate">
        <div class="e11-mb-1">
          <e11-button
            *ngIf="itemType | portfolioItemTypeIsFile"
            [size]="'sm'"
            [color]="typeToButtonColor[itemType]"
            value="{{ 'Upload' | translate }}"
            [icon]="'upload'"
            [iconPosition]="'left'"
            [iconSize]="'md-18'"
            (click)="fileInput.click()"
          >
          </e11-button>
        </div>
      </div>
      <div *ngIf="userMode === UserMode.Employer">
        <p class="e11-font-bold e11-text-sm e11-mb-0">
          {{ 'Request more in profile!' | translate }}
        </p>
      </div>
    </div>
  `,
})
export class PortfolioItemHeaderComponent {
  PortfolioUiType = PortfolioUiType
  portfolioFileTypeAccept = portfolioFileTypeAccept
  portfolioTypeToColor = portfolioTypeToBgColor
  portfolioTypeToTitle = portfolioTypeToTitle
  typeToBorderColor = portfolioTypeToBorderColor
  typeToTextColor = portfolioTypeToTextColor
  typeToButtonColor = typeToButtonColor

  UserMode = UserMode

  @Input() itemType!: PortfolioUiType
  @Input() itemCount!: number
  @Input() userMode = UserMode.Candidate
  @Input() employerMode = UserMode.Employer

  @Output() fileSelected = new EventEmitter<File[]>()

  constructor(private logger: E11Logger) {}

  selectFile(event: any) {
    this.logger.log('PortfolioItemHeaderComponent upload', event.target.files)
    this.fileSelected.emit(Array.from(event.target.files))
  }
}
