<!-- Needs to accommodate different 'current' names and italic style for empty text strings -->
<span class="e11-text-sm e11-text-skin-light">
  <!-- Start date -->
  <span *ngIf="item.startDate">
    {{ item.startDate | date : dateFormat }}
  </span>
  <span *ngIf="!item.startDate" class="e11-italic">
    {{ 'No start date' | translate }}
  </span>
  &nbsp;-&nbsp;
  <!-- End date -->
  <span *ngIf="current">
    {{ 'Current' | translate }}
  </span>
  <span *ngIf="item.endDate && !current">
    {{ item.endDate | date : dateFormat }}
  </span>
  <span *ngIf="!item.endDate && !current" class="e11-italic">
    {{ 'No end date' | translate }}
  </span>
</span>
