<!-- Empty State -->
<!-- Populated state -->
<div class="">
  <div
    class="e11-w-full e11-border e11-border-skin-app-borders e11-p-2 e11-mb-4 e11-rounded"
    *ngFor="let reference of references"
    (click)="userMode === userModeEnum.Employer && displayReference(reference)"
  >
    <h4 class="">{{ reference?.referenceType }}</h4>
    <div>{{ reference?.firstName }} {{ reference?.lastName }}</div>
    <div>{{ 'Company' | translate }}: {{ reference?.companyName }}</div>
    <div>{{ 'Relationship' | translate }}: {{ reference?.referenceRelationship }}</div>
    <div *ngIf="reference?.email">{{ reference?.email }}</div>
    <div *ngIf="reference?.phone">{{ reference?.phone }}</div>
  </div>
</div>
