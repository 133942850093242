import { AdminNavigationService, ADMIN_ROUTES } from '@admin/app/services/admin-navigation.service'
import * as fromReduce from '@admin/app/store/reducers'
import { Component } from '@angular/core'
import { Router } from '@angular/router'
import { UserService } from '@engineering11/user-web'
import { Store } from '@ngrx/store'
import jwt_decode from 'jwt-decode'
import { OnLogIn } from 'shared-lib'

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class AuthenticationLoginComponent {
  ADMIN_ROUTES = ADMIN_ROUTES
  redirectPath = 'home'
  constructor(public router: Router, private store: Store<fromReduce.user.State>, private navigationService: AdminNavigationService) {}

  onForgottenPassword() {
    this.navigationService.toRecover()
  }

  async onSubmit(token: string): Promise<void> {
    const decoded: any = jwt_decode(token)
    this.store.dispatch(new OnLogIn({ token, appUserId: decoded.appUserId, redirectPath: this.redirectPath }))
  }
}
