import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { portfolioDescriptionByType, portfolioTypeToIcon, portfolioTypeToTitle } from './portfolio-constants'
import { PortfolioStore } from './portfolio.store.component'
import { IPortfolio, IPortfolioFile, PortfolioUiType } from '../../model/portfolio.model'
import { UserMode } from '../../model/component-modes.enum'

@Component({
  selector: 'ui-portfolio-card',
  template: `
    <div *ngIf="!loading" class="e11-card e11-border e11-border-skin-grey/50 e11-rounded-lg e11-p-2">
      <div class="e11-flex e11-gap-4 e11-mb-4">
        <div class="e11-flex e11-flex-col e11-items-center justify-content-center e11-border-r e11-border-r-skin-grey/20 e11-p-2">
          <span class="material-icons-outlined md-24">{{ typeToIcon[itemType] }}</span>
          <div class="e11-text-skin-primary-accent e11-text-center hover:e11-cursor-pointer hover:e11-brightness-75" (click)="cardClicked.emit()">
            <p *ngIf="userMode === UserMode.Candidate" class="e11-m-0 e11-text-sm">{{ 'Manage' | translate }}</p>
            <p class="e11-m-0 e11-text-sm e11-truncate e11-text-ellipsis">
              {{ itemCount }} {{ itemType === PortfolioUiType.Document ? 'Docs' : (typeToTitle[itemType] | translate) }}
            </p>
          </div>
        </div>
        <div>
          <p (click)="cardClicked.emit()" class="hover:e11-cursor-pointer e11-mb-0 e11-font-primary-demibold">
            {{ typeToTitle[itemType] | translate }}
          </p>
          <p class="e11-text-sm e11-m-0">{{ typeToDescription[itemType] | translate }}</p>
        </div>
      </div>

      <div *ngIf="userMode === UserMode.Candidate && !items.length && !readonly">
        <e11-button
          [ghost]="true"
          [buttonStyle]="'rounded'"
          [color]="'primary-accent'"
          [display]="'block'"
          [type]="'button'"
          [value]="'+ ' + typeToButtonText[itemType]"
          [size]="'sm'"
          (click)="cardClicked.emit()"
        ></e11-button>
      </div>

      <div *ngIf="!items.length && readonly">
        <p class="e11-bg-skin-grey/20 e11-text-skin-grey e11-text-sm e11-text-center e11-mb-4 e11-p-4 e11-rounded">
          {{ 'No ' + typeToTitle[itemType].toLowerCase() + ' on this portfolio' | translate }}
        </p>
      </div>

      <ng-container *ngrxLet="signingFiles$ as signingFiles" [ngSwitch]="itemType">
        <ng-container *ngSwitchCase="PortfolioUiType.Link">
          <portfolio-links-preview
            [portfolioLinks]="items"
            [userMode]="pdfMode ? UserMode.Public : userMode"
            (viewMoreClicked)="cardClicked.emit()"
          ></portfolio-links-preview>
        </ng-container>

        <ng-container *ngSwitchCase="PortfolioUiType.Document">
          <portfolio-documents-preview
            [portfolioDocuments]="items"
            [signingFiles]="signingFiles"
            [userMode]="pdfMode ? UserMode.Public : userMode"
            (viewMoreClicked)="cardClicked.emit()"
          ></portfolio-documents-preview>
        </ng-container>

        <ng-container *ngSwitchCase="PortfolioUiType.Photo">
          <portfolio-photos-preview
            [items]="items"
            [signingFiles]="signingFiles"
            [userMode]="pdfMode ? UserMode.Public : userMode"
            [showPreviews]="!pdfMode"
            (viewMoreClicked)="cardClicked.emit()"
          ></portfolio-photos-preview>
        </ng-container>

        <ng-container *ngSwitchCase="PortfolioUiType.Video">
          <portfolio-videos-preview
            [items]="items"
            [signingFiles]="signingFiles"
            [userMode]="pdfMode ? UserMode.Public : userMode"
            [showPreviews]="!pdfMode"
            (viewMoreClicked)="cardClicked.emit()"
          ></portfolio-videos-preview>
        </ng-container>
      </ng-container>
    </div>

    <div *ngIf="loading" class="e11-card !e11-border !e11-rounded-lg !e11-p-2 !e11-h-20 e11-skeleton"></div>
  `,
})
export class UiPortfolioCardComponent implements OnChanges {
  @Input() itemType!: PortfolioUiType
  @Input() itemCount = 0
  @Input() loading: boolean = false
  @Input() userMode = UserMode.Candidate
  @Input() items: IPortfolio[] = []
  @Input() readonly = false
  @Input() pdfMode: boolean = false

  @Output() cardClicked = new EventEmitter()

  PortfolioUiType = PortfolioUiType
  typeToIcon = portfolioTypeToIcon
  typeToTitle = portfolioTypeToTitle
  typeToDescription = portfolioDescriptionByType
  typeToButtonText: Record<PortfolioUiType, string> = {
    Document: 'Add documents to this portfolio',
    Photo: 'Add an image to this portfolio',
    Link: 'Add links to this portfolio',
    Video: 'Add a video to this portfolio',
    NotSupported: '',
  }
  UserMode = UserMode
  signingFiles$ = this.portfolioStore.signingFiles$

  constructor(private portfolioStore: PortfolioStore) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.items.isFirstChange() || changes.items.currentValue.length !== changes.items.previousValue.length) &&
      this.itemType !== PortfolioUiType.Link
    ) {
      this.portfolioStore.signFiles(this.items as IPortfolioFile[])
    }
  }
}
