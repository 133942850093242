import { Injectable, Pipe, PipeTransform } from '@angular/core'
import { ICandidateJobApplication, applicationFieldMappings } from '../model/candidate-application.model'
import { DispositionCodes } from '../model/disposition-code.model'
import {
  APPLICATION_STATE,
  DegreeLevel,
  EmploymentType,
  ExperienceLevel,
  applicationStateDescriptionMappings,
  applicationStateMappings,
  degreeLevelMappings,
  dispositionCodeToDescription,
  dispositionCodeToLabel,
  employmentTypeMappings,
  experienceLevelMapping,
} from '../model/enum'
import { QuestionType, dialogueQuestionTypeMappings } from '../model/virtual-dialogue.model'

const getTranslation = <T>(key: T, map: Map<T, string>) => (key ? map.get(key) || '' : '')

@Pipe({ name: 'employmentType' })
export class EmploymentTypePipe implements PipeTransform {
  transform(value: EmploymentType | string): string {
    return getTranslation(value, employmentTypeMappings)
  }
}

@Pipe({ name: 'experienceLevel' })
export class ExperienceLevelPipe implements PipeTransform {
  transform(value: ExperienceLevel | string) {
    return getTranslation(value, experienceLevelMapping)
  }
}

@Pipe({ name: 'degreeLevel' })
export class DegreeLevelPipe implements PipeTransform {
  transform(value: DegreeLevel | string) {
    return getTranslation(value, degreeLevelMappings)
  }
}

@Pipe({ name: 'applicationState' })
export class ApplicationStatePipe implements PipeTransform {
  transform(value: APPLICATION_STATE | string) {
    return getTranslation(value, applicationStateMappings)
  }
}

@Pipe({ name: 'applicationStateDescription' })
export class ApplicationStateDescriptionPipe implements PipeTransform {
  transform(value: APPLICATION_STATE | string) {
    return getTranslation(value, applicationStateDescriptionMappings)
  }
}

@Pipe({ name: 'dialogueQuestionType' })
export class DialogueQuestionTypePipe implements PipeTransform {
  transform(value: QuestionType | string) {
    return getTranslation(value, dialogueQuestionTypeMappings)
  }
}

@Pipe({ name: 'dispositionCodeDescription' })
export class DispositionCodesPipe implements PipeTransform {
  transform(value?: DispositionCodes) {
    if (!value) return
    return dispositionCodeToDescription[value]
  }
}
@Pipe({ name: 'dispositionCodeLabel' })
export class DispositionCodeLabelPipe implements PipeTransform {
  transform(value?: DispositionCodes) {
    if (!value) return
    return dispositionCodeToLabel[value]
  }
}

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'applicationField' })
export class ApplicationFieldPipe implements PipeTransform {
  transform(value: keyof ICandidateJobApplication | string) {
    return getTranslation(value, applicationFieldMappings)
  }
}
