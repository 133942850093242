import { Injectable } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'

export const ADMIN_ROUTES = Object.freeze({
  HOME: '/home',
  ACCOUNT: '/account',
  NOT_FOUND: '/404',
  LOGIN: '/auth/login',
  AUTH_RECOVER: '/auth/recover',
  AUTH_CODE: '/auth/code',
  TESTING: '/testing',
})

@Injectable({ providedIn: 'root' })
export class AdminNavigationService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  toLogin() {
    this.router.navigate([ADMIN_ROUTES.LOGIN])
  }

  toHome() {
    this.router.navigate([ADMIN_ROUTES.HOME])
  }

  toRecover() {
    this.router.navigate([ADMIN_ROUTES.AUTH_RECOVER])
  }

  addQueryParams(queryParams: Params) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
      queryParamsHandling: 'merge',
    })
  }

  updateQueryParams(queryParams: Params) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
    })
  }
}
