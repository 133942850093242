import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { DomSanitizer } from '@angular/platform-browser'
import { ICandidateUser } from '@cnect/user-shared'
import { FilesService } from '@engineering11/files-web'
import { IUploadedVideoResult, VideoThumbnailsService } from '@engineering11/multimedia-web'
import { E11FormValidationService, Validation } from '@engineering11/ui-lib/e11-input-errors'
import { E11Logger } from '@engineering11/web-api-error'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { v4 as uuidv4 } from 'uuid'
import { TokenStorage } from '../../service/token-storage.service'

const mimeDB: Record<string, string> = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/webp': 'webp',
}
const MAX_THUMBS = 4

@Component({
  selector: 'video-upload-file-form',
  templateUrl: './video-upload-file-form.component.html',
  styleUrls: ['./video-upload-file-form.component.scss'],
})
export class VideoUploadFileFormComponent implements OnDestroy, OnInit {
  @Input() filename = uuidv4()
  @Input() formReadOnly = false
  @Input() thumbnails: string[] = []
  @Input() thumbnailsLoading = false
  @Input() script: string = ''
  @Input() videoLength: string = '00:00'
  @Input() makePublic: boolean = false

  selectedThumbnail = 0

  maxThumbs = MAX_THUMBS
  thumbImageTypes = Object.keys(mimeDB).join()

  @Output() submitted: EventEmitter<boolean> = new EventEmitter()
  @Output() submittedSuccessfully: EventEmitter<IUploadedVideoResult> = new EventEmitter()

  _video!: File
  formUploadVideo = new UntypedFormGroup({})

  formSubmitted = false
  loading = false
  processing: boolean = false

  user!: ICandidateUser

  destroy$: Subject<boolean> = new Subject<boolean>()
  _videoURL?: string

  generateThumbLoading: boolean = false

  constructor(
    private domSanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder,
    private logger: E11Logger,
    private filesService: FilesService,
    private tokenStorage: TokenStorage,
    private videoThumbnailsService: VideoThumbnailsService,
    private customFormValidation: E11FormValidationService
  ) {}

  ngOnInit(): void {
    this.user = this.tokenStorage.getItem('user')

    this.videoThumbnailsService
      .getCompleteCapture()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        files => {
          this.generateThumbLoading = false
          this.thumbnails = files
        },
        err => {
          this.generateThumbLoading = false
        }
      )
  }

  @Input()
  set video(value: File) {
    this.formSubmitted = false
    this._video = value
    const URL = window.URL || window.webkitURL
    this._videoURL = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(value)) as string
    this.generateThumbs(this._video)

    this.initForm(value)
  }

  ngOnDestroy(): void {
    this.resetForm()
    this.videoLength = '00:00'
    this.destroy$.next(true)
    this.destroy$.complete()
    this._videoURL = undefined
  }

  get f() {
    return this.formUploadVideo.controls as {
      [key: string]: UntypedFormControl
    }
  }

  initForm(video: File) {
    this.formUploadVideo = this.formBuilder.group({
      id: new UntypedFormControl(),
      name: new UntypedFormControl('', [Validators.required, this.customFormValidation.whitespaceValidator()]),
      description: new UntypedFormControl(''),
      video: new UntypedFormControl(video),
    })
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0]
      const mimeType = file.type
      if (mimeType.match(/image\/*/) == null) {
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = _event => {
        if (this.thumbnails.length < MAX_THUMBS) {
          this.thumbnails.push(reader.result as string)
        } else {
          this.thumbnails[MAX_THUMBS - 1] = reader.result as string
        }
        this.selectedThumbnail = this.thumbnails.length - 1 // TODO: Auto-select thumbnail on upload in a nicer way
      }
    }
  }

  resetForm() {
    this.formUploadVideo.reset()
  }

  async onSubmitForm() {
    this.formSubmitted = true
    if (this.formUploadVideo.valid) {
      this.submitted.emit(true)
      this.formReadOnly = true
      this.processing = true
      this.loading = true
      const id = uuidv4()
      const uploadedVideo: IUploadedVideoResult = {
        id,
        name: this.formUploadVideo.get('name')?.value,
        description: this.formUploadVideo.get('description')?.value,
        thumb: await this.uploadFile(await this.getThumb(), id, ['image/jpeg', 'image/png', 'image/webp']),
        video: await this.uploadFile(this._video, id, ['video/mp4', 'video/webm', 'video/ogg']),
        videoLength: this.videoLength,
      }
      this.logger.log('submittedSuccessfully - emitting: ', uploadedVideo)
      this.loading = false
      this.formSubmitted = false
      this.formReadOnly = false
      this.processing = false
      this.submittedSuccessfully.emit(uploadedVideo)
    } else {
      this.submitted.emit(false)
      this.formUploadVideo.updateValueAndValidity()
      Validation.validateAll(this.formUploadVideo)
    }
  }

  async getThumb() {
    const blob = await (await fetch(this.thumbnails[this.selectedThumbnail])).blob()
    const ext: string = mimeDB[blob.type] || 'jpg'
    const filename = this.filename.replace(/\s+/g, '_')
    const thumbName = encodeURIComponent(`${filename}_${new Date().getTime()}.${ext}`)
    return new File([blob], thumbName, { type: blob.type })
  }

  async uploadFile(file: File, id: string, mimeTypes: string[]) {
    return await this.filesService.uploadFile({
      file,
      customerKey: this.user.customerKey,
      groupingId: id,
      makePublic: this.makePublic,
      validation: {
        types: mimeTypes,
      },
    })
  }

  generateThumbs(file: File) {
    this.thumbnails = []
    this.generateThumbLoading = true
    this.videoThumbnailsService.capture(file)
  }
}
