<!-- Empty State / Clickable -->
<e11-empty-state
  *ngIf="!loading && references.length === 0"
  [icon]="'people_outline'"
  [iconSize]="'md-36'"
  [iconType]=""
  title="{{ 'No References' | translate }}"
  subtitle="{{ userMode === userModeEnum.Candidate ? 'Add references to your profile..' : ('Candidate did not supply any references.' | translate) }}"
  [clickable]="userMode === userModeEnum.Candidate"
  (click)="userMode === userModeEnum.Candidate && this.addItem.emit()"
>
</e11-empty-state>

<ng-container *ngIf="references.length > 0 || loading">
  <ng-container *ngIf="listMode === listModeEnum.Vertical">
    <div class="e11-block">
      <ng-container *ngFor="let reference of references">
        <ui-reference-line-item
          [userMode]="userMode"
          [reference]="reference"
          (viewDetail)="viewDetail.emit($event)"
          (editView)="editView.emit($event)"
          (deleteItem)="deleteItem.emit($event)"
        ></ui-reference-line-item>
      </ng-container>

      <ui-reference-line-item *ngIf="loading" [userMode]="userMode" [reference]="undefined"></ui-reference-line-item>
    </div>
  </ng-container>
  <ng-container *ngIf="listMode === listModeEnum.Horizontal">
    <p class="e11-flex e11-items-center e11-text-skin-light">
      <span class="material-icons-outlined md-18 e11-text-skin-secondary e11-mr-1"> info </span
      >{{ 'Adding and removing references here only affects this application.' | translate }}
    </p>
    <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-2 lg:e11-grid-cols-3 xl:e11-grid-cols-4 e11-gap-4">
      <ng-container *ngFor="let reference of references">
        <ui-reference-line-item-card
          [reference]="reference"
          (viewDetail)="viewDetail.emit($event)"
          (editView)="editView.emit($event)"
          [removable]="true"
          (deleteItem)="deleteItem.emit($event)"
        ></ui-reference-line-item-card>
      </ng-container>
      <ng-container *ngIf="loading">
        <ui-skeleton-card *ngFor="let i of 3 | range"></ui-skeleton-card>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
