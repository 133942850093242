import { Component, Input } from '@angular/core'
import { UserMode } from '../../model/component-modes.enum'
import { IPortfolio, IPortfolioFile, PortfolioUiType } from '../../model/portfolio.model'
import { portfolioTypeToTitle } from './portfolio-constants'
import { PortfolioStore } from './portfolio.store.component'

@Component({
  selector: 'portfolio-clear',
  template: `
    <p
      *ngIf="userMode === UserMode.Candidate"
      (click)="portfolioItems?.length && this.confirmationClearAll.open()"
      class="e11-text-sm e11-pr-2 e11-mr-1 e11-mb-6"
      [ngClass]="{
        'e11-text-skin-primary-accent e11-cursor-pointer hover:e11-text-skin-danger': portfolioItems?.length,
        'e11-text-skin-grey  e11-cursor-not-allowed': !portfolioItems?.length
      }"
    >
      {{ 'Remove All' | translate }}
    </p>

    <e11-confirmation
      #confirmationClearAll
      confirmTitle="{{ 'Clear All ' + portfolioTypeToTitle[itemType] | translate }}"
      confirmText="{{ 'confirm' | translate }}"
      cancelText="{{ 'cancel' | translate }}"
      [backdropStyle]="'dark'"
      (answer)="confirmationAnswer($event)"
    >
      {{ 'Are you sure you want to clear all ' + portfolioTypeToTitle[itemType] + '? You can always add them back if you need to.' | translate }}
    </e11-confirmation>
  `,
})
export class PortfolioClearComponent {
  portfolioTypeToTitle = portfolioTypeToTitle
  UserMode = UserMode

  @Input() userMode = UserMode.Candidate
  @Input() itemType!: PortfolioUiType
  @Input() portfolioItems?: IPortfolio[]

  constructor(private portfolioStore: PortfolioStore) {}

  confirmationAnswer(res: boolean) {
    if (!res) return

    switch (this.itemType) {
      case PortfolioUiType.Photo:
      case PortfolioUiType.Video:
      case PortfolioUiType.Document:
        this.portfolioStore.deletePortfolioFiles(this.portfolioItems! as IPortfolioFile[])
        break
      case PortfolioUiType.Link:
        this.portfolioStore.onPortfolioItemsDeleted(this.portfolioItems!.map(item => item.id))
        break
    }
  }
}
