import { BehaviorSubject, Observable, of } from 'rxjs'
import { Injectable } from '@angular/core'

const TOKEN_NAME = 'token'

/**
 * @deprecated use LocalStorageService - it has static typing
 */
@Injectable({
  providedIn: 'root',
})
export class TokenStorage {
  // JWT { }

  private storageSub = new BehaviorSubject<string>('')

  /**
   * watchStorage for changes
   */
  watchStorage(): Observable<any> {
    return this.storageSub.asObservable()
  }

  /**
   *
   * @deprecated use LocalStorageService.setItem
   * @param {string} key
   * @param {*} data
   * @memberof TokenStorage
   */
  setItem(key: string, data: any) {
    localStorage.setItem(key, data)
    this.storageSub.next('changed!')
  }

  /** @deprecated use LocalStorageService.getItem */
  public getItem(key: string): any {
    const item = localStorage.getItem(key)
    if (item) {
      return JSON.parse(item)
    }
    return null
  }

  /**
   * @deprecated use LocalStorageService.removeItem
   *
   * @param {string} key
   * @memberof TokenStorage
   */
  removeItem(key: string) {
    localStorage.removeItem(key)
    this.storageSub.next('changed!')
  }

  /**

   *
   *
   * @returns {Observable<string>}
   * @memberof TokenStorage
   */
  public getAccessToken(): string {
    const token: string = localStorage.getItem(TOKEN_NAME) as string
    return token
  }

  public observeAccessToken(): Observable<string> {
    const token: string = localStorage.getItem(TOKEN_NAME) as string
    return of(token)
  }

  /**
   *
   *
   * @param {string} token
   * @returns {TokenStorage}
   * @memberof TokenStorage
   */
  public setAccessToken(token: string): TokenStorage {
    localStorage.setItem(TOKEN_NAME, token)
    return this
  }

  /**
   *
   *
   * @param {string} token
   * @returns {TokenStorage}
   * @memberof TokenStorage
   */
  public setRefreshToken(token: string): TokenStorage {
    localStorage.setItem('refreshToken', token)
    return this
  }

  /**
   *
   * @deprecated use LocalStorageService.clear
   * @memberof TokenStorage
   */
  public clear() {
    localStorage.clear()
  }
}
