<nav>
  <div class="app-nav-header">
    <ul class="e11-text-white/60">
      <li *ngFor="let item of navigationItems" class="hover:e11-text-white" [routerLink]="item.route" [routerLinkActive]="['e11-text-white']">
        <span class="icon material-icons">
          {{ item.icon }}
        </span>
        <span class="app-nav-header-text" [ngClass]="{ 'app-nav-header-text-closed': !navStatus }">{{ item.name }}</span>
      </li>
    </ul>
  </div>
</nav>
