export class SoundMeter {
  context!: AudioContext
  instant = 0.0
  slow = 0.0
  clip = 0.0
  script!: ScriptProcessorNode
  mic!: MediaStreamAudioSourceNode
  constructor(context: AudioContext) {
    this.context = context
    this.script = this.context.createScriptProcessor(2048, 1, 1)
    this.script.onaudioprocess = (event: any) => {
      const input = event.inputBuffer.getChannelData(0)
      let i
      let sum = 0.0
      let clipcount = 0
      for (i = 0; i < input.length; ++i) {
        sum += input[i] * input[i]
        if (Math.abs(input[i]) > 0.99) {
          clipcount += 1
        }
      }
      this.instant = Math.sqrt(sum / input.length)
      this.slow = 0.95 * this.slow + 0.05 * this.instant
      this.clip = clipcount / input.length
    }
  }

  connectToSource(stream: MediaStream, callback: (value: any) => void) {
    try {
      this.mic = this.context.createMediaStreamSource(stream)
      this.mic.connect(this.script)
      // necessary to make sample run, but should not be.
      this.script.connect(this.context.destination)
      if (typeof callback !== 'undefined') {
        callback(null)
      }
    } catch (e) {
      console.error(e)
      if (typeof callback !== 'undefined') {
        callback(e)
      }
    }
  }

  stop() {
    this.mic.disconnect()
    this.script.disconnect()
  }
}
