<!-- Profile dropdown -->
<div class="e11-flex e11-justify-between e11-relative" (closeOnClickOutside)="dropDownOpen = false">
  <e11-avatar
    *ngIf="currentUser$ | async as currentUser"
    [imageUrl]="currentUser.photoURL"
    [size]="'md'"
    [initials]="currentUser.firstName.charAt(0) + currentUser.lastName.charAt(0)"
    (click)="dropDownOpen = !dropDownOpen"
  ></e11-avatar>

  <div
    [ngClass]="{
      'e11-transition e11-ease-out e11-duration-200 e11-transform e11-opacity-0 e11-scale-95 e11-invisible': !dropDownOpen,
      'e11-transition e11-ease-in duration-75 e11-e11-transform e11-opacity-100 e11-scale-100': dropDownOpen
    }"
    class="e11-origin-top-right e11-absolute e11-right-0 e11-top-10 e11-mt-2 e11-w-48 e11-rounded-md e11-shadow-lg e11-py-1 e11-bg-white e11-border e11-border-skin-app-borders focus:e11-outline-none focus:e11-ring-opacity-100"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="user-menu-button"
    tabindex="-1"
  >
    <a
      [routerLink]="[ADMIN_ROUTES.ACCOUNT]"
      class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100 e11-border-b e11-border-gray-200"
      role="menuitem"
      tabindex="-1"
      id="user-menu-item-0"
    >
      {{ 'My Account' | translate }}
    </a>

    <a
      href="#"
      class="e11-block e11-px-4 e11-py-2 e11-text-sm e11-text-gray-700 hover:e11-bg-gray-100"
      role="menuitem"
      tabindex="-1"
      id="user-menu-item-2"
      (click)="signOut()"
    >
      {{ 'Sign out' | translate }}
    </a>
  </div>

  <span
    (click)="dropDownOpen = !dropDownOpen"
    *ngIf="currentUser$ | async as currentUser"
    class="e11-flex e11-items-center sm:e11-visible e11-pl-2 e11-py-2 e11-rounded-md e11-text-sm e11-text-skin-primary-accent hover:e11-text-skin-primary e11-cursor-pointer"
  >
    {{ currentUser.firstName }} {{ currentUser.lastName }}
    <span class="material-icons">expand_more</span>
  </span>
</div>
