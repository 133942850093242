import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Inject } from '@angular/core'
import { Observable } from 'rxjs'
import { Base } from '../interface/base'

export class BaseService implements Base {
  protected headers = new HttpHeaders({
    'X-Service-Request': 'true',
  })

  constructor(@Inject(HttpClient) protected http: HttpClient, private baseUrl: string) {}

  getAll<T = any>(options?: object): Observable<T> {
    return this.http.get<T>(this.baseUrl, {
      headers: this.headers,
      params: new HttpParams(options),
    })
  }
  get<T = any>(id: string, options?: object): Observable<T> {
    return this.http.get<T>(`${this.baseUrl}/${id}`, {
      headers: this.headers,
      params: new HttpParams(options),
    })
  }
  create<T = any>(data: object, path: string = '', options?: object): Observable<T> {
    return this.http.post<T>(`${this.baseUrl}/${path}`, data, {
      headers: this.headers,
      params: new HttpParams(options),
    })
  }
  patch(data: object) {}
  delete(id: string) {}
}
